import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Header from './Header';
import Loader from './Loader';
import Footer from './Footer';

const PropertyPage = () => {
  // const [properties, setProperties] = useState([]); // Holds all fetched properties
  // const [propertiesrent, setPropertiesRent] = useState([]); // Holds all fetched properties
  const [page, setPage] = useState(1); // Current page number
  const [loading, setLoading] = useState(false); // Loading state
  const [filtermore, setfiltermore] = useState(true); // Loading state
  const [totalProperties, totalPropertyCount] = useState(0); // Total properties available
  const [propertyCount, setPropertyCount] = useState(0); // Total properties available
  const [hasMore, setHasMore] = useState(true); // To check if there are more properties

  // const [sortedProperties, setSortedProperties] = useState([]);
  const [sortBy, setSortBy] = useState('Recent'); // Set default value  
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]); 
  const [selectedParentTypes, setSelectedParentTypes] = useState([]); 
  const [selectedTypes, setSelectedTypes] = useState([]); // Property types selected for filtering
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [minBeds, setMinBeds] = useState('');
  const [maxBeds, setMaxBeds] = useState('');
  const [minSqft, setMinSqft] = useState('');
  const [maxSqft, setMaxSqft] = useState('');
  const [minYield, setMinYield] = useState('');
  const [maxYield, setMaxYield] = useState('');
  const [generatedURL, setGeneratedURL] = useState('');

  const [filteredProperties, setFilteredProperties] = useState([]);

  const [isRegionVisible, setIsRegionVisible] = useState(false);

  // State to track which tab is active, default to "buy"
  const [activeTab, setActiveTab] = useState('');

  // Function to handle tab click and update activeTab state
  const handleTabClick = (tab) => {
    setFilteredProperties([]);
    setActiveTab(tab);
    setLoading(true); 
      if (selectedRegions.length === 0 &&
      selectedLocation.length === 0 &&
      selectedParentTypes.length === 0 &&
      selectedTypes.length === 0 &&
      minPrice === '' &&
      maxPrice === '' &&
      selectedConditions.length === 0 &&
      minBeds === '' &&
      maxBeds === '' &&
      minSqft === '' &&
      maxSqft === ''  
    ) {
      return filteredProperties;
      setLoading(false); 
    }else{
      setLoading(true);
      setTimeout(() => {
        const searchButton = document.querySelector('.Searchbtn');
        if (searchButton) {
          searchButton.click();
          setLoading(false);
        } else {
        }
      }, 1600); 
    }

  };


  const domainName = window.location.hostname;
  const category = activeTab === "buy" ? "buy" : "rent";

  useEffect(() => {
    if (category === "rent") {
      setMinYield(""); 
      setMaxYield("");
    }
  }, [category]);

  /// url value base search
  useEffect(() => { 
    
    // Extract URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    if (!urlParams) {
      return;  
    }
 

    const buyRentCategoryValue = urlParams.get('buyrent_category');  
  
    if (buyRentCategoryValue) {
      setActiveTab(buyRentCategoryValue);  
    } else {
      // Optionally, you can set a default value if the URL parameter is not found
      setActiveTab('buy'); // Default value (e.g., if the URL parameter is missing)
    }

    // Set other fields (regions, locations, etc.)
    const regions = urlParams.get('regions') ? urlParams.get('regions').split(',') : [];
    setSelectedRegions(regions);

    const locations = urlParams.get('locations') ? urlParams.get('locations').split(',') : [];
    setSelectedLocation(locations);

    const parent_type = urlParams.get('parent_type') ? urlParams.get('parent_type').split(',') : [];
    setSelectedParentTypes(parent_type);

    const types = urlParams.get('types') ? urlParams.get('types').split(',') : [];
    setSelectedTypes(types);

    setMinPrice(urlParams.get('minPrice') || '');
    setMaxPrice(urlParams.get('maxPrice') || '');
    
    const conditions = urlParams.get('conditions') ? urlParams.get('conditions').split(',') : [];
    setSelectedConditions(conditions);

    setMinBeds(urlParams.get('minBeds') || '');
    setMaxBeds(urlParams.get('maxBeds') || '');
    setMinSqft(urlParams.get('minSqft') || '');
    setMaxSqft(urlParams.get('maxSqft') || '');
    setMinYield(urlParams.get('minYield') || '');
    setMaxYield(urlParams.get('maxYield') || '');
    if (urlParams.toString() !== '') {
      setLoading(true);

      // Use async function within useEffect to handle the timeout more predictably
      const handleSearchButtonClick = async () => {
        // Wait for a short time before clicking the button
        await new Promise(resolve => setTimeout(resolve, 3000));

        const searchButton = document.querySelector('.Searchbtn');
        if (searchButton) {
          searchButton.click();
          console.log('Search button clicked! 3000');
        } else {
          console.log('Search button not found');
        }

        setLoading(false);
      };

      // Call the async function to handle the button click
      handleSearchButtonClick();
    }


  }, []);


  // Show the yield area
  useEffect(() => {
      if (!activeTab) {
        return; 
      }
      if (selectedRegions.length > 0) {
        if(activeTab === 'buy'){
          setIsRegionVisible(true); // Hide the yield area
        }else{
          setIsRegionVisible(false); // Show the yield area
        }
      } else {
        setIsRegionVisible(false); // Hide the yield area
      }
  }, [selectedRegions,activeTab]);

  useEffect(() => {
    if (activeTab === '') {
      return; 
    }

    // Delay the execution of the fetch by 2 seconds
    const timer = setTimeout(() => {
      const category = activeTab === "buy" ? "buy" : "rent";
      if (filtermore) {
        fetchProperties(category);
      } else {
      }
    }, 500); // 2 seconds delay
 
    return () => clearTimeout(timer);
  }, [activeTab, page, filtermore]); 
  
  useEffect(() => {
    if (activeTab === '') {
      return;
    }

    // Delay the execution of the fetch by 2 seconds
    const timer = setTimeout(() => {
      const category = activeTab === "buy" ? "buy" : "rent";
      fetchPropertyCount(category);
    }, 800); // 2 seconds delay
 
    return () => clearTimeout(timer);
  }, [activeTab]);
   

  // Fetch properties based on the page number
  const fetchProperties = async (category) => {
    setLoading(true);
    console.log(`Fetching properties for page: ${page}`);

    const apiUrlproperty = activeTab === "buy"
      ? `https://${domainName}:5000/api/property/buy` 
      : `https://${domainName}:5000/api/property/rent`;

    try {
      const response = await axios.get(`${apiUrlproperty}?page=${page}`);
     
      const data = response.data;

      setFilteredProperties((prev) => [...prev, ...data]);
      // Check if more properties are available
      setHasMore(data.length > 0);

    } catch (error) {
      console.error("Error fetching properties:", error);
      //console.log("address is "+`https://${domainName}:5000/api/property/${category}?page=${page}`);
      setHasMore(false); // If an error occurs, stop fetching
    }
    setLoading(false);
  };

  // Fetch the total property count
  const fetchPropertyCount = async (category) => {
    try {
      const response = await fetch(`https://${domainName}:5000/api/property-count-by-category/${category}`);
      if (!response.ok) throw new Error('Failed to fetch property count');
      const data = await response.json();
      totalPropertyCount(data.count);  // Set the count value
      setPropertyCount(data.count);    
    } catch (error) {
      console.error('Error fetching property count:', error);
    }
    setLoading(false);
  };

  // Infinite scroll event listener
  useEffect(() => {
    const handleScroll = () => {
     // console.log('Scroll position:', window.innerHeight + window.scrollY, 'document height:', document.body.offsetHeight);

      // Only increment page when we can load more data
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight-500 && hasMore && !loading  ) {
        
        // Use a functional update to ensure we get the correct previous page
        setPage((prevPage) => {
          console.log('Prev Page:', prevPage); // Log previous page to ensure it's incrementing
          return prevPage + 1;  // Increment the page number
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasMore, loading]);  // Re-run only if 'hasMore' or 'loading' changes


    
  // Function to handle checkbox change
  const handleChange = (event) => {
    setSortBy(event.target.value); // Update the sort option
  };

  const handleRegionChange = (event) => {
    const { value, checked } = event.target;
    setSelectedRegions((prevSelectedRegions) => {
      if (checked) {
        return [...prevSelectedRegions, value];
      } else {
        return prevSelectedRegions.filter(region => region !== value);
      }
    });
  };
  const handleLocationChange = (event) => {
    const { value, checked } = event.target;
    setSelectedLocation((prevSelectedLocation) => {
      if (checked) {
        return [...prevSelectedLocation, value];
      } else {
        return prevSelectedLocation.filter(Location => Location !== value);
      }
    });
  };

  const handleParentTypeChange = (event) => {
    const { value, checked } = event.target;
    setSelectedParentTypes((prevSelectedParentType) => {
      if (checked) {
        return [...prevSelectedParentType, value];
      } else {
        return prevSelectedParentType.filter(parent_type => parent_type !== value);
      }
    });
  };

  // Function to handle checkbox change for property type selection
  const handleTypeChange = (event) => {
    const { value, checked } = event.target;
    setSelectedTypes(prevSelectedTypes => {
      if (checked) {
        return [...prevSelectedTypes, value];
      } else {
        return prevSelectedTypes.filter(type => type !== value);
      }
    });
  };

  const handleMinPriceChange = (e) => setMinPrice(e.target.value.replace(/[^0-9]/g, ''));
  const handleMaxPriceChange = (e) => setMaxPrice(e.target.value.replace(/[^0-9]/g, ''));

  const handleConditionChange = (event) => {
    const { value, checked } = event.target;
    setSelectedConditions((prevConditions) => {
      if (checked) {
        return [...prevConditions, value]; // Add the value if checked
      } else {
        return prevConditions.filter((condition) => condition !== value); // Remove the value if unchecked
      }
    });
  };

  const handleMinBedsChange = (e) => setMinBeds(e.target.value);
  const handleMaxBedsChange = (e) => setMaxBeds(e.target.value);

  const handleMinSqftChange = (e) => setMinSqft(e.target.value);
  const handleMaxSqftChange = (e) => setMaxSqft(e.target.value);

  const handleMinYieldChange = (e) => setMinYield(e.target.value);
  const handleMaxYieldChange = (e) => setMaxYield(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setLoading(true); 
    // Fetch data based on the activeTab value
    const fetchPropertiesByCategory = (activeTab) => {
        if (!activeTab) {
            return; // Early return if activeTab is empty or invalid
        }

        const apiUrl = activeTab === "buy"
            ? `https://${domainName}:5000/api/filterbuy`
            : `https://${domainName}:5000/api/filterrent`;

        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                // Apply filtering logic on the fetched data
                const filtered = data.filter(property => {
                    const matchesRegion = selectedRegions.length === 0 || selectedRegions.includes(property.region);
                    const matchesLocation = selectedLocation.length === 0 || selectedLocation.includes(property.location);
                    const matchesParentType = selectedParentTypes.length === 0 || selectedParentTypes.includes(property.parent_type);
                    const matchesType = selectedTypes.length === 0 || selectedTypes.includes(property.property_type);
                    
                    // Check if the property price is within the selected range
                    const price = parseFloat(property.price);
                    const matchesPrice =
                        (minPrice === '' || price >= parseFloat(minPrice)) &&
                        (maxPrice === '' || price <= parseFloat(maxPrice));

                    const matchesCondition = selectedConditions.length === 0 || selectedConditions.includes(property.condition_type);
                    
                    const bedrooms = parseFloat(property.bedrooms);
                    const matchesbedrooms =
                        (minBeds === '' || bedrooms >= parseFloat(minBeds)) &&
                        (maxBeds === '' || bedrooms <= parseFloat(maxBeds));

                    const costsqmeter = parseFloat(property.cost_per_square_meter);
                    const matchescostsqmeter =
                        (minSqft === '' || costsqmeter >= parseFloat(minSqft)) &&
                        (maxSqft === '' || costsqmeter <= parseFloat(maxSqft));

                    const rentalyield = parseFloat(property.rental_yield);
                    const matchesyield =
                        (minYield === '' || rentalyield >= parseFloat(minYield)) &&
                        (maxYield === '' || rentalyield <= parseFloat(maxYield));

                    return matchesRegion && matchesLocation && matchesParentType && matchesType && matchesPrice && matchesCondition && matchesbedrooms && matchescostsqmeter && matchesyield;
                });

                // Set the filtered properties in state
                setFilteredProperties(filtered);
                const totalProperties = filtered.length;
                setPropertyCount(totalProperties);
               
                setfiltermore(false);   
                
                // Construct filter conditions as query parameters for the URL
                const queryParams = new URLSearchParams();

                // Add filter criteria to query parameters
                if (activeTab !== '') queryParams.append("buyrent_category", activeTab); 
                if (selectedRegions.length > 0) queryParams.append("regions", selectedRegions.join(","));
                if (selectedLocation.length > 0) queryParams.append("locations", selectedLocation.join(","));
                if (selectedTypes.length > 0) queryParams.append("types", selectedTypes.join(","));
                if (selectedParentTypes.length > 0) queryParams.append("parent_type", selectedParentTypes.join(","));
                if (minPrice !== '') queryParams.append("minPrice", minPrice);
                if (maxPrice !== '') queryParams.append("maxPrice", maxPrice);
                if (selectedConditions.length > 0) queryParams.append("conditions", selectedConditions.join(","));
                if (minBeds !== '') queryParams.append("minBeds", minBeds);
                if (maxBeds !== '') queryParams.append("maxBeds", maxBeds);
                if (minSqft !== '') queryParams.append("minSqft", minSqft);
                if (maxSqft !== '') queryParams.append("maxSqft", maxSqft);
                if (minYield !== '') queryParams.append("minYield", minYield);
                if (maxYield !== '') queryParams.append("maxYield", maxYield);

                // Construct the full URL with query parameters
                const url = `https://${domainName}/search?${queryParams.toString()}`;

                // Optionally set the URL (redirect or show)
                setGeneratedURL(url);
                setLoading(false); 
              })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    // Call the fetch function with the current activeTab
    fetchPropertiesByCategory(activeTab);
  };


   const sortProperties = (filteredProperties) => {
    if (sortBy === 'Recent') {
      return filteredProperties.sort((a, b) => new Date(b.date) - new Date(a.date));
    }
    if (sortBy === 'lowtohigh') {
      return filteredProperties.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
    }
    if (sortBy === 'hightolow') {
      return filteredProperties.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
    }
    return filteredProperties;
  };

  const filteredProperties1 = filteredProperties;
  // Get filtered properties and apply sorting
 const sortedProperties = sortProperties(filteredProperties1);

  // Limit the displayed properties to 20 per page
  const allproperties = sortedProperties.slice(0, page * 20);

  const [showNoPropertiesMessage, setShowNoPropertiesMessage] = useState(false);

  useEffect(() => {
    if (allproperties.length === 0) {
      const timer = setTimeout(() => {
        setShowNoPropertiesMessage(true); // Show the message after 1 seconds
      }, 5000); 

      // Cleanup the timer when the component unmounts or if the effect is re-run
      return () => clearTimeout(timer);
    } else {
      setShowNoPropertiesMessage(false); // Hide the message if properties are found
    }
  }, [allproperties]);

  
  return (
    <div className="">
    <Header />
    <div className="banner-header">
    <h1>Properties For {category === 'buy' ? 'Sale' : category} in Cyprus</h1>

      </div>
    <div className="main-sec">
      <div className="container">
        {/* Property List Section */}
        {generatedURL && <h5> Generated URL: <a href={generatedURL} target="_blank" rel="noopener noreferrer">{generatedURL}</a></h5>}
        <div className="header-section" id="header-section">
          <div className='total-results'>
            <p>Showing: {allproperties.length} out of {new Intl.NumberFormat('en-US').format(Math.round(propertyCount))}</p>
          </div>
          <ul><li className={`buy-tab-link ${activeTab === "buy" ? "active" : ""}`}
              onClick={() => handleTabClick("buy")}>Buy</li>
            <li className={`rent-tab-link ${activeTab === "rent" ? "active" : ""}`}
              onClick={() => handleTabClick("rent")}>Rent</li>
          </ul>
          <div className="sort-by">
            <label>SortBy</label>
            <select name="sortby" value={sortBy} onChange={handleChange}>
              <option value="Recent">Most Recent</option>
              <option value="lowtohigh">Lowest Price</option>
              <option value="hightolow">Highest Price</option>
            </select>
          </div>
        </div>

        
        <div className="filter-section">  
          <form onSubmit={handleSubmit} className="filter-form">            
          <div className="location-column">
            <div className="visible-location selected-visible">
            <i class="fa fa-map-pin"></i>
              <input type="text" id="region_location" placeholder="Locations" value={`${selectedRegions} ${selectedLocation}`} disabled/>
            </div>
            <div className="location-toggle select-details">
                  <ul className="location-list">
                    {/* Limassol Region */}
                    <li className="Limassol-reg1">
                      <input
                        type="checkbox"
                        className="regionval"
                        value="Limassol"
                        id="region-Limassol"
                        onChange={handleRegionChange}
                        checked={selectedRegions.includes('Limassol')}
                      />
                      <span>Limassol</span>

                      {/* Nested locations for Limassol */}
                      {selectedRegions.includes('Limassol') && (
                        <ul className="Limassol-locations">
                          {[
                            "Germasogeia", "Germasogeia Tourist", "Erimi", "Moni", "Agios Tychon", "Parekklisia", "Polemidia Kato", 
                            "Agios Athanasios", "Limassol", "Monagroulli", "Pyrgos Lemesou Tourist", "Trachoni Lemesou", "Pissouri", 
                            "Palodeia", "Dora", "Monagri", "Mouttagiaka", "Agios Amvrosios Lemesou", "Ypsonas", "Kellaki", 
                            "Mesa Geitonia", "Souni", "Kalo Chorio Lemesou", "Akrounta", "Platres Pano", "Doros", "Fasoula Lemesou", 
                            "Agios Tychon Tourist", "Pyrgos Lemesou", "Parekklisia Tourist", "Potamos Germasogeias", "Panthea", 
                            "Asomatos Lemesou", "Ekali", "Tserkez Tsiftlik (Tserkezoi)", "Agios Stylianos", "Paramali", "Akrotiri", 
                            "Prodromos", "Limassol Marina", "Foinikaria", "Pera Pedi", "Pentakomo", "Episkopi Lemesou", "Zanakia", 
                            "Agios Georgios", "Armenochori", "Vasa Koilaniou", "Arsos Lemesou", "Prastio Avdimou", "Anogyra", "Paramytha", 
                            "Mouttagiaka Tourist", "Laneia", "Kolossi", "Polemidia", "Pelendri", "Agios Therapon", "Sotira Lemesou", 
                            "Vouni", "Polemidia Pano", "Louvaras", "Kouka", "Agios Ioannis", "Historical Center", "Trimiklini", "Alassa", 
                            "Eptagoneia", "Anthoupoli", "Agia Paraskevi", "Moniatis", "Asgata", "Moni Agiou Georgiou Alamanou", "Omodos", 
                            "Archangelos Michail", "Agios Spyridon", "Omonia", "Agia Fyla", "Petrou Kai Pavlou", "Apostolos Varnavas", 
                            "Apostolos Andreas", "Agios Theodoros Lemesou", "Platres Kato", "Agios Pavlos", "Pachna", "Amiantos Kato", 
                            "Amiantos Pano", "Dierona", "Agros", "Kivides Pano", "Linopetra", "Zakaki", "Lofou", "Silikou", "Spitali", 
                            "Kontovathkeia", "Foini", "Apesia", "Mandria Lemesou", "Mylos Kato", "Apsiou", "Prastio Kellakiou", "Fasouri", 
                            "Mathikoloni", "Lemithou", "Gerasa", "Profitis Ilias", "Avdimou", "Vasa Kellakiou", "Timiou Prodromou Mesa Geitonias", 
                            "Chalkoutsa", "Kapsalos", "Agios Georgios(Fragk)", "Troodos", "Makarios Iii", "Potamiou", "Kyperounta", "Potamitissa", 
                            "Agios Konstantinos", "Agios Mamas", "Kapileio", "Arakapas", "Koilani", "Limnatis", "Korfi", "Chandria", 
                            "Kolossi -Agios Loukas", "Sykopetra", "Zoopigi", "Kaminaria"
                          ].map((propertyLocation) => (
                            <li key={propertyLocation}>
                              <input
                                type="checkbox"
                                className="locationval"
                                value={propertyLocation}
                                id={`location-${propertyLocation}`}
                                onChange={handleLocationChange}
                                checked={selectedLocation.includes(propertyLocation)}
                              />
                              <span>{propertyLocation}</span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>

                    {/* Larnaca Region */}
                    <li className="Larnaca-reg1">
                      <input
                        type="checkbox"
                        className="regionval"
                        value="Larnaca"
                        id="region-Larnaca"
                        onChange={handleRegionChange}
                        checked={selectedRegions.includes('Larnaca')}
                      />
                      <span>Larnaca</span>

                      {/* Nested locations for Larnaca */}
                      {selectedRegions.includes('Larnaca') && (
                        <ul className="Larnaca-locations">
                          {[
                            "Pyla", "Kiti", "Livadia Larnakas", "Aradippou", "Dekeleia", "Perivolia Larnakas", "Larnaka", "Pyla Tourist", 
                            "Vavatsinia", "Oroklini Tourist", "Ormideia", "Oroklini", "Maroni", "Mazotos", "Xylofagou", "Lefkara Pano", 
                            "Vavla", "Tersefanou", "Mosfiloti", "Agios Theodoros", "Lefkara Kato", "Kalavasos", "Psevdas", "Zygi", "Meneou", 
                            "Anglisides", "Dromolaxia", "Psematismenos", "Alethriko", "Kalo Chorio", "Sotiros", "Kokkines", "Agios Fanourios", 
                            "Anafotida", "Xylotymvou", "Pyrga Larnakas", "Softades", "Kornos", "Skala", "Tochni", "Chrysopolitissa", 
                            "Agios Nikolaos", "Apostolos Loukas", "Kamares", "Ora", "Agioi Anargyroi I", "Melini", "Drys Kato", "Troulloi", 
                            "Drosia", "Athienou", "Alaminos", "Kofinou", "Skarinou", "Agioi Anargyroi II", "Lageia", "Choirokoitia", "Mari", 
                            "Avdellero", "Agia Anna", "Agioi Vavatsinias", "Odou"
                          ].map((propertyLocation) => (
                            <li key={propertyLocation}>
                              <input
                                type="checkbox"
                                className="locationval"
                                value={propertyLocation}
                                id={`location-${propertyLocation}`}
                                onChange={handleLocationChange}
                                checked={selectedLocation.includes(propertyLocation)}
                              />
                              <span>{propertyLocation}</span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>

                    {/* Paphos Region */}
                    <li className="Paphos-reg1">
                      <input
                        type="checkbox"
                        className="regionval"
                        value="Paphos"
                        id="region-Paphos"
                        onChange={handleRegionChange}
                        checked={selectedRegions.includes('Paphos')}
                      />
                      <span>Paphos</span>

                      {/* Nested locations for Paphos */}
                      {selectedRegions.includes('Paphos') && (
                        <ul className="Paphos-locations">
                          {[
                            "Paphos", "Kissonerga", "Pegeia", "Koloni", "Tsada", "Tala", "Pomos", "Armou", "Chlorakas", "Mesa Chorio", 
                            "Konia", "Geroskipou", "Latsi", "Empa", "Anarita", "Agia Marinouda", "Argaka", "Neo Chorio Pafou", "Nea Dimmata", 
                            "Lempa", "Polis Chrysochous", "Kritou Tera", "Tombs Of The Kings", "Mesogi", "Nikokleia", "Kouklia Pafou", 
                            "Prodromi", "Lysos", "Acheleia", "Episkopi Pafou", "Amargeti", "Aphrodite Hills Kouklia", "Mandria Pafou", 
                            "Kathikas", "Timi", "Ineia", "Statos", "Pentalia", "Koilineia", "Koili", "Agia Marina Kelokedaron", "Tremithousa", 
                            "Chrysochou", "Universal", "Agios Georgios", "Venus Rock Kouklia", "Geroskipou Tourist", "Choulou", "Agios Nikolaos", 
                            "Agios Theodoros", "Moutallos", "Thrinia", "Marathounta", "Steni", "Agia Marina Chrysochous", "Giolou", "Simou", 
                            "Akourdaleia Pano", "Polemi", "Arodes Pano", "Peristerona Pafou", "Lasa", "Kato Paphos", "Drouseia", "Goudi", 
                            "Drymou", "Kallepeia", "Panagia Pano", "Agios Ioannis", "Agia Varvara", "Skoulli", "Salamiou", "Stroumpi Kato", 
                            "Nata", "Kelokedara", "Akourdaleia Kato", "Mousere", "Psathi", "Arodes Kato", "Galataria", "Trachypedoula", 
                            "Fyti", "Lemona", "Mamonia", "Eledio", "Mesana", "Arminou", "Gialia Kato", "Panagia Kato", "Theletra", "Choletria", 
                            "Kannaviou", "Praitori", "Akoursos", "Choli", "Milia Pafou", "Kynousa", "Kedares", "Letymvou", "Stroumpi Pano"
                          ].map((propertyLocation) => (
                            <li key={propertyLocation}>
                              <input
                                type="checkbox"
                                className="locationval"
                                value={propertyLocation}
                                id={`location-${propertyLocation}`}
                                onChange={handleLocationChange}
                                checked={selectedLocation.includes(propertyLocation)}
                              />
                              <span>{propertyLocation}</span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>

                    {/* Nicosia Region */}
                    <li className="Nicosia-reg1">
                      <input
                        type="checkbox"
                        className="regionval"
                        value="Nicosia"
                        id="region-Nicosia"
                        onChange={handleRegionChange}
                        checked={selectedRegions.includes('Nicosia')}
                      />
                      <span>Nicosia</span>

                      {/* Nested locations for Nicosia */}
                      {selectedRegions.includes('Nicosia') && (
                        <ul className="Nicosia-locations">
                          {[
                            "Tseri", "Strovolos", "Aglantzia", "Lakatameia", "Agia Varvara Lefkosias", "Egkomi", "Alampra", "Agios Dometios", 
                            "Kalo Chorio", "Nicosia", "Politiko", "Dali", "Latsia", "Agios Epifanios Oreinis", "Palouriotissa", "Ergates", 
                            "Lythrodontas", "Kokkinotrimithia", "Episkopeio", "Anageia", "Nisou", "Klirou", "Geri", "Potamia", "Agioi Omologites", 
                            "Palaiometocho", "Pera Chorio", "Moni Kato", "Deftera Pano", "Kampia", "Kalithea", "Psimolofou", "Gsp", "Akaki", 
                            "Pedoulas", "Lympia", "Agrokipia", "Carolina Park", "Agios Vasileios", "Parissinos", "Agia Paraskevi", "Stelmek", 
                            "Agios Georgios", "Galata", "Pyrgos Kato", "Agios Mamas", "Deftera Kato", "Kalopanagiotis", "Panag Evangelistria", 
                            "Chryseleousa", "Peristerona Lefkosias", "Agios Nikolaos", "Kakopetria", "Agioi Trimithias", "Anthoupolis", "Spilia", 
                            "Askas", "Agia Marina Xyliatou", "Pera", "Korakou", "Mammari", "Gourri", "Sia", "Deneia", "Kapedes", "Linou", 
                            "Synoikismos Anthoupolis", "Margi", "Mitsero", "Potami", "Astromeritis", "Ilioupoli", "Meniko", "Palaichori Oreinis", 
                            "Apliki", "Kaliana", "Panagia", "Mosfili", "Pyrgos Pano", "Evrychou", "Mathiatis", "Arediou", "Pachyammos", 
                            "Palaichori Morfou", "Analiontas", "Agios Theodoros Soleas", "Orounta", "Agios Ioannis Malountas", "Vyzakia", 
                            "Flasou Kato", "Pigenia", "Farmakas", "Lagoudera", "Nikitari", "Polystypos", "Xyliatos", "Nea Ledras", "Temvria", 
                            "Kampos", "Sinaoros", "Malounta", "Oikos", "Koutrafas Kato", "Katydata", "Alona"
                          ].map((propertyLocation) => (
                            <li key={propertyLocation}>
                              <input
                                type="checkbox"
                                className="locationval"
                                value={propertyLocation}
                                id={`location-${propertyLocation}`}
                                onChange={handleLocationChange}
                                checked={selectedLocation.includes(propertyLocation)}
                              />
                              <span>{propertyLocation}</span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>

                    {/* Famagusta Region */}
                    <li className="Famagusta-reg1">
                      <input
                        type="checkbox"
                        className="regionval"
                        value="Famagusta"
                        id="region-Famagusta"
                        onChange={handleRegionChange}
                        checked={selectedRegions.includes('Famagusta')}
                      />
                      <span>Famagusta</span>

                      {/* Nested locations for Famagusta */}
                      {selectedRegions.includes('Famagusta') && (
                        <ul className="Famagusta-locations">
                          {[
                            "Profitis Elias", "Paralimni", "Agia Napa", "Frenaros", "Sotira", "Kapparis", "Deryneia", "Cape Greko", 
                            "Pernera", "Protaras", "Vrysoulles", "Agia Thekla", "Konnos", "Avgorou", "Liopetri", "Agia Triada", "Dasaki Achnas"
                          ].map((propertyLocation) => (
                            <li key={propertyLocation}>
                              <input
                                type="checkbox"
                                className="locationval"
                                value={propertyLocation}
                                id={`location-${propertyLocation}`}
                                onChange={handleLocationChange}
                                checked={selectedLocation.includes(propertyLocation)}
                              />
                              <span>{propertyLocation}</span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  </ul>
                </div>
           {/*  <div className="location-toggle select-details">
              <ul className="location-list">
              {uniquePropertyRegion.length > 0 ? (
                uniquePropertyRegion.map((propertyRegion) => (
                  <li key={propertyRegion} className={`${propertyRegion}-reg1`}>
                    <input type="checkbox" className="regionval" value={propertyRegion} id={`region-${propertyRegion}`} onChange={handleRegionChange} checked={selectedRegions.includes(propertyRegion)} />
                    <span>{propertyRegion}</span>
                     {selectedRegions.includes(propertyRegion) && (
                      <ul className={`${propertyRegion}-locations`}>
                        {groupedLocations[propertyRegion].map((propertyLocation) => (
                          <li key={propertyLocation}>
                            <input type="checkbox" className="locationval" value={propertyLocation} id={`location-${propertyLocation}`} onChange={handleLocationChange} checked={selectedLocation.includes(propertyLocation)} />
                            <span>{propertyLocation}</span>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))
              ) : (
                <span>Regions Loading</span>
              )}
              </ul>
            </div> */}
          </div>
          <div className="type-column">
            <div className="visible-type selected-visible">
              <input type="text" id="type" placeholder="Type"
              value={
                selectedParentTypes.length === 0 && selectedTypes.length === 0
                  ? "Type"
                  : `${selectedParentTypes.join(', ')}${selectedParentTypes.length && selectedTypes.length ? " | " : ""}${selectedTypes.join(', ')}`
              }
              onChange={handleTypeChange} disabled />
              <i class="fa fa-chevron-down"></i>
            </div>  
            <div className="type-toggle select-details">              
              <ul className="type-list">
              <div className="parent-type-toggle select-details">
                    <ul className="parent-type-list">
                      {/* Houses ParentType */}
                      <li className="Houses-par1">
                        <input
                          type="checkbox"
                          className="parentval"
                          value="Houses"
                          id="parent-Houses"
                          onChange={handleParentTypeChange}
                          checked={selectedParentTypes.includes('Houses')}
                        />
                        <span>Houses</span>

                        {/* Nested property types for Houses */}
                        {selectedParentTypes.includes('Houses') && (
                          <ul className="Houses-propertyType">
                            {["Villa", "Detached House", "Maisonette", "Semi Detached"].map((propertyParentType) => (
                              <li key={propertyParentType}>
                                <input
                                  type="checkbox"
                                  className="typeval"
                                  value={propertyParentType}
                                  id={`type-${propertyParentType}`}
                                  onChange={handleTypeChange}
                                  checked={selectedTypes.includes(propertyParentType)}
                                />
                                <span>{propertyParentType}</span>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>

                      {/* Apartments ParentType */}
                      <li className="Apartments-par1">
                        <input
                          type="checkbox"
                          className="parentval"
                          value="Apartments"
                          id="parent-Apartments"
                          onChange={handleParentTypeChange}
                          checked={selectedParentTypes.includes('Apartments')}
                        />
                        <span>Apartments</span>

                        {/* Nested property types for Apartments */}
                        {selectedParentTypes.includes('Apartments') && (
                          <ul className="Apartments-propertyType">
                            {["Apartment", "Penthouse"].map((propertyParentType) => (
                              <li key={propertyParentType}>
                                <input
                                  type="checkbox"
                                  className="typeval"
                                  value={propertyParentType}
                                  id={`type-${propertyParentType}`}
                                  onChange={handleTypeChange}
                                  checked={selectedTypes.includes(propertyParentType)}
                                />
                                <span>{propertyParentType}</span>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                          
                      {/* Lands ParentType */}
                      {category !== 'rent' && (
  <li className="Lands-par1">
    <input
      type="checkbox"
      className="parentval"
      value="Lands"
      id="parent-Lands"
      onChange={handleParentTypeChange}
      checked={selectedParentTypes.includes('Lands')}
    />

    <span>Lands</span>

    {/* Nested property types for Lands */}
    {selectedParentTypes.includes('Lands') && (
      <ul className="Lands-propertyType">
        {[
          "Agricultural Land", "Industrial Land", "Residential Land",
          "Commercial Land", "Tourist Land"
        ].map((propertyParentType) => (
          <li key={propertyParentType}>
            <input
              type="checkbox"
              className="typeval"
              value={propertyParentType}
              id={`type-${propertyParentType}`}
              onChange={handleTypeChange}
              checked={selectedTypes.includes(propertyParentType)}
            />
            <span>{propertyParentType}</span>
          </li>
        ))}
      </ul>
    )}
  </li>
)}

                    </ul>
                  </div>
             {/*  {uniqueParentType.length > 0 ? (
                  uniqueParentType.map((parentType) => (
                    <li key={parentType} className={`${parentType}-par1`}>
                      <input type="checkbox" className="parentval" value={parentType} id={`parent-${parentType}`} onChange={handleParentTypeChange} checked={selectedParentTypes.includes(parentType)} />
                      <span>{parentType}</span>
  
                  
                      {selectedParentTypes.includes(parentType) && (
                        <ul className={`${parentType}-propertyType`}>
                          {groupedParentType[parentType].map((propertyParentType) => (
                            <li key={propertyParentType}>
                              <input type="checkbox" className="typeval" value={propertyParentType} id={`type-${propertyParentType}`} onChange={handleTypeChange} checked={selectedTypes.includes(propertyParentType)} />
                              <span>{propertyParentType}</span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))
                ) : (
                  <span>parent_type Loading</span>
                )} */}
              {/* {uniquePropertyTypes.map((propertyType) => (
                <li key={propertyType}><input type="checkbox" className="typeval" value={propertyType} onChange={handleTypeChange} checked={selectedTypes.includes(propertyType)}/>
                  <span>{propertyType}</span>
                </li>              
              ))} */}
              </ul>
            </div>
          </div>
          <div className="price-column">  
            <div className="visible-price selected-visible">
              <input type="text" id="pricerange" placeholder="Price"  value={`€ ${minPrice ? new Intl.NumberFormat('en-US').format(minPrice) : ''} - € ${maxPrice ? new Intl.NumberFormat('en-US').format(maxPrice) : ''}`} disabled />
              <i class="fa fa-chevron-down"></i>
            </div>        
            <div className="price-toggle select-details">
              <span>Min Price (€)</span>
              <input type="text" name="price-min" id="pricemin" placeholder="-" value={minPrice ? new Intl.NumberFormat('en-US').format(minPrice) : ''} onChange={handleMinPriceChange} />
              <span>Max Price (€)</span>
              <input type="text" name="price-max" id="pricemax" placeholder="-" value={maxPrice ? new Intl.NumberFormat('en-US').format(maxPrice) : ''} onChange={handleMaxPriceChange} />
            </div>
          </div>
          <div className="condition-column">
            <div className="visible-condition selected-visible">
              <input type="text" id="condition" placeholder="Condition" value={selectedConditions} disabled />
              <i class="fa fa-chevron-down"></i>
            </div>  
            <div className="condition-toggle select-details">
              <ul className="condition-list">
              {["Resale", "Brand new", "Under construction", "N/A"].map((propertyCondition) => (
                  <li key={propertyCondition}>
                    <input
                      type="checkbox"
                      className="condval"
                      value={propertyCondition}
                      onChange={handleConditionChange}
                      checked={selectedConditions.includes(propertyCondition)}
                    />
                    <span>{propertyCondition}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="beds-column"> 
            <div className="visible-beds selected-visible">
              <input type="text" id="bedsrange" placeholder="Beds" value={`${minBeds} - ${maxBeds} Beds`} disabled />
              <i class="fa fa-chevron-down"></i>
            </div>       
            <div className="beds-toggle select-details">
              <span>Min Beds</span>
              <input type="text" name="bed-min" id="bedmin" placeholder="-" value={minBeds} onChange={handleMinBedsChange} />
              <span>Max Beds</span>
              <input type="text" name="bed-max" id="bedmix" placeholder="-" value={maxBeds} onChange={handleMaxBedsChange} />
            </div>
          </div>
          <div className="area-column">
            <div className="visible-area selected-visible">
                <input type="text" id="sqftrange" placeholder="SQM"  value={`${minSqft} - ${maxSqft} SQM`} disabled/>
                <i class="fa fa-chevron-down"></i>
            </div>
            <div className="area-toggle select-details">
              <span>Min Sq (m<sup>2</sup>)</span>
              <input type="text" name="sqft-min" id="sqdmin" placeholder="-" value={minSqft} onChange={handleMinSqftChange} />
              <span>Max Sq (m<sup>2</sup>)</span>
              <input type="text" name="sqft-max" id="sqdmix" placeholder="-" value={maxSqft} onChange={handleMaxSqftChange} />
            </div>            
          </div>
          {isRegionVisible && (
          <div className="area-column yield-area">
            <div className="visible-area selected-visible">
              <input type="text" id="yieldrange" placeholder="Yield" value={minYield && maxYield ? `${minYield} - ${maxYield}` : ''}disabled/>
            </div>
            <div className="area-toggle select-details">
              <span>Min Yield</span>
              <input type="text" name="yieldmin" id="yieldmin" placeholder="-" value={minYield} onChange={handleMinYieldChange}/>
              <span>Max Yield</span>
              <input type="text" name="yieldmax" id="yieldmax" placeholder="-" value={maxYield} onChange={handleMaxYieldChange} />
            </div>
          </div>
          )}
            <div className="searchprop">
                <button type="submit" className="Searchbtn">Search</button>
            </div>


          </form>
        </div>


        {/* Property cards */}
        <div className="card-container">
        {allproperties.length > 0 ? (
          allproperties.map((property) => (
            <div className="card" key={property.property_id}>  
            {/*`https://propertyone.com.cy/properties/5360307_2-bedroom-apartment-to-rent/`
               `https://${domainName}:3000/property/rent/1`
            */}
           <Link 
  to={`/properties/${property.property_id.split('-')[1]}_${property.bedrooms}-bedroom-${property.property_type.toLowerCase()}-to-${category}`} 
  target="_blank" rel="noopener noreferrer" className="view-more-btn">
              <div
                className="card-image"
                style={{
                  backgroundImage: `url(${property.features_image || ''})`,
                }}
              >
              </div>
              <div className="card-category">{property.bedrooms ==='0' ? '':property.bedrooms}{property.parent_type !== 'Lands' ? 'Bed' : ''}  {property.property_type} For {category}</div>
                <div className="card-price">€ {property.price ? new Intl.NumberFormat('en-US').format(property.price) : 'N/A'}
                {category === 'buy' && property.parent_type !=='Lands' ? (<p>{Math.round(property.rental_yield) || 'N/A'}% Yield</p>) : null}
                </div>
                <div className="card-yield"></div>

              <div className="card-details">
                <h2>{property.property_title || 'No title available'}</h2>
                <p className="location-region">
                  <strong>{property.property_type || 'N/A'}</strong> | {property.location || 'N/A'}, {property.region || 'N/A'}
                </p>

                <div className="card-hover-details">
                  <p>€{property.price ? new Intl.NumberFormat('en-US').format(property.price) : 'N/A'}</p>
                  <p>{property.property_type || 'N/A'}</p>
                  <p>{property.location || 'N/A'}</p>
                  <p>{property.region || 'N/A'}</p>
                  <p className="see-more-btn">See More</p>
                  
                </div>
              </div>
              </Link>
            </div>
             ))
          ) : (
            showNoPropertiesMessage && (
              <div className="no-properties-found">
                <h2>No Properties found matching your search criteria.</h2>
              </div>
            )
          )}
        </div>

        {/* Loading state */}
        {loading && <Loader />}

        {/* No more data message */}
        {!hasMore && <div>No more properties to load</div>}
      </div>
      <Footer />
     
    </div>
    </div>
  );
};

export default PropertyPage;
 