// src/components/Footer.js
import React from 'react';
import './footer.css'; // Make sure to link the correct path to your CSS

const Footer = () => {
  const host = window.location.hostname; 
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-top-sec">
          {/* Left Menu */}
          <div className="left-menu">
            <h4>Sitemap</h4>
            <ul>
            <li><a href={`https://${host}/about/`}>About</a></li>
              {/* <li><a href={`https://${host}/services/`}>Services</a></li>
               <li><a href={`https://${host}/contact/`}>Contact</a></li>*/}
              <li><a href={`https://${host}/sell/`}>Sell</a></li>
              <li><a href={`https://${host}/lease/`}>Lease</a></li>
            </ul>
          </div>

          {/* Center Logo */}
          <div className="center-logo">
            <a href={`https://${host}`}>
              <img
                src="../images/PropertyOne-logo.svg"
                alt="Property One Logo"
                width="510"
                height="456"
              />
            </a>
          </div>

          {/* Right Menu */}
          <div className="right-menu">
            <h4>Connect</h4>
            <ul>
              <li>
                <a href="tel:+357.22.285635"> +357.22.285635</a>
              </li>
              <li><a href="mailto:info@propertyone.com.cy">info@propertyone.com.cy</a></li>
              <li><a href='https://maps.app.goo.gl/6ZT7jTzP2GVD9NfN7' target="_blank" >9, Navarinou 1100, Nicosia</a></li>
            </ul>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="footer-bottom">
          <div className="left-text">
            <p>RN1026.LN510/E</p>
           </div>

          {/* Right Social Icons */}
          <div className="right-socail-icon">
            <ul>
              <li>
                <a href="mailto:info@propertyone.com.cy">
                  <i className="fas fa-at"></i>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/propertyonehq/" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/propertyonehq" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-facebook"></i>
                </a>
              </li>
              <li>
                <a href="https://t.me/+35796371372" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-telegram"></i>
                </a>
              </li>
              <li>
                <a href="https://wa.me/+35796371372" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-whatsapp"></i>
                </a>
              </li>
              <li className="phone-btn">
                <a href="tel:+35722285635">
                  <i className="fas fa-phone-square-alt"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="right-socail-icon mobile-fixed">
            <ul>
              <li>
                <a href="mailto:info@propertyone.com.cy">
                  <i className="fas fa-at"></i>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/propertyonehq/" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/propertyonehq" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-facebook"></i>
                </a>
              </li>
              <li>
                <a href="https://t.me/+35796371372" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-telegram"></i>
                </a>
              </li>
              <li>
                <a href="https://wa.me/+35796371372" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-whatsapp"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
