import React, { useState, useEffect } from 'react';
import { FaTimes } from "react-icons/fa";

const RequestInfoPopup = ({ 
  onClose ,  
  propertyId, 
    propertyType, 
    maxBudget, 
    leadType, 
    beds,
    description, 
    citiesOfInterest,
    datasource,
     urlParams = new URLSearchParams(window.location.search)
  }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    propertyType: [propertyType],
    maxBudget: maxBudget ,
    leadType:[leadType],
    beds: [beds],
    citiesOfInterest:[citiesOfInterest]  ,
    country: '',
    city: '',
    description: `Property URL:${window.location.href.split('?')[0]}
 
Data Source: ${datasource}
 
UTM Details:
Campaign ID: ${urlParams.get('utm_id')}
Campaign Source: ${urlParams.get('utm_source')}
Campaign Medium: ${urlParams.get('utm_medium')}
Campaign Name: ${urlParams.get('utm_campaign')}
Campaign Term: ${urlParams.get('utm_term')}
Campaign Content: ${urlParams.get('utm_content')}
`,
    leadSource: 'XML Portals',
  });
  const domainName = window.location.hostname;

  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        // Fetching from the ip-api service
        const response = await fetch('http://ip-api.com/json');
        const data = await response.json();

        if (data && data.country && data.city) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            country: data.country, // Update the country
            city: data.city,       // Update the city
          }));
        }
      } catch (error) {
        console.error('Error fetching location data:', error);
      }
    };

    fetchLocationData();
   
  }, []);  


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleMultiSelectChange = (e) => {
    const { name, options } = e.target;
    const selectedValues = Array.from(options)
      .filter((option) => option.selected)
      .map((option) => option.value);
    setFormData({
      ...formData,
      [name]: selectedValues,
    });
  };

  const validateEmail = () => {
    const email = formData.email;
    const atpos = email.indexOf('@');
    const dotpos = email.lastIndexOf('.');
    const emailInputElement = document.getElementById('Email');

    if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= email.length) {
      //alert('Please enter a valid email address.');
      emailInputElement.style.borderColor = "red";
      return false;
    }
    emailInputElement.style.borderColor = "";
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    let isFormValid = true; // To track if the form is valid

    // Check each required field and set red border if empty
    if (!formData.firstName) {
      document.getElementById('First_Name').style.borderColor = "red";
      isFormValid = false;  // Mark the form as invalid
    } else {
      document.getElementById('First_Name').style.borderColor = "";
    }
  
    if (!formData.lastName) {
      document.getElementById('Last_Name').style.borderColor = "red";
      isFormValid = false;  // Mark the form as invalid
    } else {
      document.getElementById('Last_Name').style.borderColor = "";
    }
  
    if (!formData.email) {
      document.getElementById('Email').style.borderColor = "red";
      isFormValid = false;  // Mark the form as invalid
    } else {
      document.getElementById('Email').style.borderColor = "";
    }
  
    if (!formData.phone) {
      document.getElementById('Phone').style.borderColor = "red";
      isFormValid = false;  // Mark the form as invalid
    } else {
      document.getElementById('Phone').style.borderColor = "";
    }
  
    // If any required field is empty, prevent form submission
    if (!isFormValid) {
      return;
    }
  
    if (!validateEmail()) return;
  
    // Find the form
    const form = document.getElementById('webform745708000000438001');
    if (!form) {
      console.error("Form not found!");
      return;
    }
  
    // Log the form to check for existence of elements
    console.log(form);
  
    const formFields = ['firstName', 'lastName', 'email', 'phone', 'propertyType', 'maxBudget', 'leadType', 'beds', 'citiesOfInterest', 'country', 'city', 'description', 'leadSource'];
    
    formFields.forEach((field) => {
      const input = form.querySelector(`[name=${field}]`);
      if (input) {
        input.value = formData[field];
      } else {
        console.warn(`Field with name ${field} not found in the form.`);
      }
    });
  
    // Submit the form
     form.submit(); 
  };
  

  // Reset form data
  const handleReset = () => {
    document.getElementById('First_Name').style.borderColor = "";
    document.getElementById('Last_Name').style.borderColor = "";
    document.getElementById('Email').style.borderColor = "";
    document.getElementById('Phone').style.borderColor = "";
    setFormData({
      ...formData,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    });
  };
  console.log(formData);
  return (
    <div id="request-info-popup" className="request-form">
      <div className="inner-request-form">
        
        <span
                id="close-register-popup-alert-form"
                onClick={onClose}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  fontSize: "20px", // Adjust size if needed
                  color: "#000",
                }}
              >
                <FaTimes />
              </span>
        <h3>Request Info</h3>

        <div
          id="crmWebToEntityForm"
          className="zcwf_lblLeft crmWebToEntityForm"
          style={{ backgroundColor: 'white', color: 'black', maxWidth: '600px' }}
        >
         {/* Do not remove this --- Analytics Tracking code starts --> */}
         <script id='wf_anal' src='https://crm.zohopublic.eu/crm/WebFormAnalyticsServeServlet?rid=617dbb6067034720b4cad8d58ca61076bcda7489198f97091270a5848a861160b4a571db844c70130306f475a7af418cgid24da5c9ce81169a934fd7734c7aeac20c8680dd4c36f31dc0c5aa8082054612bgida53217d45b3df7e42fd3fe62ff13c6d9c46e2332d66365259721eed789b67600gid8674097c16eead13c3d87d4a0133c0e63cef245df0745724b284543cbcf68cd2&tw=9ff967bc054c8cb128e22ad9b966b7c7f484ad76dddba8b21a41a9ee8ef983d2'></script>
           {/*<!-- Do not remove this --- Analytics Tracking code ends. -->*/}
         <form id='webform745708000000438001' action='https://crm.zoho.eu/crm/WebToLeadForm' name='WebToLeads745708000000438001' method='POST' acceptCharset='UTF-8'onSubmit={handleSubmit}>
         <input type='text' style={{ display: 'none' }} name='xnQsjsdp' value='26a237614b31914c34ad26f89a20ce1a3c56019eb548c7fbe292fa765610f89c' />
        <input type='hidden' name='zc_gad' id='zc_gad' value='' />
        <input type='text' style={{ display: 'none' }} name='xmIwtLD' value='15808ee5adab482c8faf72ee7501a5e09104b5fc502f1e9bf73fe123a055dac1f7179259d9775e18c99e598cf6a7800e' />
        <input type='text' style={{ display: 'none' }} name='actionType' value='TGVhZHM=' />
        <input type='text' style={{ display: 'none' }} name='returnURL' value={`https://${domainName}/thank-you/`} />

            <div className="zcwf_title" style={{ maxWidth: '600px', color: 'black', fontFamily: 'Arial' }}>
              Property Buy Request Info
            </div>
            <div className="zcwf_row">
              <div className="zcwf_col_lab"></div>
              <div className="zcwf_col_fld">
                <input
                  type="text"
                  id="First_Name"
                  name="First Name"
                  value={formData.firstName}
                  onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                  placeholder="First Name"
                  maxLength="40"
                />
              </div>
            </div>
            <div className="zcwf_row">
              <div className="zcwf_col_lab"></div>
              <div className="zcwf_col_fld">
                <input
                  type="text"
                  id="Last_Name"
                  name="Last Name"
                  value={formData.lastName}
                  onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                  placeholder="Last Name"
                  maxLength="80"
                />
              </div>
            </div>
            <div className="zcwf_row">
              <div className="zcwf_col_lab"></div>
              <div className="zcwf_col_fld">
                <input
                  type="email"
                  id="Email"
                  name="Email"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  placeholder="Email"
                  maxLength="100"
                />
              </div>
            </div>
            <div className="zcwf_row">
              <div className="zcwf_col_lab"></div>
              <div className="zcwf_col_fld">
                <input
                  type="text"
                  id="Phone"
                  name="Phone"
                  value={formData.phone}
                  onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                  placeholder="Phone"
                  maxLength="30"
                />
              </div>
            </div>
            <div className="zcwf_row wfrm_fld_dpNn" >
              <div className="zcwf_col_lab">
                <label htmlFor="LEADCF6">Property Type</label>
              </div>
              <div className="zcwf_col_fld">
                <select
                  id="LEADCF6"
                  name="LEADCF6"
                  multiple
                  value={formData.propertyType}
                  onChange={(e) => setFormData({ ...formData, propertyType: [e.target.value] })}
                >
                  <option value="Apartment">Apartment</option>
                  <option value="Penthouse">Penthouse</option>
                  <option value="Detached House">Detached House</option>
                  <option value="Semi-detached House">Semi-detached House</option>
                  <option value="Villa">Villa</option>
                  <option value="Plot">Plot</option>
                  <option value="Land" selected="selected">Land</option>
                </select>
              </div>
            </div>
            <div className="zcwf_row wfrm_fld_dpNn">
              <div className="zcwf_col_lab">
                <label htmlFor="LEADCF66">Max Budget</label>
              </div>
              <div className="zcwf_col_fld">
                <input
                  type="text"
                  id="LEADCF66"
                  name="LEADCF66"
                  value={formData.maxBudget}
                  onChange={(e) => setFormData({ ...formData, maxBudget: e.target.value })}
                  maxLength="16"
                />
              </div>
            </div>
            <div className="zcwf_row wfrm_fld_dpNn">
              <div className="zcwf_col_lab">
                <label htmlFor="LEADCF1">Lead Type</label>
              </div>
              <div className="zcwf_col_fld">
                <select
                  id="LEADCF1"
                  name="LEADCF1"
                  value={formData.leadType}
                  onChange={(e) => setFormData({ ...formData, leadType: [e.target.value] })}
                >
                  <option value="-None-">-None-</option>
                  <option value="Buyer">Buyer</option>
                  <option value="Renter">Renter</option>
                  <option value="land">Landlord</option>
                  <option value="Unknown">Unknown</option>
                </select>
              </div>
            </div>
            <div className="zcwf_row wfrm_fld_dpNn">
              <div className="zcwf_col_lab">
                <label htmlFor="LEADCF5">Beds</label>
              </div>
              <div className="zcwf_col_fld">
                <select
                  id="LEADCF5"
                  name="LEADCF5"
                  multiple
                  value={formData.beds}
                  onChange={(e) => setFormData({ ...formData, beds: [e.target.value] })}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6+">6+</option>
                </select>
              </div>
            </div>
            <div className="zcwf_row wfrm_fld_dpNn">
              <div className="zcwf_col_lab">
                <label htmlFor="LEADCF2">Cities of Interest</label>
              </div>
              <div className="zcwf_col_fld">
              <select
              id="LEADCF2"
              name="LEADCF2"
              multiple
              value={formData.citiesOfInterest}
              onChange={(e) => setFormData({ ...formData, city: e.target.value })}
            >
              {['Nicosia', 'Limassol', 'Larnaca', 'Paphos', 'Famagusta'].map((city) => (
                <option key={city} value={city}>
                  {city}
                </option>
              ))}
            </select>
              </div>
            </div>
            <div className="zcwf_row wfrm_fld_dpNn">
              <div className="zcwf_col_lab">
                <label htmlFor="Country">Country</label>
              </div>
              <div className="zcwf_col_fld">
                <input
          type="text"
          name='Country'
          value={formData.country}
          onChange={(e) => setFormData({ ...formData, country: e.target.value })}
        />
              </div>
            </div>
            <div className="zcwf_row wfrm_fld_dpNn">
              <div className="zcwf_col_lab">
                <label htmlFor="City">City</label>
              </div>
              <div className="zcwf_col_fld">
              <input
          type="text"
          name='City'
          value={formData.city}
          onChange={(e) => setFormData({ ...formData, city: e.target.value })}
        />
              </div>
            </div>
            <div className="zcwf_row wfrm_fld_dpNn">
              <div className="zcwf_col_lab">
                <label htmlFor="Description">Description</label>
              </div>
              <div className="zcwf_col_fld">
                <textarea
                  id="Description"
                  name="Description"
                  value={formData.description}
                />
              </div>
            </div>
            <div className="zcwf_row wfrm_fld_dpNn">
              <div className="zcwf_col_lab">
                <label htmlFor="Lead_Source">Lead Source</label>
              </div>
              <div className="zcwf_col_fld">
                <select
                  id="Lead_Source"
                  name='Lead Source'
                  value={formData.leadSource}
                  onChange={handleChange}
                >
                   <option value='-None-'>-None-</option> <option value='Advertisement'>Advertisement</option> <option selected value='XML&#x20;Portals'>XML Portals</option> <option value='Cold&#x20;Call'>Cold Call</option> <option value='Employee&#x20;Referral'>Employee Referral</option> <option value='External&#x20;Referral'>External Referral</option> <option value='Partner'>Partner</option> <option value='Online&#x20;Store'>Online Store</option> <option value='Public&#x20;Relations'>Public Relations</option> <option value='Trade&#x20;Show'>Trade Show</option> <option value='Sales&#x20;Email&#x20;Alias'>Sales Email Alias</option> <option value='Seminar&#x20;Partner'>Seminar Partner</option> <option value='Facebook'>Facebook</option> <option value='Internal&#x20;Seminar'>Internal Seminar</option> <option value='X&#x20;&#x28;Twitter&#x29;'>X &#x28;Twitter&#x29;</option> <option value='Web&#x20;Download'>Web Download</option> <option value='Web&#x20;Research'>Web Research</option> <option value='Chat'>Chat</option> 
                </select>
              </div>
            </div>
            <div className="zcwf_row ">
              <div className="zcwf_col_lab"></div>
              <div className="zcwf_col_fld form-btn-sec">
                <input
                  type="submit"
                  id="formsubmit"
                  className="formsubmit zcwf_button"
                  value="Submit"
                />
                <input
                  type="button"
                  className="zcwf_button"
                  name="reset"
                  value="Reset"
                  onClick={handleReset}  // Calling the custom reset function
                />
              </div>
            </div>
          
          </form>
        </div>
      </div>
    </div>
  );
};

export default RequestInfoPopup;
