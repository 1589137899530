import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Header from './Header';
import Loader from './Loader';
import Footer from './Footer';

const PropertyPage = () => {
    const [propertydata, setApiData] = useState([]);  // Initialize as an empty array
    const [propertyUrl, setPropertyUrl] = useState('');
    const [agencyUrl, setAgencyUrl] = useState('');
    const [loadingproperty, setLoading] = useState(false);
    const [loadingAgency, setLoadingAgency] = useState(false);
    const [propertyDetails, setPropertyDetails] = useState(null);
    const [AgencyDetails, setAgencyDetails] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorMessageag, setErrorMessageag] = useState('');
    const [searchAttempted, setSearchAttempted] = useState(false); 
    const [searchAttemptedag, setSearchAttemptedag] = useState(false); 
    const domainName = window.location.hostname;

    useEffect(() => {
        fetch(`https://${domainName}:5000/api/filtersource`)
          .then(response => response.json()) // Parse the JSON response
          .then(data => {
            // Check the structure of the received data
            console.log('Fetched data:', data);
            if (Array.isArray(data)) {
                setApiData(data); // Set the fetched data if it's an array
            } else {
                console.error('Fetched data is not an array');
            }
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      }, []);
    
    const handlePropertySearch = () => {
        if (!propertyUrl || !isValidUrl(propertyUrl)) {
            setErrorMessage('Please enter a valid link');
        } else {
            setErrorMessage('');
            console.log('Searching for:', propertyUrl);
        }
        setLoading(true);

        setTimeout(() => {
            if (propertyUrl !== '') {
               // const propertyIdFromUrl = propertyUrl.split('/').pop().split('_')[0]; // Extracts "4424521"

                const propertyIdFromUrl = propertyUrl.split('/')[4].split('_')[0];
                              
                const matchedProperty = propertydata.find(property => {
                    const id = property.property_id.split('-')[1]; // Extracts "4424521"
                    return id === propertyIdFromUrl;
                });


                if (matchedProperty) {
                    setPropertyDetails(matchedProperty); // Store matched property details
                } else {
                    setPropertyDetails(null); // No match found
                    setSearchAttempted(true);
                }
            }
            setLoading(false);
        }, 1000);
    };

    const handleAgencySearch = () => {
        if (!agencyUrl || !isValidUrl(agencyUrl)) {
            setErrorMessageag('Please enter a valid link');
        } else {
            setErrorMessageag('');
            console.log('Searching for:', agencyUrl);
        }
        setLoadingAgency(true);
        setTimeout(() => {
            if (agencyUrl !== '') {
                const propertyIdFromUrl = agencyUrl.split('/')[4].split('_')[0];
                
                const matchedPropertyag = propertydata.find(property => {
                    const id = property.property_id.split('-')[1]; // Extracts "4424521"
                    return id === propertyIdFromUrl;
                });
                console.log(matchedPropertyag,' metch agency');
                
                if (matchedPropertyag) {
                    setAgencyDetails(matchedPropertyag); // Store matched property details
                } else {
                    setAgencyDetails(null); // No match found
                    setSearchAttemptedag(true);
                }
            }
            setLoadingAgency(false);
        }, 1000);
    };

    const isValidUrl = (url) => {
         const regex = /^(ftp|http|https):\/\/[^\s/$.?#].[^\s]*$/;  // Improved regex to cover more cases        
         return regex.test(url);       
    };
    

    return (
        <div>
            <Header />
            <div className="banner-header">
                <h1>Agents Source</h1>
            </div>
            <div className="main-sec">
                <div className="container">
                    <div className="filter-sec property-source-outer">
                        <div className="container">
                            <div className="property-source-sec">
                                <h3>Source Link</h3>
                                <input type="text" name="propertys" id="propertys" placeholder="Enter Property Url" value={propertyUrl} onChange={(e) => setPropertyUrl(e.target.value)} />
                                <button id="source_button" onClick={handlePropertySearch}>Search Source</button>
                                {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                                <div className="list-property">
                                    {propertyDetails && (
                                        <div className="property-details">
                                            {propertyDetails.datasource && (
                                                <a href={propertyDetails.datasource} target="_blank" rel="noopener noreferrer">{propertyDetails.datasource}</a>
                                            )}
                                        </div>
                                    )}
                                    {searchAttempted && (
                                        <div className="property-details">
                                         <h2>No Source Link found.</h2>
                                        </div> 
                                    )}

                                </div>
                                {loadingproperty && (
                                    <div className="loader-img">
                                        <img src="../images/loader-img.gif" alt="Loading..." />
                                    </div>
                                )}
                            </div>

                            <div className="property-source-sec">
                                <h3>Agency Link</h3>
                                <input type="text" name="propertys" id="propertys-agency" placeholder="Enter Property Url" value={agencyUrl} onChange={(e) => setAgencyUrl(e.target.value)} />
                                <button id="source_button_agency" onClick={handleAgencySearch}>Search Agency</button>
                                {errorMessageag && <div style={{ color: 'red' }}>{errorMessageag}</div>}
                                <div className="list-property-agency">
                                    {AgencyDetails && (
                                        <div className="property-details">
                                            {AgencyDetails.datasource && (
                                                <>                                                
                                                <Link 
                                                    to={`/properties/${AgencyDetails.property_id.split('-')[1]}_${AgencyDetails.bedrooms}-bedroom-${AgencyDetails.property_type ? AgencyDetails.property_type.toLowerCase() : 'unknown'}-to-${AgencyDetails.buy_rent_category}`} 
                                                    className="view-more-btn">
                                                     {`https://${domainName}/properties/${AgencyDetails.property_id.split('-')[1]}_${AgencyDetails.bedrooms}-bedroom-${AgencyDetails.property_type ? AgencyDetails.property_type.replace(/\s+/g, '-').toLowerCase(): 'unknown'}-to-${AgencyDetails.buy_rent_category}`}
                                                </Link>
                                                 </>
                                            ) }

                                            
                                            
                                        </div>
                                    )}
                                    {searchAttemptedag && (
                                        <div className="property-details">
                                         <h2>No Agency Link found.</h2>
                                        </div>
                                    )}
                                </div>
                                {loadingAgency && (
                                    <div className="loader-agency">
                                        <img src="../images/loader-img.gif" alt="Loading..." />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default PropertyPage;
