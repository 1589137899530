import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams , useLocation} from 'react-router-dom'; 
import Header from './Header';
import Footer from './Footer';
import Loader from './Loader';
import MapComponent from './MapComponent';
import RequestInfoForm from './requestInfoForm';  // access request form  
import RequestViewingForm from './requestViewingForm'
import ShareWithFriendPopup from './shareWithFriendPopup';  // access request form  
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';

//import './pdfStyle.css';  //


const PropertyDetail = () => {
    const { id, category  } = useParams(); // Access the property ID and category from the URL
    const [property, setProperty] = useState(null);
    const [propertyImages, setPropertyImages] = useState([]);
    const [Propertyavg_price, setPropertyavg_price] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showForm, setShowForm] = useState(false); // State to toggle the visibility of the form
    const [showViewForm, setShowViewForm] = useState(false); 
    const [showSharePopup, setShowSharePopup] = useState(false); 
    const [session_id, setSessionid] = useState(null); 
    const [isSaved, setIsSaved] = useState(false);  // Initialize with false
    const domainName = window.location.hostname;
    const urllocation = useLocation();
    const queryParams = new URLSearchParams(urllocation.search);
    const type = queryParams.get('type');
//alert('param type is ',type);
    useEffect(() => {
    const sessionid = localStorage.getItem('id');  // Retrieve 'id' from sessionStorage
   //const sessionid = '34';
      console.log(sessionid,'session id value');
      if (sessionid) {
        setSessionid(sessionid); // Set the sessionid state if found
      }
    }, []);  
 
    const apiUrl = `https://${domainName}:5000/api/saveproperty`;

    const handleSaveClick = async (property_id) => {
      if (!session_id) {
        console.error('Session ID is not available');
        const loginDiv = document.getElementById('login_check');
        if (loginDiv) {
          loginDiv.style.display = 'block'; // Change display to 'block' to make it visible
        }
        return;  // Do not proceed if Sessionid is not available
      }
      try {      
        const requestData = {          
          property_id,
          session_id,
        };

        const response = await axios.post(apiUrl, requestData);
  
        if (response.data.token) {
              console.log('Property updated:');
              sessionStorage.setItem('property_save_id', response.data.property_id);
              setIsSaved(true);
        } else {
            console.error('Error:');
        }
      } catch (error) {
          console.error('Network error:', error);
      }
  };
  
  
    useEffect(() => {
      const fetchProperty = async () => {
        try {
          const response = await axios.get(`https://${domainName}:5000/api/property/${category}/${id}?property_type=${type}`); //?type=exclusive
          console.log('API Response:', response.data);

          if (response.data && response.data.property) {
            setProperty(response.data.property);
            setPropertyImages(response.data.propertyImages);
            setPropertyavg_price(response.data.locationData);
          } else {
            setError('No property found for the given ID');
          }
        } catch (err) {
          console.error('Error fetching property data:', err);
          setError('There was an error fetching the property data');
        } finally {
          setLoading(false);
        }
      };

      fetchProperty(); 
    }, [id, category]);

    if (loading) {
      return <div> {loading && <Loader />}</div>;
    }

    if (error) {
      return (
        <div className="property-detail property-detail-pdf">
                <Helmet>
                  <meta charset="UTF-8" />
                  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                  <title>Home-Property One</title>
                  <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.1/jquery.min.js"></script>
                </Helmet>
                <Header activeMarket="home" />
                
                <div
                  className="banner-header"
                  style={{ backgroundImage: 'url(/images/header-background.jpg)' }}
                >
                   <h1>Properties For Buy Or Sale in Cyprus</h1>
                </div>
                <div className="properties-head-sec">
                     <div className="container">
                      <h4 style={{textAlign:'center'}}>Above property Not available Please view another one</h4>
                     </div>
                </div>
                <Footer />
              </div>
       /*  <div>
          <h2>Error: {error}</h2>
          <p>Please check if the property ID is correct or try again later.</p>
        </div> */
      );
    }

/*     if (!property || property.length === 0) {
      return <div>No property found for this ID.</div>;
    } */

    const propertyDetails = property;
    //const backgroundImage = propertyDetails.background_image || 'images/listings-bg.jpg'; // Default background image

    const location = `${propertyDetails.region || ''} ${propertyDetails.location || ''}`.trim();
    return (
      <div className="property-detail property-detail-pdf">
         <Helmet>
                <title>{id}</title>  
                <meta name="title" content={`${propertyDetails.property_title} in [${propertyDetails.location}]| PropertyOne`}></meta>
                <meta name="description" content={`${propertyDetails.short_description} [${propertyDetails.location}] | PropertyOne`}></meta>
         </Helmet>
        <Header />
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.5.1/jspdf.umd.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/html2canvas/0.4.1/html2canvas.min.js"></script>

        {/* Banner Header with dynamic background image */}
        <div 
          className="banner-header" 
          style={{ backgroundImage: `url(${propertyDetails.property_added_status === 1 || propertyDetails.property_added_status === "1" ? `https://${domainName}:5000/uploads/${propertyDetails.features_image}`: propertyDetails.features_image})` }}
        >
          <div className='single-post-banner-dis'>
              <h1>{propertyDetails.bedrooms ==='0' ? '':propertyDetails.bedrooms +' Bed'} {propertyDetails.property_type } - {propertyDetails.property_area ==='N/A' ? '': propertyDetails.property_area} {propertyDetails.region }, {propertyDetails.location }</h1>
              <div className="price-text">€{propertyDetails.price ? new Intl.NumberFormat().format(propertyDetails.price) : ''}</div>
          </div>
        </div>
        {/* properties-head-sec */}
        <div className="properties-head-sec">
  <div className="container">
    <ul>
    <li className="save-prop" >
        <a href="javascript:void(0)" id="save" className={isSaved ? 'saved' : ''} onClick={() => handleSaveClick(propertyDetails.property_id)}>
          <i className="far fa-heart"></i> Save
        </a>
        <div id="login_check" style={{ display: 'none' }}>
          <p>
            Please <a href={`http://${domainName}/login/`}>Login</a> or{' '}
            <a href={`}http://${domainName}/register/`}>Register</a> With Us To Save Properties
          </p>
        </div>
      </li>
      <li className={`view-info-prop`} >
      <a href="javascript:void(0)" className="request info-popup-form" onClick={() => setShowForm(true)}>
        Request Info 
      </a>

      {/* Conditionally render the RequestInfoForm */}
      {showForm && propertyDetails ? (
        <RequestInfoForm 
          onClose={() => setShowForm(false)}
          propertyId={propertyDetails.pro_id}
          propertyType={propertyDetails.property_type}  
          maxBudget={propertyDetails.price} 
          leadType={propertyDetails.buy_rent_category ==='buy'?'Buyer':'Renter'}
          beds={propertyDetails.bedrooms[0]} 
          citiesOfInterest={propertyDetails.region} 
          datasource={propertyDetails.datasource} 
        />
      ) : (
        ''// If propertyDetails is not ready, show a loading message
      )}

        <a href="javascript:void(0)" className="request viewing-popup-form" onClick={() => setShowViewForm(true)}>
          Request Viewing
        </a>
        {showViewForm && propertyDetails ? (
        <RequestViewingForm 
        closePopupView={() => setShowViewForm(false)}
          propertyId={propertyDetails.pro_id}
          propertyType={propertyDetails.property_type}  
          maxBudget={propertyDetails.price} 
          leadType={propertyDetails.buy_rent_category ==='buy'?'Buyer':'Renter'}
          beds={propertyDetails.bedrooms[0]} 
          citiesOfInterest={propertyDetails.region} 
          datasource={propertyDetails.datasource} 
        />
      ) : (
        ''// If propertyDetails is not ready, show a loading message
      )}
       
      </li>
      <li>
        <a href="javascript:void(0)" className="share-popup-form" onClick={() => setShowSharePopup(true)}>
          <i className="fa fa-plus"></i>
          <p>Share</p>
        </a>
        {showSharePopup && <ShareWithFriendPopup closePopup={() => setShowSharePopup(false)} />}
        <a
          href="javascript:void(0)"
          style={{ cursor: 'pointer' }}
          onClick={() => window.print()}
        >
          <div
            className="save-as-pdf-pdfcrowd-button-wrap pdfcrowd-remove save-as-pdf-pdfcrowd-reset"
            style={{ textAlign: 'center' }}
          >
            <div
             className="save-as-pdf-pdfcrowd-button"
             style={{
               marginTop: '6px',
               marginRight: '6px',
               marginBottom: '6px',
               marginLeft: '6px',
               paddingTop: '6px',
               paddingRight: '6px',
               paddingBottom: '6px',
               paddingLeft: '6px',
               fontSize: '14px',
               fontWeight: 'bold',
               color: '#fff',
               backgroundColor: '#007bff',
               borderColor: '#007bff',
               borderStyle: 'solid',
               borderWidth: '1px',
               borderRadius: '3px',
             }}
            >
             
            </div>
          </div>
          <i className="fa fa-print"></i>
          <p>Print</p>
        </a>
      </li>
    </ul>
  </div>
</div>
 <ToastContainer position="top-right" autoClose={3000} />
        {/* Rest of the property details... */}
        <div className="single-properties-sec">
          <div className="container">
          <ul>
                <li><h6>{propertyDetails.property_type}</h6><p>Property Type</p></li>
                {propertyDetails.property_type !== 'Land' ? (
                  <>
                    <li><h6>{propertyDetails.bedrooms || 'N/A'}</h6><p>Bed</p></li>
                    <li><h6>{propertyDetails.property_area} {/* m<sup>2</sup> */}</h6><p>Property Area</p></li>
                    <li><h6>{propertyDetails.energy_efficiency || 'N/A'}</h6><p>Energy Efficiency</p></li>
                    <li><h6>€{propertyDetails.price ? new Intl.NumberFormat().format(Math.round(propertyDetails.price)) : 'N/A'}</h6><p>Price</p></li>
                    <li><h6>{propertyDetails.air_conditioning || 'N/A'}</h6><p>Air Conditioning</p></li>
                    <li><h6>€{propertyDetails.cost_per_square_meter ? new Intl.NumberFormat().format(Math.round(propertyDetails.cost_per_square_meter)) : 'N/A'} /m<sup>2</sup></h6><p>Cost Per Square Meter</p></li>
                    <li><h6>{propertyDetails.postal_code || 'N/A'}</h6><p>Postal Code</p></li>
                    <li><h6>{propertyDetails.region || 'Unknown'}</h6><p>District</p></li>
                    <li><h6>{propertyDetails.location || 'Unknown'}</h6><p>Location</p></li>
                    <li><h6>{propertyDetails.include || 'N/A'}</h6><p>Included</p></li>
                    <li><h6>{propertyDetails.furnishing || 'N/A'}</h6><p>Furnishing</p></li>
                    <li><h6>{propertyDetails.floor || 'N/A'}</h6><p>Floor</p></li>
                    <li><h6>{propertyDetails.parking || 'N/A'}</h6><p>Parking</p></li>
                    <li><h6>{propertyDetails.reference_number || 'N/A'}</h6><p>Reference Number</p></li>
                    </>
                ) : (
                  <>
                    <li><h6>€{propertyDetails.price ? new Intl.NumberFormat().format(Math.round(propertyDetails.price)) : 'N/A'}</h6><p>Price</p></li>
                    <li><h6>{propertyDetails.region}</h6><p>District</p></li>
                    <li><h6>{propertyDetails.location}</h6><p>Location</p></li>
                    <li><h6>{propertyDetails.online_viewing}</h6><p>Online Viewing</p></li>
                    <li><h6>{propertyDetails.reference_number}</h6><p>Reference Number</p></li>
                </>
                )}
              </ul>

              <ul className="yield-annual">
                  {propertyDetails.buy_rent_category === 'buy' && propertyDetails.property_type !== 'Land' && (
                    <>
                      {/* Check if rental_yield is valid and not 0 or '0' propertyDetails.property_added_status !== "1" &&   */}
                      {propertyDetails.rental_yield !== '0' && propertyDetails.rental_yield !== 0 && propertyDetails.rental_yield !== '' && (
                        <li>
                          <h6>AVG Yield %</h6>
                          <p>{Math.round(propertyDetails.rental_yield)} %</p>
                        </li>
                      )}

                      {/* Check if annual_income is valid and not 0, '0' or empty string */}
                      {propertyDetails.annual_income !== '0' && propertyDetails.annual_income !== 0 && propertyDetails.annual_income !== '' && (
                        <li>
                          <h6>AVG Annual Rental Income</h6>
                          <p>€ {new Intl.NumberFormat().format(Math.round(propertyDetails.annual_income))}</p>
                        </li>
                      )}
                    </>
                  )}
                </ul>
          </div>
        </div>
        {(propertyDetails.property_added_status === "1" || propertyDetails.property_added_status === 1) && 
            (propertyDetails.property_video_upload_By === 'by_youtube' || propertyDetails.property_video_upload_By === 'by_computer') && (
              <div className="about-video-banner detail-video">
                <div className="main-banner">
                  {propertyDetails.property_video_upload_By === 'by_youtube' ? (
                    <div className="by-youtube-video">
                      <p dangerouslySetInnerHTML={{ __html: propertyDetails.youtube_url }} />
                    </div>
                  ) : propertyDetails.property_video_upload_By === 'by_computer' ? (
                    <div className="bg-video-container by-computer-video">
                      <video autoplay loop muted controls>
                        <source src={`https://${domainName}:5000/uploads/${propertyDetails.property_video}`} />
                      </video>
                    </div>
                  ) : null}
                </div>
              </div>
            )}

        <div className="image-gallery-sec">
          <div className="container">
          <ul>
              {propertyImages.length > 0 ? (
                propertyImages.map((image, index) => (
                  <li key={index}>
                    <img
                      className="property-image"
                      src={ propertyDetails.property_added_status === "1" || propertyDetails.property_added_status === 1
                        ? `https://${domainName}:5000/uploads/${image}`
                        : image}
                      alt={`Photo of ${propertyDetails.location}, Image ${index + 1}`}
                      style={{ maxWidth: '100%', marginBottom: '10px' }}
                    />
                  </li>
                ))
              ) : (
                <p>No images available for this property.</p>
              )}
          </ul>

          {/* <div className="property-tuor-video">
              <h2>Tour video</h2>
              <p dangerouslySetInnerHTML={{ __html: propertyDetails.property_tour_video }} />
            </div> */}

            <div className="property-content property-content-full">
              <h2>Description</h2>
              <p dangerouslySetInnerHTML={{ __html: propertyDetails.p_description || 'N/A' }} />
            </div>
          </div>
        </div>
        {(propertyDetails.property_added_status === "1" || propertyDetails.property_added_status === 1) && 
          propertyDetails.property_tour_video && (
            <div className="video-3d-sec">
              <span dangerouslySetInnerHTML={{ __html: propertyDetails.property_tour_video || 'N/A' }} />
            </div>
          )}


        {location && <MapComponent location={location} />}
        <Helmet>
        
        <script src="https://fast.wistia.com/embed/medias/3ebvt41ub3.jsonp" async></script>
        <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
          <script
            src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.1/jquery.min.js"
            integrity="sha512-v2CJ7UaYy4JwqLDIrZUI/4hqeoQieOmAZNXBeQyjo21dadnwR+8ZaIJVT8EE2iyI61OV8e6M8PP2/4hpQINQ/g=="
            crossorigin="anonymous"
            referrerpolicy="no-referrer"
          ></script>
        
          
        </Helmet>
        <Footer />
      </div>
    );
};

export default PropertyDetail;
