import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const TrailingSlashRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const path = window.location.pathname;
    if (path.endsWith('/')) {
      navigate(path.slice(0, -1));
    }
  }, [navigate]);

  return null;  // This component doesn't render anything
};

export default TrailingSlashRedirect;
