import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import  Header from './Header';
import Loader from './Loader';
import { toggleMenu } from './style';
import Footer from './Footer';
import { Helmet } from 'react-helmet';

const PropertyPage = () => {
  const [properties, setProperties] = useState([]); // Holds all fetched properties
  const [page, setPage] = useState(1); // Current page number
  const [loading, setLoading] = useState(false); // Loading state
  const [totalProperties, totalPropertyCount] = useState(0); // Total properties available
  const [propertyCount, setPropertyCount] = useState(0); // Total properties available
  const [hasMore, setHasMore] = useState(true); // To check if there are more properties

  
  const [filterData, setApiFilterData] = useState([]);  
  const [apiData, setApiData] = useState([]);  
  const [sortBy, setSortBy] = useState('Recent'); // Set default value  
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]); 
  const [selectedParentTypes, setSelectedParentTypes] = useState([]); 
  const [selectedTypes, setSelectedTypes] = useState([]); // Property types selected for filtering
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [minBeds, setMinBeds] = useState('');
  const [maxBeds, setMaxBeds] = useState('');
  const [minSqft, setMinSqft] = useState('');
  const [maxSqft, setMaxSqft] = useState('');
  const [minYield, setMinYield] = useState('');
  const [maxYield, setMaxYield] = useState('');

  const  category  = 'buy';
  const domainName = window.location.hostname;
  //const protocol = window.location.protocol;

  const [isRegionVisible, setIsRegionVisible] = useState(false);
  useEffect(() => {
    if (selectedRegions.length > 0) {
      setIsRegionVisible(true); // Show the yield area
    } else {
      setIsRegionVisible(false); // Hide the yield area
    }
  }, [selectedRegions]);

  // Fetch properties based on the page number
  const fetchProperties = async () => {
    setLoading(true);
    console.log(`Fetching properties for page: ${page}`);

    try {
      const response = await axios.get(`https://${domainName}:5000/api/property/${category}?page=${page}`);
     
      const data = response.data;

       console.log('address:', `https://${domainName}:5000/api/property/${category}?page=${page}`); // Log the response to see what you're getting

     // Append new properties to the list
     setProperties((prev) => [...prev, ...data]);
     //setTotalProperties(data.totalProperties);

     // Check if more properties are available
     setHasMore(data.length > 0);
    } catch (error) {
      console.error("Error fetching properties:", error);
      console.log("address is "+`https://${domainName}:5000/api/property/${category}?page=${page}`);
      setHasMore(false); // If an error occurs, stop fetching
    }
    setLoading(false);
  };

  // Fetch the total property count
  const fetchPropertyCount = async () => {
    try {
      const response = await fetch(`https://${domainName}:5000/api/property-count-by-category/${category}`);
      if (!response.ok) throw new Error('Failed to fetch property count');
      const data = await response.json();
      totalPropertyCount(data.count);  // Set the count value
    } catch (error) {
      console.error('Error fetching property count:', error);
    }
    setLoading(false);
  };

  // Fetch properties and property count initially
  useEffect(() => {
    fetchProperties();  // Initial fetch
    fetchPropertyCount();  // Get total property count
     toggleMenu();
    
        // Ensure DOM is loaded and the element exists
        const toggleButton = document.querySelector('.mobile-toggle-btn');
        console.log('Toggle button:', toggleButton);
      
        if (toggleButton) {
          const handleToggleClick = () => {
            document.body.classList.toggle('menu-show');
            console.warn('Done');
          };
      
          toggleButton.addEventListener('click', handleToggleClick);
      
          // Cleanup function to remove the event listener
          return () => {
            toggleButton.removeEventListener('click', handleToggleClick);
          };
        } else {
          console.log('Toggle button not found in DOM');
        }
  }, [page]); // Only run once when the component mounts

  // Infinite scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      console.log('Scroll position:', window.innerHeight + window.scrollY, 'document height:', document.body.offsetHeight);

      // Only increment page when we can load more data
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight-500 && hasMore && !loading  ) {
        console.log('Triggering page increment');
        
        // Use a functional update to ensure we get the correct previous page
        setPage((prevPage) => {
          console.log('Prev Page:', prevPage); // Log previous page to ensure it's incrementing
          return prevPage + 1;  // Increment the page number
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasMore, loading]);  // Re-run only if 'hasMore' or 'loading' changes

  const fetchData = () => {
    // Fetch data from the filterbuy API
    setLoading(true); 
    fetch(`https://${domainName}:5000/api/filterbuy`)
      .then(response => response.json())  
      .then(data => {
        console.log('Fetched buy data:', data.length);
        setApiData(data);  
        setLoading(false); 
      })
      .catch(error => {
        console.error('Error fetching data from filterbuy:', error);
      });

    // Fetch data from the filter API
   /*  fetch(`https://${domainName}:5000/api/filter`)
      .then(response => response.json()) // Parse the JSON response
      .then(data => {
        console.log('Fetched filter data:', data);
        setApiFilterData(data); // Set the fetched filter data to state
      })
      .catch(error => {
        console.error('Error fetching data from filter:', error);
      }); */
  };


  // Function to handle checkbox change
  const handleChange = (event) => {
    setSortBy(event.target.value); // Update the sort option
  };

  const handleRegionChange = (event) => {
    const { value, checked } = event.target;
    setSelectedRegions((prevSelectedRegions) => {
      if (checked) {
        return [...prevSelectedRegions, value];
      } else {
        return prevSelectedRegions.filter(region => region !== value);
      }
    });
    fetchData();
  };
  const handleLocationChange = (event) => {
    const { value, checked } = event.target;
    setSelectedLocation((prevSelectedLocation) => {
      if (checked) {
        return [...prevSelectedLocation, value];
      } else {
        return prevSelectedLocation.filter(Location => Location !== value);
      }
    });
    fetchData();
  };

  const handleParentTypeChange = (event) => {
    const { value, checked } = event.target;
    setSelectedParentTypes((prevSelectedParentType) => {
      if (checked) {
        return [...prevSelectedParentType, value];
      } else {
        return prevSelectedParentType.filter(parent_type => parent_type !== value);
      }
    });
    fetchData();
  };

  // Function to handle checkbox change for property type selection
  const handleTypeChange = (event) => {
    const { value, checked } = event.target;
    setSelectedTypes(prevSelectedTypes => {
      if (checked) {
        return [...prevSelectedTypes, value];
      } else {
        return prevSelectedTypes.filter(type => type !== value);
      }
    });
    fetchData();
  };

  
  const handleMinPriceChange = (e) => {setMinPrice(e.target.value.replace(/[^0-9]/g, ''));fetchData();}
  const handleMaxPriceChange = (e) => {setMaxPrice(e.target.value.replace(/[^0-9]/g, ''));fetchData();}

  const handleConditionChange = (event) => {
    const { value, checked } = event.target;
    setSelectedConditions((prevConditions) => {
      if (checked) {
        return [...prevConditions, value]; // Add the value if checked
      } else {
        return prevConditions.filter((condition) => condition !== value); // Remove the value if unchecked
      }
    });
    fetchData();
  };

  const handleMinBedsChange = (e) => {setMinBeds(e.target.value); fetchData();}
  const handleMaxBedsChange = (e) => {setMaxBeds(e.target.value);fetchData();}

  const handleMinSqftChange = (e) => {setMinSqft(e.target.value);fetchData();}
  const handleMaxSqftChange = (e) => {setMaxSqft(e.target.value);fetchData();}

  // Memoized unique property types and conditions
  /* const uniquePropertyTypes = useMemo(() => [...new Set(filterData.map((property) => property.property_type))], [filterData]);
  const uniquePropertycondition = useMemo(() => [...new Set(filterData.map((property) => property.condition_type))], [filterData]); */

  // Memoized grouped locations by region
  const groupedLocations = useMemo(() => {
    return filterData.reduce((acc, property) => {
      const { region, location } = property;
      if (!region || !location) return acc; // Ensure Region and location exist
      if (!acc[region]) {
        acc[region] = [];
      }
      if (!acc[region].includes(location)) {
        acc[region].push(location); // Add unique locations for each region
      }
      return acc;
    }, {});
  }, [filterData]);

  // Memoized unique regions from groupedLocations
  const uniquePropertyRegion = useMemo(() => Object.keys(groupedLocations), [groupedLocations]);
  
  const groupedParentType = useMemo(() => {
    return filterData.reduce((acc, property) => {
      const { parent_type, property_type } = property;
      if (!parent_type || !property_type) return acc; // Ensure parent_type and property_type exist
      if (!acc[parent_type]) {
        acc[parent_type] = [];
      }
      if (!acc[parent_type].includes(property_type)) {
        acc[parent_type].push(property_type); // Add unique locations for each region
      }
      return acc;
    }, {});
  }, [filterData]);

  const uniqueParentType = useMemo(() => Object.keys(groupedParentType), [groupedParentType]);

   

  const filterProperties = () => {
    if (
      selectedRegions.length === 0 &&
      selectedLocation.length === 0 &&
      selectedTypes.length === 0 &&
      selectedParentTypes.length === 0 &&
      minPrice === '' &&
      maxPrice === '' &&
      selectedConditions.length === 0 &&
      minBeds === '' &&
      maxBeds === '' &&
      minSqft === '' &&
      maxSqft === '' &&
      minYield === '' &&
      maxYield === ''
    ) {
      return properties; // Show all properties if no filters are applied
    } 
    
    return apiData.filter(property => {      
      const matchesRegion = selectedRegions.length === 0 || selectedRegions.includes(property.region);
      const matchesLocation = selectedLocation.length === 0 || selectedLocation.includes(property.location);
      const matchesParentType = selectedParentTypes.length === 0 || selectedParentTypes.includes(property.parent_type);
      const matchesType = selectedTypes.length === 0 || selectedTypes.includes(property.property_type);
      
      // Ensure price comparisons are done as numbers, not strings
      const price = parseFloat(property.price.replace(/[^0-9.-]+/g, "")); // Ensure price is a number
      const matchesPrice =
        (minPrice === '' || price >= parseFloat(minPrice)) &&
        (maxPrice === '' || price <= parseFloat(maxPrice));
  
      const matchesCondition = selectedConditions.length === 0 || selectedConditions.includes(property.condition_type);
  
      // Compare bedrooms with numbers
      const bedrooms = parseFloat(property.bedrooms);
      const matchesBedrooms =
        (minBeds === '' || bedrooms >= parseFloat(minBeds)) &&
        (maxBeds === '' || bedrooms <= parseFloat(maxBeds));
  
      // Compare square footage and rental yield
      const costsqmeter = parseFloat(property.cost_per_square_meter);
      const matchesSqft =
        (minSqft === '' || costsqmeter >= parseFloat(minSqft)) &&
        (maxSqft === '' || costsqmeter <= parseFloat(maxSqft));
  
      const rentalYield = parseFloat(property.rental_yield);
      const matchesYield =
        (minYield === '' || rentalYield >= parseFloat(minYield)) &&
        (maxYield === '' || rentalYield <= parseFloat(maxYield));
  
      // Ensure all conditions match
      return matchesRegion && matchesLocation && matchesParentType && matchesType && matchesPrice && matchesCondition && matchesBedrooms && matchesSqft && matchesYield;
    });
  };
  

   
  // Get filtered properties after region selection
  // const allproperties = filterProperties();

   // Sorting function based on selected sort option
   const sortProperties = (filteredProperties) => {
    if (sortBy === 'Recent') {
      return filteredProperties.sort((a, b) => new Date(b.date) - new Date(a.date));
    }
    if (sortBy === 'lowtohigh') {
      return filteredProperties.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
    }
    if (sortBy === 'hightolow') {
      return filteredProperties.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
    }
    return filteredProperties;
  };

   // Apply filters and sorting
   const filteredProperties = filterProperties();
   const sortedProperties = sortProperties(filteredProperties);


  useEffect(() => {
    const totalProperty = totalProperties; // or fetch from your data source
    setPropertyCount(totalProperty);
    if (selectedRegions.length === 0 && selectedLocation.length === 0 && selectedParentTypes.length === 0 && selectedTypes.length === 0 && minPrice === '' && maxPrice === '' && selectedConditions.length === 0 && minBeds === '' && maxBeds === '' && minSqft === '' && maxSqft === '' && minYield === '' && maxYield === '') {
      // If no filters are selected, show all properties
      const totalProperty = totalProperties; // or fetch from your data source
      setPropertyCount(totalProperty);
    } else {
      // Otherwise, show the count of filtered properties
      const totalProperties = filteredProperties.length; 
      setPropertyCount(totalProperties);
    }
  }, [selectedRegions,selectedLocation,selectedParentTypes,selectedTypes,minPrice,maxPrice,selectedConditions,minBeds,maxBeds,minSqft,maxSqft,minYield,maxYield,filteredProperties,totalProperties,]); 

  // Limit the displayed properties to 20 per page
  const allproperties = sortedProperties.slice(0, page * 20); // 20 properties per page


  console.log('api not',properties);
  console.log('with api',apiData);
  console.log('property data filter array',allproperties);

  const [showNoPropertiesMessage, setShowNoPropertiesMessage] = useState(false);

  useEffect(() => {
    if (allproperties.length === 0) {
      const timer = setTimeout(() => {
        setShowNoPropertiesMessage(true); // Show the message after 1 seconds
      }, 5000);

      // Cleanup the timer when the component unmounts or if the effect is re-run
      return () => clearTimeout(timer);
    } else {
      setShowNoPropertiesMessage(false); // Hide the message if properties are found
    }
  }, [allproperties]);

  
  return (
    <div className="">
      <Helmet>
        <title> Properties of Sale in Cyprus</title>
        <meta name="title" content="Properties for Sale in Cyprus | Buy Apartments, Villas & More"></meta>
        <meta name="description" content="Discover properties for sale in Cyprus. Explore apartments, villas, and commercial properties with PropertyOne."></meta>
      </Helmet>
     <Header activeMarket="buy" />
    <div className="banner-header">
    <h1>Properties For {category === 'buy' ? 'Sale' : category} in Cyprus</h1>

      </div>
    <div className="main-sec">
      <div className="container">
        
        {/* Property List Section */}
        <div className="header-section" id="header-section">
          <div className='total-results'>
            <p>Showing: {allproperties.length} out of {new Intl.NumberFormat('en-US').format(Math.round(propertyCount))}</p>
          </div>
          <div className="sort-by">
            <label>SortBy</label>
            <select name="sortby" value={sortBy} onChange={handleChange}>
              <option value="Recent">Most Recent</option>
              <option value="lowtohigh">Lowest Price</option>
              <option value="hightolow">Highest Price</option>
            </select>
          </div>
        </div>

        
        <div className="filter-section">
          <div className="filter-form">                   
            <div className="location-column">
              <div className="visible-location selected-visible">
              <i class="fa fa-map-pin"></i>
                <input type="text" id="region_location" placeholder="Locations" value={selectedRegions.length > 0 && selectedLocation.length > 0 ? `${selectedRegions.join(', ')} | ${selectedLocation.join(', ')}` : selectedRegions.length > 0 ? selectedRegions.join(', ') : selectedLocation.join(', ') || ''} disabled/>
              </div>
              {/* ----------- */}
              <div className="location-toggle select-details">
                  <ul className="location-list">
                    {/* Limassol Region */}
                    <li className="Limassol-reg1">
                      <input
                        type="checkbox"
                        className="regionval"
                        value="Limassol"
                        id="region-Limassol"
                        onChange={handleRegionChange}
                        checked={selectedRegions.includes('Limassol')}
                      />
                      <span>Limassol</span>

                      {/* Nested locations for Limassol */}
                      {selectedRegions.includes('Limassol') && (
                        <ul className="Limassol-locations">
                          {[
                            "Germasogeia", "Germasogeia Tourist", "Erimi", "Moni", "Agios Tychon", "Parekklisia", "Polemidia Kato", 
                            "Agios Athanasios", "Limassol", "Monagroulli", "Pyrgos Lemesou Tourist", "Trachoni Lemesou", "Pissouri", 
                            "Palodeia", "Dora", "Monagri", "Mouttagiaka", "Agios Amvrosios Lemesou", "Ypsonas", "Kellaki", 
                            "Mesa Geitonia", "Souni", "Kalo Chorio Lemesou", "Akrounta", "Platres Pano", "Doros", "Fasoula Lemesou", 
                            "Agios Tychon Tourist", "Pyrgos Lemesou", "Parekklisia Tourist", "Potamos Germasogeias", "Panthea", 
                            "Asomatos Lemesou", "Ekali", "Tserkez Tsiftlik (Tserkezoi)", "Agios Stylianos", "Paramali", "Akrotiri", 
                            "Prodromos", "Limassol Marina", "Foinikaria", "Pera Pedi", "Pentakomo", "Episkopi Lemesou", "Zanakia", 
                            "Agios Georgios", "Armenochori", "Vasa Koilaniou", "Arsos Lemesou", "Prastio Avdimou", "Anogyra", "Paramytha", 
                            "Mouttagiaka Tourist", "Laneia", "Kolossi", "Polemidia", "Pelendri", "Agios Therapon", "Sotira Lemesou", 
                            "Vouni", "Polemidia Pano", "Louvaras", "Kouka", "Agios Ioannis", "Historical Center", "Trimiklini", "Alassa", 
                            "Eptagoneia", "Anthoupoli", "Agia Paraskevi", "Moniatis", "Asgata", "Moni Agiou Georgiou Alamanou", "Omodos", 
                            "Archangelos Michail", "Agios Spyridon", "Omonia", "Agia Fyla", "Petrou Kai Pavlou", "Apostolos Varnavas", 
                            "Apostolos Andreas", "Agios Theodoros Lemesou", "Platres Kato", "Agios Pavlos", "Pachna", "Amiantos Kato", 
                            "Amiantos Pano", "Dierona", "Agros", "Kivides Pano", "Linopetra", "Zakaki", "Lofou", "Silikou", "Spitali", 
                            "Kontovathkeia", "Foini", "Apesia", "Mandria Lemesou", "Mylos Kato", "Apsiou", "Prastio Kellakiou", "Fasouri", 
                            "Mathikoloni", "Lemithou", "Gerasa", "Profitis Ilias", "Avdimou", "Vasa Kellakiou", "Timiou Prodromou Mesa Geitonias", 
                            "Chalkoutsa", "Kapsalos", "Agios Georgios(Fragk)", "Troodos", "Makarios Iii", "Potamiou", "Kyperounta", "Potamitissa", 
                            "Agios Konstantinos", "Agios Mamas", "Kapileio", "Arakapas", "Koilani", "Limnatis", "Korfi", "Chandria", 
                            "Kolossi -Agios Loukas", "Sykopetra", "Zoopigi", "Kaminaria"
                          ].map((propertyLocation) => (
                            <li key={propertyLocation}>
                              <input
                                type="checkbox"
                                className="locationval"
                                value={propertyLocation}
                                id={`location-${propertyLocation}`}
                                onChange={handleLocationChange}
                                checked={selectedLocation.includes(propertyLocation)}
                              />
                              <span>{propertyLocation}</span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>

                    {/* Larnaca Region */}
                    <li className="Larnaca-reg1">
                      <input
                        type="checkbox"
                        className="regionval"
                        value="Larnaca"
                        id="region-Larnaca"
                        onChange={handleRegionChange}
                        checked={selectedRegions.includes('Larnaca')}
                      />
                      <span>Larnaca</span>

                      {/* Nested locations for Larnaca */}
                      {selectedRegions.includes('Larnaca') && (
                        <ul className="Larnaca-locations">
                          {[
                            "Pyla", "Kiti", "Livadia Larnakas", "Aradippou", "Dekeleia", "Perivolia Larnakas", "Larnaka", "Pyla Tourist", 
                            "Vavatsinia", "Oroklini Tourist", "Ormideia", "Oroklini", "Maroni", "Mazotos", "Xylofagou", "Lefkara Pano", 
                            "Vavla", "Tersefanou", "Mosfiloti", "Agios Theodoros", "Lefkara Kato", "Kalavasos", "Psevdas", "Zygi", "Meneou", 
                            "Anglisides", "Dromolaxia", "Psematismenos", "Alethriko", "Kalo Chorio", "Sotiros", "Kokkines", "Agios Fanourios", 
                            "Anafotida", "Xylotymvou", "Pyrga Larnakas", "Softades", "Kornos", "Skala", "Tochni", "Chrysopolitissa", 
                            "Agios Nikolaos", "Apostolos Loukas", "Kamares", "Ora", "Agioi Anargyroi I", "Melini", "Drys Kato", "Troulloi", 
                            "Drosia", "Athienou", "Alaminos", "Kofinou", "Skarinou", "Agioi Anargyroi II", "Lageia", "Choirokoitia", "Mari", 
                            "Avdellero", "Agia Anna", "Agioi Vavatsinias", "Odou"
                          ].map((propertyLocation) => (
                            <li key={propertyLocation}>
                              <input
                                type="checkbox"
                                className="locationval"
                                value={propertyLocation}
                                id={`location-${propertyLocation}`}
                                onChange={handleLocationChange}
                                checked={selectedLocation.includes(propertyLocation)}
                              />
                              <span>{propertyLocation}</span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>

                    {/* Paphos Region */}
                    <li className="Paphos-reg1">
                      <input
                        type="checkbox"
                        className="regionval"
                        value="Paphos"
                        id="region-Paphos"
                        onChange={handleRegionChange}
                        checked={selectedRegions.includes('Paphos')}
                      />
                      <span>Paphos</span>

                      {/* Nested locations for Paphos */}
                      {selectedRegions.includes('Paphos') && (
                        <ul className="Paphos-locations">
                          {[
                            "Paphos", "Kissonerga", "Pegeia", "Koloni", "Tsada", "Tala", "Pomos", "Armou", "Chlorakas", "Mesa Chorio", 
                            "Konia", "Geroskipou", "Latsi", "Empa", "Anarita", "Agia Marinouda", "Argaka", "Neo Chorio Pafou", "Nea Dimmata", 
                            "Lempa", "Polis Chrysochous", "Kritou Tera", "Tombs Of The Kings", "Mesogi", "Nikokleia", "Kouklia Pafou", 
                            "Prodromi", "Lysos", "Acheleia", "Episkopi Pafou", "Amargeti", "Aphrodite Hills Kouklia", "Mandria Pafou", 
                            "Kathikas", "Timi", "Ineia", "Statos", "Pentalia", "Koilineia", "Koili", "Agia Marina Kelokedaron", "Tremithousa", 
                            "Chrysochou", "Universal", "Agios Georgios", "Venus Rock Kouklia", "Geroskipou Tourist", "Choulou", "Agios Nikolaos", 
                            "Agios Theodoros", "Moutallos", "Thrinia", "Marathounta", "Steni", "Agia Marina Chrysochous", "Giolou", "Simou", 
                            "Akourdaleia Pano", "Polemi", "Arodes Pano", "Peristerona Pafou", "Lasa", "Kato Paphos", "Drouseia", "Goudi", 
                            "Drymou", "Kallepeia", "Panagia Pano", "Agios Ioannis", "Agia Varvara", "Skoulli", "Salamiou", "Stroumpi Kato", 
                            "Nata", "Kelokedara", "Akourdaleia Kato", "Mousere", "Psathi", "Arodes Kato", "Galataria", "Trachypedoula", 
                            "Fyti", "Lemona", "Mamonia", "Eledio", "Mesana", "Arminou", "Gialia Kato", "Panagia Kato", "Theletra", "Choletria", 
                            "Kannaviou", "Praitori", "Akoursos", "Choli", "Milia Pafou", "Kynousa", "Kedares", "Letymvou", "Stroumpi Pano"
                          ].map((propertyLocation) => (
                            <li key={propertyLocation}>
                              <input
                                type="checkbox"
                                className="locationval"
                                value={propertyLocation}
                                id={`location-${propertyLocation}`}
                                onChange={handleLocationChange}
                                checked={selectedLocation.includes(propertyLocation)}
                              />
                              <span>{propertyLocation}</span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>

                    {/* Nicosia Region */}
                    <li className="Nicosia-reg1">
                      <input
                        type="checkbox"
                        className="regionval"
                        value="Nicosia"
                        id="region-Nicosia"
                        onChange={handleRegionChange}
                        checked={selectedRegions.includes('Nicosia')}
                      />
                      <span>Nicosia</span>

                      {/* Nested locations for Nicosia */}
                      {selectedRegions.includes('Nicosia') && (
                        <ul className="Nicosia-locations">
                          {[
                            "Tseri", "Strovolos", "Aglantzia", "Lakatameia", "Agia Varvara Lefkosias", "Egkomi", "Alampra", "Agios Dometios", 
                            "Kalo Chorio", "Nicosia", "Politiko", "Dali", "Latsia", "Agios Epifanios Oreinis", "Palouriotissa", "Ergates", 
                            "Lythrodontas", "Kokkinotrimithia", "Episkopeio", "Anageia", "Nisou", "Klirou", "Geri", "Potamia", "Agioi Omologites", 
                            "Palaiometocho", "Pera Chorio", "Moni Kato", "Deftera Pano", "Kampia", "Kalithea", "Psimolofou", "Gsp", "Akaki", 
                            "Pedoulas", "Lympia", "Agrokipia", "Carolina Park", "Agios Vasileios", "Parissinos", "Agia Paraskevi", "Stelmek", 
                            "Agios Georgios", "Galata", "Pyrgos Kato", "Agios Mamas", "Deftera Kato", "Kalopanagiotis", "Panag Evangelistria", 
                            "Chryseleousa", "Peristerona Lefkosias", "Agios Nikolaos", "Kakopetria", "Agioi Trimithias", "Anthoupolis", "Spilia", 
                            "Askas", "Agia Marina Xyliatou", "Pera", "Korakou", "Mammari", "Gourri", "Sia", "Deneia", "Kapedes", "Linou", 
                            "Synoikismos Anthoupolis", "Margi", "Mitsero", "Potami", "Astromeritis", "Ilioupoli", "Meniko", "Palaichori Oreinis", 
                            "Apliki", "Kaliana", "Panagia", "Mosfili", "Pyrgos Pano", "Evrychou", "Mathiatis", "Arediou", "Pachyammos", 
                            "Palaichori Morfou", "Analiontas", "Agios Theodoros Soleas", "Orounta", "Agios Ioannis Malountas", "Vyzakia", 
                            "Flasou Kato", "Pigenia", "Farmakas", "Lagoudera", "Nikitari", "Polystypos", "Xyliatos", "Nea Ledras", "Temvria", 
                            "Kampos", "Sinaoros", "Malounta", "Oikos", "Koutrafas Kato", "Katydata", "Alona"
                          ].map((propertyLocation) => (
                            <li key={propertyLocation}>
                              <input
                                type="checkbox"
                                className="locationval"
                                value={propertyLocation}
                                id={`location-${propertyLocation}`}
                                onChange={handleLocationChange}
                                checked={selectedLocation.includes(propertyLocation)}
                              />
                              <span>{propertyLocation}</span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>

                    {/* Famagusta Region */}
                    <li className="Famagusta-reg1">
                      <input
                        type="checkbox"
                        className="regionval"
                        value="Famagusta"
                        id="region-Famagusta"
                        onChange={handleRegionChange}
                        checked={selectedRegions.includes('Famagusta')}
                      />
                      <span>Famagusta</span>

                      {/* Nested locations for Famagusta */}
                      {selectedRegions.includes('Famagusta') && (
                        <ul className="Famagusta-locations">
                          {[
                            "Profitis Elias", "Paralimni", "Agia Napa", "Frenaros", "Sotira", "Kapparis", "Deryneia", "Cape Greko", 
                            "Pernera", "Protaras", "Vrysoulles", "Agia Thekla", "Konnos", "Avgorou", "Liopetri", "Agia Triada", "Dasaki Achnas"
                          ].map((propertyLocation) => (
                            <li key={propertyLocation}>
                              <input
                                type="checkbox"
                                className="locationval"
                                value={propertyLocation}
                                id={`location-${propertyLocation}`}
                                onChange={handleLocationChange}
                                checked={selectedLocation.includes(propertyLocation)}
                              />
                              <span>{propertyLocation}</span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  </ul>
                </div>
             {/* ------------ */}
            </div>
            <div className="type-column">
              <div className="visible-type selected-visible">
                <input type="text" id="type" placeholder="Type" /* value={selectedTypes} */ 
                 value={
                  selectedParentTypes.length === 0 && selectedTypes.length === 0
                    ? "Type"
                    : `${selectedParentTypes.join(', ')}${selectedParentTypes.length && selectedTypes.length ? " | " : ""}${selectedTypes.join(', ')}`
                }
                onChange={handleTypeChange} disabled />
                <i class="fa fa-chevron-down"></i>
              </div>  
              <div className="type-toggle select-details">              
                <ul className="type-list">
                  <div className="parent-type-toggle select-details">
                    <ul className="parent-type-list">
                      {/* Houses ParentType */}
                      <li className="Houses-par1">
                        <input
                          type="checkbox"
                          className="parentval"
                          value="Houses"
                          id="parent-Houses"
                          onChange={handleParentTypeChange}
                          checked={selectedParentTypes.includes('Houses')}
                        />
                        <span>Houses</span>

                        {/* Nested property types for Houses */}
                        {selectedParentTypes.includes('Houses') && (
                          <ul className="Houses-propertyType">
                            {["Villa", "Detached House", "Maisonette", "Semi Detached"].map((propertyParentType) => (
                              <li key={propertyParentType}>
                                <input
                                  type="checkbox"
                                  className="typeval"
                                  value={propertyParentType}
                                  id={`type-${propertyParentType}`}
                                  onChange={handleTypeChange}
                                  checked={selectedTypes.includes(propertyParentType)}
                                />
                                <span>{propertyParentType}</span>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>

                      {/* Apartments ParentType */}
                      <li className="Apartments-par1">
                        <input
                          type="checkbox"
                          className="parentval"
                          value="Apartments"
                          id="parent-Apartments"
                          onChange={handleParentTypeChange}
                          checked={selectedParentTypes.includes('Apartments')}
                        />
                        <span>Apartments</span>

                        {/* Nested property types for Apartments */}
                        {selectedParentTypes.includes('Apartments') && (
                          <ul className="Apartments-propertyType">
                            {["Apartment", "Penthouse"].map((propertyParentType) => (
                              <li key={propertyParentType}>
                                <input
                                  type="checkbox"
                                  className="typeval"
                                  value={propertyParentType}
                                  id={`type-${propertyParentType}`}
                                  onChange={handleTypeChange}
                                  checked={selectedTypes.includes(propertyParentType)}
                                />
                                <span>{propertyParentType}</span>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>

                      {/* Lands ParentType */}
                      <li className="Lands-par1">
                        <input
                          type="checkbox"
                          className="parentval"
                          value="Lands"
                          id="parent-Lands"
                          onChange={handleParentTypeChange}
                          checked={selectedParentTypes.includes('Lands')}
                        />
                        <span>Lands</span>

                        {/* Nested property types for Lands */}
                        {selectedParentTypes.includes('Lands') && (
                          <ul className="Lands-propertyType">
                            {[
                              "Agricultural Land", "Industrial Land", "Residential Land", 
                              "Commercial Land", "Tourist Land"
                            ].map((propertyParentType) => (
                              <li key={propertyParentType}>
                                <input
                                  type="checkbox"
                                  className="typeval"
                                  value={propertyParentType}
                                  id={`type-${propertyParentType}`}
                                  onChange={handleTypeChange}
                                  checked={selectedTypes.includes(propertyParentType)}
                                />
                                <span>{propertyParentType}</span>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    </ul>
                  </div>
                </ul>
              </div>
            </div>
            <div className="price-column">  
              <div className="visible-price selected-visible">
                <input type="text" id="pricerange" placeholder="Price"  value={`€ ${minPrice ? new Intl.NumberFormat('en-US').format(minPrice) : ''} - € ${maxPrice ? new Intl.NumberFormat('en-US').format(maxPrice) : ''}`} disabled />
                <i class="fa fa-chevron-down"></i>
              </div>        
              <div className="price-toggle select-details">
                <span>Min Price (€)</span>
                <input type="text" name="price-min" id="pricemin" placeholder="-" value={minPrice ? new Intl.NumberFormat('en-US').format(minPrice) : ''} onChange={handleMinPriceChange} />
                <span>Max Price (€)</span>
                <input type="text" name="price-max" id="pricemax" placeholder="-" value={maxPrice ? new Intl.NumberFormat('en-US').format(maxPrice):''} onChange={handleMaxPriceChange} />
                
              </div>
            </div>
            <div className="condition-column">
              <div className="visible-condition selected-visible">
                <input type="text" id="condition" placeholder="Condition" value={selectedConditions} disabled />
                <i class="fa fa-chevron-down"></i>
              </div>  
              <div className="condition-toggle select-details">
                <ul className="condition-list">
                   {/* Resale, Brand new, Under construction, N/A condition types */}
                {["Resale", "Brand new", "Under construction", "N/A"].map((propertyCondition) => (
                  <li key={propertyCondition}>
                    <input
                      type="checkbox"
                      className="condval"
                      value={propertyCondition}
                      onChange={handleConditionChange}
                      checked={selectedConditions.includes(propertyCondition)}
                    />
                    <span>{propertyCondition}</span>
                  </li>
                ))}
                </ul>
              </div>
            </div>
            <div className="beds-column"> 
              <div className="visible-beds selected-visible">
                <input type="text" id="bedsrange" placeholder="Beds" value={`${minBeds} - ${maxBeds} Beds`} disabled />
                <i class="fa fa-chevron-down"></i>
              </div>       
              <div className="beds-toggle select-details">
                <span>Min Beds</span>
                <input type="text" name="bed-min" id="bedmin" placeholder="-" value={minBeds} onChange={handleMinBedsChange} />
                <span>Max Beds</span>
                <input type="text" name="bed-max" id="bedmix" placeholder="-" value={maxBeds} onChange={handleMaxBedsChange} />
              </div>
            </div>
            <div className="area-column">
              <div className="visible-area selected-visible">
                  <input type="text" id="sqftrange" placeholder="SQM"  value={`${minSqft} - ${maxSqft} SQM`} disabled/>
                  <i class="fa fa-chevron-down"></i>
              </div>
              <div className="area-toggle select-details">
                <span>Min Sq (m<sup>2</sup>)</span>
                <input type="text" name="sqft-min" id="sqdmin" placeholder="-" value={minSqft} onChange={handleMinSqftChange} />
                <span>Max Sq (m<sup>2</sup>)</span>
                <input type="text" name="sqft-max" id="sqdmix" placeholder="-" value={maxSqft} onChange={handleMaxSqftChange} />
              </div>            
            </div>
            {isRegionVisible && (
            <div className="area-column yield-area">
              <div className="visible-area selected-visible">
                <input type="text" id="yieldrange" placeholder="Yield" value={minYield && maxYield ? `${minYield} - ${maxYield}` : ''}disabled/>
              </div>
              <div className="area-toggle select-details">
                <span>Min Yield</span>
                <input type="text" name="yieldmin" id="yieldmin" placeholder="-" value={minYield} onChange={(e) => setMinYield(e.target.value)}/>
                <span>Max Yield</span>
                <input type="text" name="yieldmax" id="yieldmax" placeholder="-" value={maxYield} onChange={(e) => setMaxYield(e.target.value)} />
              </div>
            </div>
            )}

          </div>
        </div>


        {/* Property cards */}
        <div className="card-container">
        {allproperties.length > 0 ? (
          allproperties.map((property) => (
            <div className="card" key={property.property_id}>  
           <Link 
  to={`/properties/${property.property_id.split('-')[1]}_${property.property_type ==='Land' ? '' : property.bedrooms +'-bedroom-'}${ property.property_type ==='Land' ? '' : property.property_type.toLowerCase() +'-to-'}${property.property_type ==='Land' ? '' : category}`} 
  target="_blank" rel="noopener noreferrer"  className="view-more-btn">
              <div
                className="card-image"
                style={{
                  backgroundImage: `url(${property.features_image || ''})`,
                }}
              >
              </div>
              <div className="card-category">{property.bedrooms ==='0' ? '':property.bedrooms} {property.parent_type !== 'Lands' ? 'Bed' : ''} {property.property_type} For {category=='buy' ? 'Sale':'Sale'}</div>
                <div className="card-price">€ {property.price ? new Intl.NumberFormat('en-US').format(property.price) : 'N/A'} <p>{property.rental_yield !== '0' && property.rental_yield !== '' && property.parent_type !=='Lands' ? `${Math.round(property.rental_yield)}% Yield` : ''} </p> </div>
                <div className="card-yield"></div>

              <div className="card-details">
                <h2>{property.property_title ==='N/A' ? '' : property.property_title}</h2>
                <p className="location-region">
                  <strong>{property.property_type || 'N/A'}</strong> | {property.location || 'N/A'}, {property.region || 'N/A'}
                </p>

                <div className="card-hover-details">
                  <p>€{property.price ? new Intl.NumberFormat('en-US').format(property.price) : 'N/A'}</p>
                  <p>{property.property_type || 'N/A'}</p>
                  <p>{property.location || 'N/A'}</p>
                  <p>{property.region || 'N/A'}</p>
                  <p className="see-more-btn">See More</p>
                  
                </div>
              </div>
              </Link>
            </div>
             ))
          ) : (
            showNoPropertiesMessage && (
              <div className="no-properties-found">
                <h2>No Properties found matching your search criteria.</h2>
              </div>
            )
          )}
        </div>

        {/* Loading state */}
        {loading && <Loader />}

        {/* No more data message */}
        {!hasMore && <div>No more properties to load</div>}
      </div>
      <Footer />
     
    </div>
    </div>
  );
};

export default PropertyPage;
