import React from 'react';
import { Helmet } from 'react-helmet';

const Training = () => {
  return (
    <div className="App">
         <Helmet>
         <title>Training – Property One</title>
         </Helmet>
      <iframe
        src="https://pitch.com/embed-link/cuzy2d"
        allow="fullscreen; clipboard-write"
        allowFullScreen
        width="560"
        height="368"
        style={{ border: '0', width:'100%' ,height: '100vh',
          minHeight: '450px', }}
        title="Embedded Content"
      ></iframe>
    </div>
  );
}

export default Training;
