// style.js
export function toggleMenu() {
  const toggleButton = document.querySelector('.mobile-toggle-btn');
  const closeButton = document.querySelector('.mobile-toggle-close');

  if (toggleButton) {
    toggleButton.addEventListener('click', function () {
      document.body.classList.toggle('menu-show');
     // console.warn('Menu toggled open');
    });
  } else {
    console.warn('Toggle button not found');
  }

  if (closeButton) {
    closeButton.addEventListener('click', function () {
      document.body.classList.remove('menu-show');
     // console.warn('Menu toggled close');
    });
  } else {
   console.warn('Close button not found');
  }
}

// style.js
/* export function toggleshow() {
  const toggleButton = document.querySelector('.toggle-show');
  const targetElement = document.querySelector('.elementskit-submenu-panel'); // The element you want to toggle

  if (toggleButton && targetElement) {
     toggleButton.addEventListener('click', function () {
        // Toggle display block for the target element
        targetElement.style.display = (targetElement.style.display === 'block') ? 'none' : 'block';
        console.warn('Menu toggled clicked market');
     });
  }
}
 */


// jQuery(document).ready(function () {
// jQuery(".toggle-show").click(function () {
//     jQuery(this).toggleClass("active-btn");
//    jQuery(".right-col-content").toggleClass("show-content");
// });