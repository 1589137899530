// App.js
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
//import './App.css';
import './pages/style.css';
import './pages/style.js';
import './pages/script.js';
import './pages/market.css';
import './pages/fonts/stylesheet.css';
import { Helmet } from 'react-helmet';

import PropertyList from './pages/PropertyList';  // Corrected import
import Property_buy_rent from './pages/property_rent';
import Property_buy from './pages/property_buy';
import Property_detail from './pages/property_detail';
import Property_search from './pages/property_search';
import Property_source from './pages/property_source';
import Markets from './pages/market-limassol';
import MarketNicosia from './pages/market-nicosia';
import MarketPaphos from './pages/market-paphos';
import MarketLarnaca from './pages/market-larnaca';   
import MarketFamagusta from './pages/market-famagusta'; 
import GetMarketData from './pages/market-test'; 
import Login from './pages/userLogin';
import MyLogin from './pages/mylogintest.js';
import AboutUs from './pages/about-us';
import HomePage from './pages/home';
import MyListing from './pages/mylisting'; 
import AlertListing from './pages/alert_listing'; 
import Services from './pages/services'; 
import Contact from './pages/contact';
import Lido from './pages/lido';
import Thankyou from './pages/thank-you';
import UpdatePassword  from './pages/updatePassword.js';
import TrailingSlashRedirect from './TrailingSlashRedirect';
import ExclusiveListing from './pages/exclusiveProperty';
import Training from './pages/training.js';
 
import PropertySell from './pages/propertysell.js'
import Lease  from './pages/property_lease.js'

function App() {
  return (
    <BrowserRouter basename="/"> 
      <div className="App">
      <TrailingSlashRedirect />
      <Helmet>
          <title>PropertyOne </title> {/* Default title */}
          <meta name="description" content="PropertyOne - Your Real Estate Solution" />
        </Helmet>
        <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/update-password" element={<UpdatePassword />} />
        {/*   <Route exact path="/" element={<PropertyList />} /> */}
          <Route path="/rent" element={<Property_buy_rent />} />
          <Route path="/buy" element={<Property_buy />} />
          <Route path="/:category/:id" element={<Property_detail />} />
          <Route path="/search" element={< Property_search/>} />
          <Route path="/source" element={<Property_source />} />
          <Route path="/market-limassol" element={<Markets />} />
          <Route path="/market-nicosia" element={<MarketNicosia />} />
          <Route path="/market-paphos" element={<MarketPaphos />} />
          <Route path="/market-larnaca" element={<MarketLarnaca />} />
          <Route path="/market-famagusta" element={<MarketFamagusta />} />
          <Route path="/market_data_test" element={<GetMarketData />} />
          <Route path="/my-login" element={<MyLogin />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/mylisting" element={<MyListing />} />
          <Route path="/alert-listing" element={<AlertListing />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/lidoexclusive" element={<Lido />} />
          <Route path="/thank-you" element={<Thankyou />} />
          <Route path="/featured" element={<ExclusiveListing />} />
          <Route path="/training" element={<Training />} />
          <Route path="/sell" element={<PropertySell />} />
          <Route path="/lease" element={<Lease />} />
        
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
