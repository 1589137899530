import React, { useState } from 'react';
import axios from 'axios';

function LoginForm() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const BASE_URL = 'http://51.44.183.118';  // Use environment variable or fallback
    const API_URL = `${BASE_URL}/wp-json/custom-auth/v1/login-api`;

    const handleLogin = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post(API_URL, {
                username,
                password,
            });

            if (response.data.cookie) {
                localStorage.setItem('authCookie', response.data.cookie);
                console.log('Login successful, cookie saved');
                alert('Login successful!');
            } else {
                throw new Error('Failed to fetch WordPress cookie');
            }
        } catch (err) {
            setError(err.response?.data?.message || 'Invalid username or password');
        }
    };
   
    return (
        <div>
            <form onSubmit={handleLogin}>
                <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Username"
                    required
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    required
                />
                <button type="submit">Login</button>
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </form>

        </div>
    );
}

export default LoginForm;
