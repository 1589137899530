import React, { useState, useEffect } from 'react';
import { FaTimes } from "react-icons/fa";

const RequestViewingForm = ({
  closePopupView ,  
  propertyId, 
    propertyType, 
    maxBudget, 
    leadType, 
    beds,
    description, 
    citiesOfInterest,
    datasource,
     urlParams = new URLSearchParams(window.location.search)
  }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    propertyType: [propertyType],
    maxBudget: maxBudget ,
    leadType:[leadType],
    beds: [beds],
    citiesOfInterest:[citiesOfInterest]  ,
    country: '',
    city: '',
    description: `Property URL:${window.location.href.split('?')[0]}
 
Data Source: ${datasource}
 
UTM Details:
Campaign ID: ${urlParams.get('utm_id')}
Campaign Source: ${urlParams.get('utm_source')}
Campaign Medium: ${urlParams.get('utm_medium')}
Campaign Name: ${urlParams.get('utm_campaign')}
Campaign Term: ${urlParams.get('utm_term')}
Campaign Content: ${urlParams.get('utm_content')}
`,
    leadSource: 'XML Portals',
  });

  const domainName = window.location.hostname;

  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        // Fetching from the ip-api service
        const response = await fetch('http://ip-api.com/json');
        const data = await response.json();

        if (data && data.country && data.city) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            country: data.country, // Update the country
            city: data.city,       // Update the city
          }));
        }
      } catch (error) {
        console.error('Error fetching location data:', error);
      }
    };

    fetchLocationData();
   
  }, []); // Only run once on component mount


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSelectChange = (e) => {
    const { name, options } = e.target;
    const values = Array.from(options).filter(option => option.selected).map(option => option.value);
    setFormData({
      ...formData,
      [name]: values
    });
  };

  const validateEmail = () => {
    const { email } = formData;
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const validateForm = () => {
    const requiredFields = ['First Name', 'Last Name', 'Email', 'Phone'];
    for (const field of requiredFields) {
      if (!formData[field].trim()) {
        alert(`${field.replace(/([A-Z])/g, ' $1')} cannot be empty.`);
        return false;
      }
    }
    if (!validateEmail()) {
      const emailInputElement = document.getElementById('Email');
      emailInputElement.style.borderColor = "red";
      //alert('Please enter a valid email address.');
      return false;
    }
    const emailInputElement = document.getElementById('Email');
    emailInputElement.style.borderColor = "";
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
 /*    if (!formData.firstName || !formData.lastName || !formData.email || !formData.phone) {
      alert('Please fill in all required fields.');
      return;
    } */
      let isFormValid = true; // To track if the form is valid

      // Check each required field and set red border if empty
      if (!formData.firstName) {
        document.getElementById('First_Name').style.borderColor = "red";
        isFormValid = false;  // Mark the form as invalid
      } else {
        document.getElementById('First_Name').style.borderColor = "";
      }
    
      if (!formData.lastName) {
        document.getElementById('Last_Name').style.borderColor = "red";
        isFormValid = false;  // Mark the form as invalid
      } else {
        document.getElementById('Last_Name').style.borderColor = "";
      }
    
      if (!formData.email) {
        document.getElementById('Email').style.borderColor = "red";
        isFormValid = false;  // Mark the form as invalid
      } else {
        document.getElementById('Email').style.borderColor = "";
      }
    
      if (!formData.phone) {
        document.getElementById('Phone').style.borderColor = "red";
        isFormValid = false;  // Mark the form as invalid
      } else {
        document.getElementById('Phone').style.borderColor = "";
      }
    
      // If any required field is empty, prevent form submission
      if (!isFormValid) {
        return;
      }
  
    if (!validateEmail()) return;
  
    // Find the form
    const form = document.getElementById('webform745708000000454001');
    if (!form) {
      console.error("Form not found!");
      return;
    }
  
    // Log the form to check for existence of elements
    console.log(form);
  
    const formFields = ['firstName', 'lastName', 'email', 'phone', 'propertyType', 'maxBudget', 'leadType', 'beds', 'citiesOfInterest', 'country', 'city', 'description', 'leadSource'];
    
    formFields.forEach((field) => {
      const input = form.querySelector(`[name=${field}]`);
      if (input) {
        input.value = formData[field];
      } else {
        console.warn(`Field with name ${field} not found in the form.`);
      }
    });
  
    // Submit the form
     form.submit(); 
    
  };
  

 
    // Reset form data
  const handleReset = () => {
    document.getElementById('First_Name').style.borderColor = "";
    document.getElementById('Last_Name').style.borderColor = "";
    document.getElementById('Email').style.borderColor = "";
    document.getElementById('Phone').style.borderColor = "";
    setFormData({
      ...formData,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    });
  };
  return (
    <div id="request-viewing-popup" className="request-form">
      <div className="inner-request-form">
        <span 
        id="close-register-popup-alert-form"
        onClick={closePopupView}
        style={{
          cursor: "pointer",
          position: "absolute",
          top: "10px",
          right: "10px",
          fontSize: "20px", // Adjust size if needed
          color: "#000",
        }}
      >
        <FaTimes />
         </span>
        <h3>Request Viewing
        </h3>
        <div
          id="crmWebToEntityForm"
          className="zcwf_lblLeft crmWebToEntityForm"
          style={{ backgroundColor: 'white', color: 'black', maxWidth: '600px' }}
        >
         {/* Do not remove this --- Analytics Tracking code starts --> */}
         <script id='wf_anal' src='https://crm.zohopublic.eu/crm/WebFormAnalyticsServeServlet?rid=d2e60d8b3d9b8e6b5e64f66c5bbe976bd357b35b04d4bbdff2bc29d8d4c0c34eedef473ca9a82fc501e18ac3cd967a2dgid59c3a04c7daf26f70da00cfd02943ff60d6d0e5040b2f509aa5c6eae8667c0a0gidfc7f11af889b877ad51da87d82a38051405efb7e1470804d3879fb6ea490e79fgid84fa649522c1b6202f30bb24f1ea083a780b5d1f9929dda7e59766f974993a0f&tw=f9c3d402fcb85e0aacaee1f0751de05cc748e060476ea9c8a7aabe2c9094e0e0'></script>{/*  Do not remove this --- Analytics Tracking code ends. */}
        
           {/*<!-- Do not remove this --- Analytics Tracking code ends. -->*/}
           <form id='webform745708000000454001' action='https://crm.zoho.eu/crm/WebToLeadForm' name='WebToLeads745708000000454001' method='POST' acceptCharset='UTF-8'onSubmit={handleSubmit}>
           <input type='text' style={{ display: 'none' }} name='xnQsjsdp' value='108d9f333466e770acdcc2e11d55e80d32e9843641b9ddf826c492c57ce9efd7' />
<input type='hidden' name='zc_gad' id='zc_gad' value='' />
<input type='text' style={{ display: 'none' }} name='xmIwtLD' value='4e762d3eacb29b4bb2dff4e1531aed7e8e4694bc52379c7a011d8b30c088440bbafc586f36b14c0e9ad5d188ad3b23ae' />
<input type='text' style={{ display: 'none' }} name='actionType' value='TGVhZHM=' />
<input type='text' style={{ display: 'none' }} name='returnURL' value={`https://${domainName}/thank-you/`} />

            <div className="zcwf_title" style={{ maxWidth: '600px', color: 'black', fontFamily: 'Arial' }}>
              Property Buy Request Info
            </div>
            <div className="zcwf_row">
              <div className="zcwf_col_lab"></div>
              <div className="zcwf_col_fld">
                <input
                  type="text"
                  id="First_Name"
                  name="First Name"
                  value={formData.firstName}
                  onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                  placeholder="First Name"
                  maxLength="40"
                />
              </div>
            </div>
            <div className="zcwf_row">
              <div className="zcwf_col_lab"></div>
              <div className="zcwf_col_fld">
                <input
                  type="text"
                  id="Last_Name"
                  name="Last Name"
                  value={formData.lastName}
                  onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                  placeholder="Last Name"
                  maxLength="80"
                />
              </div>
            </div>
            <div className="zcwf_row">
              <div className="zcwf_col_lab"></div>
              <div className="zcwf_col_fld">
                <input
                  type="email"
                  id="Email"
                  name="Email"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  placeholder="Email"
                  maxLength="100"
                />
              </div>
            </div>
            <div className="zcwf_row">
              <div className="zcwf_col_lab"></div>
              <div className="zcwf_col_fld">
                <input
                  type="text"
                  id="Phone"
                  name="Phone"
                  value={formData.phone}
                  onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                  placeholder="Phone"
                  maxLength="30"
                />
              </div>
            </div>
            <div className="zcwf_row wfrm_fld_dpNn" >
              <div className="zcwf_col_lab">
                <label htmlFor="LEADCF6">Property Type</label>
              </div>
              <div className="zcwf_col_fld">
                <select
                  id="LEADCF6"
                  name="LEADCF6"
                  multiple
                  value={formData.propertyType}
                  onChange={(e) => setFormData({ ...formData, propertyType: [e.target.value] })}
                >
                  <option value="Apartment">Apartment</option>
                  <option value="Penthouse">Penthouse</option>
                  <option value="Detached House">Detached House</option>
                  <option value="Semi-detached House">Semi-detached House</option>
                  <option value="Villa">Villa</option>
                  <option value="Plot">Plot</option>
                  <option value="Land" selected="selected">Land</option>
                </select>
              </div>
            </div>
            <div className="zcwf_row wfrm_fld_dpNn">
              <div className="zcwf_col_lab">
                <label htmlFor="LEADCF66">Max Budget</label>
              </div>
              <div className="zcwf_col_fld">
                <input
                  type="text"
                  id="LEADCF66"
                  name="LEADCF66"
                  value={formData.maxBudget}
                  onChange={(e) => setFormData({ ...formData, maxBudget: e.target.value })}
                  maxLength="16"
                />
              </div>
            </div>
            <div className="zcwf_row wfrm_fld_dpNn">
              <div className="zcwf_col_lab">
                <label htmlFor="LEADCF1">Lead Type</label>
              </div>
              <div className="zcwf_col_fld">
                <select
                  id="LEADCF1"
                  name="LEADCF1"
                  value={formData.leadType}
                  onChange={(e) => setFormData({ ...formData, leadType: [e.target.value] })}
                >
                  <option value="-None-">-None-</option>
                  <option value="Buyer">Buyer</option>
                  <option value="Renter">Renter</option>
                  <option value="land">Landlord</option>
                  <option value="Unknown">Unknown</option>
                </select>
              </div>
            </div>
            <div className="zcwf_row wfrm_fld_dpNn">
              <div className="zcwf_col_lab">
                <label htmlFor="LEADCF5">Beds</label>
              </div>
              <div className="zcwf_col_fld">
                <select
                  id="LEADCF5"
                  name="LEADCF5"
                  multiple
                  value={formData.beds}
                  onChange={(e) => setFormData({ ...formData, beds: [e.target.value] })}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6+">6+</option>
                </select>
              </div>
            </div>
            <div className="zcwf_row wfrm_fld_dpNn">
              <div className="zcwf_col_lab">
                <label htmlFor="LEADCF2">Cities of Interest</label>
              </div>
              <div className="zcwf_col_fld">
              <select
              id="LEADCF2"
              name="LEADCF2"
              multiple
              value={formData.citiesOfInterest}
              onChange={(e) => setFormData({ ...formData, city: e.target.value })}
            >
              {['Nicosia', 'Limassol', 'Larnaca', 'Paphos', 'Famagusta'].map((city) => (
                <option key={city} value={city}>
                  {city}
                </option>
              ))}
            </select>
              </div>
            </div>
            <div className="zcwf_row wfrm_fld_dpNn">
              <div className="zcwf_col_lab">
                <label htmlFor="Country">Country</label>
              </div>
              <div className="zcwf_col_fld">
                <input
          type="text"
          name='Country'
          value={formData.country}
          onChange={(e) => setFormData({ ...formData, country: e.target.value })}
        />
              </div>
            </div>
            <div className="zcwf_row wfrm_fld_dpNn">
              <div className="zcwf_col_lab">
                <label htmlFor="City">City</label>
              </div>
              <div className="zcwf_col_fld">
              <input
          type="text"
          name='City'
          value={formData.city}
          onChange={(e) => setFormData({ ...formData, city: e.target.value })}
        />
              </div>
            </div>
            <div className="zcwf_row wfrm_fld_dpNn">
              <div className="zcwf_col_lab">
                <label htmlFor="Description">Description</label>
              </div>
              <div className="zcwf_col_fld">
                <textarea
                  id="Description"
                  name="Description"
                  value={formData.description}
                />
              </div>
            </div>
            <div className="zcwf_row wfrm_fld_dpNn">
              <div className="zcwf_col_lab">
                <label htmlFor="Lead_Source">Lead Source</label>
              </div>
              <div className="zcwf_col_fld">
                <select
                  id="Lead_Source"
                  name='Lead Source'
                  value={formData.leadSource}
                  onChange={handleChange}
                >
                   <option value='-None-'>-None-</option> <option value='Advertisement'>Advertisement</option> <option selected value='XML&#x20;Portals'>XML Portals</option> <option value='Cold&#x20;Call'>Cold Call</option> <option value='Employee&#x20;Referral'>Employee Referral</option> <option value='External&#x20;Referral'>External Referral</option> <option value='Partner'>Partner</option> <option value='Online&#x20;Store'>Online Store</option> <option value='Public&#x20;Relations'>Public Relations</option> <option value='Trade&#x20;Show'>Trade Show</option> <option value='Sales&#x20;Email&#x20;Alias'>Sales Email Alias</option> <option value='Seminar&#x20;Partner'>Seminar Partner</option> <option value='Facebook'>Facebook</option> <option value='Internal&#x20;Seminar'>Internal Seminar</option> <option value='X&#x20;&#x28;Twitter&#x29;'>X &#x28;Twitter&#x29;</option> <option value='Web&#x20;Download'>Web Download</option> <option value='Web&#x20;Research'>Web Research</option> <option value='Chat'>Chat</option> 
                </select>
              </div>
            </div>
            <div className="zcwf_row ">
              <div className="zcwf_col_lab"></div>
              <div className="zcwf_col_fld form-btn-sec">
                <input
                  type="submit"
                  id="formsubmit"
                  className="formsubmit zcwf_button"
                  value="Submit"
                />
                <input
                  type="button"
                  className="zcwf_button"
                  name="reset"
                  value="Reset"
                  onClick={handleReset}  // Calling the custom reset function
                />
              </div>
            </div>
          
          </form>
        </div>
      </div>
    </div>
  );
};

export default RequestViewingForm;
