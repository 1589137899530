import React, { useState, useEffect } from 'react';
import { FaTimes } from "react-icons/fa";
import { toast } from 'react-toastify'; // Importing toast and ToastContainer
import 'react-toastify/dist/ReactToastify.css'; 

const ShareWithFriendPopup = ({ closePopup }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    propertyUrl: `${window.location.href.split('?')[0]}`, // This can be passed dynamically or set based on context
    utmSource: 'utm_source',
    utmMedium: 'utm_medium',
    utmCampaign: 'utm_campaign',
    utmId: 'utm_id',
    utmTerm: 'utm_term',
    utmContent: 'utm_content',
  });
  //share-property-email

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  
  const domainName = window.location.hostname;

  const validateEmail = () => {
    const email = formData.email;
    const atpos = email.indexOf('@');
    const dotpos = email.lastIndexOf('.');
    const emailInputElement = document.getElementById('email');

    if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= email.length) {
      //alert('Please enter a valid email address.');
      emailInputElement.style.borderColor = "red";
      return false;
    }
    emailInputElement.style.borderColor = "";
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form from submitting the default way
    
    // Form validation
    let isFormValid = true; // To track if the form is valid
    
    // Check if required fields are filled and set red border if empty
    if (!formData.name) {
      document.getElementById('name').style.borderColor = "red"; // Ensure you have the correct ID for the name input
      isFormValid = false; 
    } else {
      document.getElementById('name').style.borderColor = "";
    }
  
    if (!formData.email) {
      document.getElementById('email').style.borderColor = "red"; // Ensure you have the correct ID for the email input
      isFormValid = false;
    } else {
      document.getElementById('email').style.borderColor = "";
    }
  
    if (!isFormValid) {
      return; // If validation fails, prevent submission
    }
        if (!validateEmail()) return;
  
    // If form is valid, proceed to send the form data
    try {
      // Make the POST request to the API with form data
      const response = await fetch(`https://${domainName}:5000/api/share-property-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Set the content type as JSON
        },
        body: JSON.stringify(formData), // Convert formData to JSON string
      });
  
      const data = await response.json(); // Parse the JSON response from the server
  
      if (response.ok) {
        // If the response is successful, show a success message
        toast.success(`Email sent successfully!`, {
          autoClose: 3000,
        });
        closePopup(); // Close the popup on success
      } else {
        // If the response is not successful, show an error message
        toast.error('Failed to send the email. Please try again later.', {
          autoClose: 3000,
        });
      }
    } catch (error) {
      // If there is an error in the request, catch it and show an error message
      toast.error('An error occurred while sending the email. Please try again later.', {
        autoClose: 3000,
      });
    }
  };
  
  return (
    <div id="share-popup" className="request-form">
      <div className="inner-request-form">
        <span className="share-popup-close" onClick={closePopup}>
          <FaTimes />
        </span>
        <h3>Share with a Friend</h3>

        <div className="wpcf7 js">
          <form  action={`https://${domainName}:5000/api/share-property-email`}
          onSubmit={handleSubmit} className="wpcf7-form" aria-label="Share form">
            <div style={{ display: 'none' }}>
              <input type="hidden" name="_wpcf7" value="6079" />
              <input type="hidden" name="_wpcf7_version" value="6.0" />
              {/* Include hidden fields */}
              <input type="hidden" name="text-510" value={formData.utmSource} />
              <input type="hidden" name="text-668" value={formData.utmMedium} />
              <input type="hidden" name="text-623" value={formData.utmCampaign} />
              <input type="hidden" name="text-645" value={formData.utmId} />
              <input type="hidden" name="text-293" value={formData.utmTerm} />
              <input type="hidden" name="text-872" value={formData.utmContent} />
            </div>
            <div>
              <input
                type="text"
                name="name"
                id='name'
                value={formData.name}
                onChange={handleChange}
                placeholder="Name"
               />
            </div>
            <div>
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
               />
            </div>
            <div>
            <input class="wpcf7-form-control wpcf7-hidden form-control hidden-item" id="prop_url" value={formData.propertyUrl} type="hidden" name="prop_url"  />
            </div>
            <div>
            <input className="wpcf7-form-control wpcf7-submit has-spinner btn" type="submit" value="Submit" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ShareWithFriendPopup;
