import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Header from './Header';
import Loader from './Loader';
/* import { toggleMenu } from './style'; */
import Footer from './Footer';
import { Helmet } from 'react-helmet';

const PropertyPage = () => {
  const [properties, setProperties] = useState([]);
  const [page, setPage] = useState(1); // Start at page 1
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // To track if there are more properties to load
  const [sortBy, setSortBy] = useState('Recent');
  const [activeTab, setActiveTab] = useState('all');
  const propertiesPerPage = 20;

  const [exclusiveAllData, setExclusiveAllData] = useState([]);
  const [exclusiveBuyData, setExclusiveBuyData] = useState([]);
  const [exclusiveRentData, setExclusiveRentData] = useState([]);
  const [allCount, setAllCount] = useState(0);
  const [buyCount, setBuyCount] = useState(0);
  const [rentCount, setRentCount] = useState(0);

  const [regions, setRegions] = useState([]); // List of all regions
  const [locations, setLocations] = useState([]); // List of locations based on selected region
  const [selectedRegion, setSelectedRegion] = useState(''); // Selected region
  const [selectedLocation, setSelectedLocation] = useState(''); // Selected location
  const [minPrice, setMinPrice] = useState(''); // Min price
  const [maxPrice, setMaxPrice] = useState(''); // Max price
  const [minBeds, setMinBeds] = useState(''); // Min beds
  const [maxBeds, setMaxBeds] = useState(''); // Max beds
  const [groupedLocations, setGroupedLocations] = useState({}); // To store locations grouped by region
  const [filteredCount, setFilteredCount] = useState(0);
  const host = window.location.hostname;

  useEffect(() => {
    fetchProperties(); // Load properties initially
    fetchPropertyCount();
   // toggleMenu();
  }, []);

  useEffect(() => {
    setPage(1); // Reset page when tab changes
    fetchProperties(); // Fetch properties again when activeTab changes
  }, [activeTab]);

  useEffect(() => {
    sortProperties();
  }, [sortBy]);

  // Updates the properties to show based on activeTab and filters
  const updateProperties = (category, allData, buyData, rentData) => {
    let filteredData = [...allData];

    if (category === 'all') {
      filteredData = allData;
    } else if (category === 'buy') {
      filteredData = buyData;
    } else if (category === 'rent') {
      filteredData = rentData;
    }


    // Apply location filter
    if (selectedRegion) {
      filteredData = filteredData.filter(
        (property) => property.region === selectedRegion
      );
    }

    if (selectedLocation) {
      filteredData = filteredData.filter(
        (property) => property.location === selectedLocation
      );
    }

    // Apply price filter
    if (minPrice) {
      console.log('min price is:'+minPrice);
      filteredData = filteredData.filter(
        (property) => parseFloat(property.price) >= parseFloat(minPrice)
      );
    }

    if (maxPrice) {
      filteredData = filteredData.filter(
        (property) => parseFloat(property.price) <= parseFloat(maxPrice)
      );
    }

    // Apply bedrooms filter
    if (minBeds) {
      filteredData = filteredData.filter(
        (property) => property.bedrooms >= minBeds
      );
    }

    if (maxBeds) {
      filteredData = filteredData.filter(
        (property) => property.bedrooms <= maxBeds
      );
    }

    // Update filtered count
    setFilteredCount(filteredData.length);

    // Paginate filtered data
    const paginatedData = filteredData.slice(0, page * propertiesPerPage);
    setProperties(paginatedData);
    setHasMore(filteredData.length > page * propertiesPerPage); // Handle infinite scroll
  };

  useEffect(() => {
    updateProperties(activeTab, exclusiveAllData, exclusiveBuyData, exclusiveRentData);
  }, [
    activeTab,
    exclusiveAllData,
    exclusiveBuyData,
    exclusiveRentData,
    page,
    selectedRegion,
    selectedLocation,
    minPrice,
    maxPrice,
    minBeds,
    maxBeds,
  ]);


  const fetchProperties = async () => {
    if (loading || !hasMore) return;
    setLoading(true);
  
    try {
      const response = await axios.get(
        `https://${host}:5000/api/property/all?page=${page}&limit=${propertiesPerPage}&property_type=exclusive`
      );
      const data = response.data;
      //console.log('property data: ' + JSON.stringify(data));
      // Initialize empty arrays for regions and a map for locations grouped by region
      let fetchedRegions = [];
      let groupedLocations = {};
  
      // Loop through the properties data to extract regions and locations
      data.forEach(property => {
        if (property.region && !fetchedRegions.includes(property.region)) {
          fetchedRegions.push(property.region); // Add region to fetchedRegions array if it doesn't already exist
        }
  
        if (property.location && property.region) {
          // Initialize location array if region doesn't exist in groupedLocations
          if (!groupedLocations[property.region]) {
            groupedLocations[property.region] = [];
          }
          // Add location to the corresponding region
          if (!groupedLocations[property.region].includes(property.location)) {
            groupedLocations[property.region].push(property.location);
          }
        }
      });
  
      // Set regions and locations state
      setRegions(fetchedRegions);
      setGroupedLocations(groupedLocations);
  
      // Log regions and groupedLocations to confirm
      console.log("Fetched Regions: ", fetchedRegions);
      console.log("Grouped Locations: ", groupedLocations);
  
      // Handle empty data case
      if (data.length === 0) {
        setHasMore(false);
        setLoading(false);
        return;
      }
  
      // Add new properties only if they don't exist
      const newProperties = data.filter(
        (newProp) => !exclusiveAllData.some((prevProp) => prevProp.property_id === newProp.property_id)
      );
  
      const updatedAllData = [...exclusiveAllData, ...newProperties];
      setExclusiveAllData(updatedAllData);
  
      const buyData = updatedAllData.filter((property) => property.buy_rent_category === 'buy');
      const rentData = updatedAllData.filter((property) => property.buy_rent_category === 'rent');
  
      setExclusiveBuyData(buyData);
      setExclusiveRentData(rentData);
  
      setAllCount(updatedAllData.length);
      setBuyCount(buyData.length);
      setRentCount(rentData.length);
  
      updateProperties(activeTab, updatedAllData, buyData, rentData);
    } catch (error) {
      console.error('Error fetching properties:', error);
      setHasMore(false);
    }
  
    setLoading(false);
  };
  

  
  const fetchPropertyCount = async () => {
    try {
    /*   const response = await fetch(`https://fitkube.com:5000/api/property-count-by-category/all?property_type=exclusive`);
     // if (!response.ok) throw new Error('Failed to fetch property count');
      const data = await response.json();
      setAllCount(data.count); // Set total property count for "all"
      setBuyCount(data.buyCount); // Example, adjust this based on your API response structure
      setRentCount(data.rentCount); // Example, adjust this based on your API response structure */
    } catch (error) {
      console.error('Error fetching property count:', error);
    }
  };

  // Sorting properties based on the selected option
  const sortProperties = () => {
    setProperties((prev) => {
      const sortedProperties = [...prev];
      if (sortBy === 'Recent') {
        sortedProperties.sort((a, b) => new Date(b.date) - new Date(a.date));
      } else if (sortBy === 'lowtohigh') {
        sortedProperties.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
      } else if (sortBy === 'hightolow') {
        sortedProperties.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
      }
      return sortedProperties;
    });
  };

  // Scroll event listener to trigger the next page load
  useEffect(() => {
    const handleScroll = () => {
      const nearBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - 500;
      if (nearBottom && hasMore && !loading) {
        setPage(prev => prev + 1); // Increment page when scrolling near the bottom
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, hasMore]); // Listen for scroll event and load next page if needed

  useEffect(() => {
  // Filter locations based on the selected region
  if (selectedRegion) {
    const filteredLocations = locations.filter(location => location.region === selectedRegion);
    setLocations(filteredLocations);
  }
}, [selectedRegion, locations]);

useEffect(() => {
  // Trigger property update when region, location or other filters change
  updateProperties(activeTab, exclusiveAllData, exclusiveBuyData, exclusiveRentData);
}, [
  activeTab, 
  exclusiveAllData, 
  exclusiveBuyData, 
  exclusiveRentData, 
  selectedRegion, 
  selectedLocation, 
  minPrice, 
  maxPrice, 
  minBeds, 
  maxBeds,
]);

  return (
    <div>
      <Helmet>
        <title>Featured Properties Listings Property One</title>
      </Helmet>
      <Header activeMarket="exclusives" />
      <div className="banner-header" style={{ backgroundImage: 'url(/images/exclusives.jpg)' }}>
        <h1>Featured Listings</h1>
      </div>
      <div className="main-sec">
        <div className="container">
          <div className="header-section" id="header-section">
            <div className='total-results'>
              <p>
              Showing: {properties.length} out of{' '}
              {new Intl.NumberFormat('en-US').format(filteredCount)}

               {/*  Showing: {properties.length} out of{' '}
                {new Intl.NumberFormat().format(
                  activeTab === 'all' ? allCount : activeTab === 'buy' ? buyCount : rentCount
                )} */}
              </p>
            </div>
            <div className="sort-by">
              <label>SortBy</label>
              <select name="sortby" value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
                <option value="Recent">Most Recent</option>
                <option value="lowtohigh">Lowest Price</option>
                <option value="hightolow">Highest Price</option>
              </select>
            </div>
          </div>

          <div className="filter-sec all-tab">
             
              <div className="sort-by">
                <select name="exclusivelisting" value={activeTab} 
                onChange={(e) => {
                  setActiveTab(e.target.value); // Update the active tab
                  setSelectedRegion(''); // Reset the selected region
                  setSelectedLocation(''); 
                  setMinBeds('');
                  setMaxBeds('');
                  setMinPrice('');
                  setMaxPrice('');
                  // Reset the selected location
                }}
                >
                  <option value="all">All Listings {/* ({allCount}) */}</option>
                  <option value="buy">For Sale {/* ({buyCount}) */}</option>
                  <option value="rent">For Rent {/* ({rentCount}) */}</option>
                </select>
              </div>
            
          </div>

      <div class="filter-section exclusives-filter">
        <form class="filter-form">
        {/*  Location Section */ }
        
        <div className="location-column">
  <div className="visible-location selected-visible">
    <i className="fa fa-map-pin"></i>
    <input
      type="text"
      id="region_location"
      placeholder="Locations"
      disabled
      value={`${selectedRegion !== '' ? selectedRegion + ' | ' : ''}${selectedLocation}`
}
    />
  </div>

  <div className="location-toggle select-details">
    <ul className="location-list">
      {/* Render regions as checkboxes */}
      {regions.length > 0 ? (
        regions.map((region) => (
          <li key={region} className={`${region}-reg1`}>
            <input
              type="checkbox"
              className="regionval"
              id={`region-${region}`}
              value={region}
              checked={selectedRegion === region} // Update checkbox state based on selected region
              onChange={() => {
                setSelectedRegion(region); // Update the selected region
                setSelectedLocation('');  // Reset the selected location when region changes
              }}
              
            />
            <span>{region}</span>

            {/* Render locations under the selected region */}
            {selectedRegion === region && groupedLocations[region] && (
              <ul className={`${region}-locations`}>
                {groupedLocations[region].map((location) => (
                  <li key={location}>
                    <input
                      type="checkbox"
                      className="locationval"
                      id={`location-${location}`}
                      value={location}
                      checked={selectedLocation === location} // Update checkbox state based on selected location
                      onChange={() => setSelectedLocation(location)} // Set selected location
                    />
                    <span>{location}</span>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))
      ) : (
        <p>No regions available</p> // Display if no regions are found € - €
      )}
    </ul>
  </div>
</div>



            <div class="price-column">
                      <div class="visible-price selected-visible">
                        <input type="text" id="pricerange" placeholder="Price" disabled  value={`€ ${minPrice ? new Intl.NumberFormat('en-US').format(minPrice) : ''} - € ${maxPrice ? new Intl.NumberFormat('en-US').format(maxPrice) : ''}`}/>
                        <i class="fa fa-chevron-down"></i>
                    </div>
            <div class="price-toggle select-details">
              <span>Min Price (€)</span>
                       <input
                        type="text"
                        value={minPrice ? Intl.NumberFormat('en-US').format(minPrice) : ''}  // Use raw numeric value
                        onChange={(e) => setMinPrice(e.target.value.replace(/\D/g, ''))}  // Store raw value without any commas
                        placeholder="Min"
                      />
                      <span>Max Price (€)</span>
                      <input
                        type="text"
                        value={maxPrice ? Intl.NumberFormat('en-US').format(maxPrice) : ''}
                      onChange={(e) => setMaxPrice(e.target.value.replace(/\D/g, ''))}  
                        placeholder="Max"
                      />
                  </div>   
            </div>

            <div className="beds-column">
            <div class="visible-beds selected-visible">
              <input type="text" id="bedsrange" placeholder="Beds" disabled value={` ${minBeds} - ${maxBeds} Beds`}/>
              <i class="fa fa-chevron-down"></i>
          </div>
          <div class="beds-toggle select-details">
              <span>Min Beds</span>
              <input
                type="number"
                value={minBeds}
                onChange={(e) => setMinBeds(e.target.value)}
                placeholder="Min"
              />
                <span>Max Beds</span>
              <input
                type="number"
                value={maxBeds}
                onChange={(e) => setMaxBeds(e.target.value)}
                placeholder="Max"
              />
                </div>
            </div>

        </form>
      </div>
{/*

*/}
        <div className="card-container">
            {properties.length > 0 ? (
              properties.map((property) => (
                <div className="card" key={property.property_id}>
                  <Link
                    to={`/properties/${property.property_id.split('-')[1]}_${property.bedrooms}-bedroom-${property.property_type.toLowerCase()}-to-${property.buy_rent_category}?type=exclusive`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="view-more-btn"
                  >
                    
                    <div
                    
  className="card-image"
  style={{
  
    backgroundImage: `url(${ property.property_added_status === 1 || property.property_added_status === "1" ? `https://${host}:5000/uploads/${property.features_image}` : property.features_image
    })`,
  }}
></div>
                    <div className="card-category">
                      {property.bedrooms !=0 || property.bedrooms !='0' ? property.bedrooms: ''} {property.property_type !== 'Land' ? 'Bed' : ''} {property.property_type} For {property.buy_rent_category =='buy'?'Sale':'Rent'}
                    </div>
                    <div className="card-price">
                      € {property.price ? new Intl.NumberFormat('en-US').format(property.price) : 'N/A'}
                    </div>
                     <div className="card-details">
                <h2>{property.property_title || 'No title available'}</h2>
                <p className="location-region">
                  <strong>{property.property_type || 'N/A'}</strong> | {property.location || 'N/A'}, {property.region || 'N/A'}
                </p>

                <div className="card-hover-details">
                  <p>€{property.price ? new Intl.NumberFormat('en-US').format(property.price) : 'N/A'}</p>
                  <p>{property.property_type || 'N/A'}</p>
                  <p>{property.location || 'N/A'}</p>
                  <p>{property.region || 'N/A'}</p> 
                  <p className="see-more-btn">See More</p> 
                  
                </div>
              </div>
                  </Link>
                </div>
              ))
            ) : (
              <div className="no-properties-found">
                <h2>No Properties found matching your search criteria.</h2>
              </div>
            )}
          </div>

          {loading && <Loader />}
          {!hasMore && <div className='load-message'>No more properties to load</div>}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default PropertyPage;
