import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head
import './newstyle.css'; // Your styles
import Header from './Header';
import Footer from './Footer';
import { jquery }  from 'react-jquery-plugin';
import { toast, ToastContainer } from 'react-toastify';
import Loader from './Loader';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
 

const Pages = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone:'',
    message:'',
    propertyUrl: `${window.location.href.split('?')[0]}`, // This can be passed dynamically or set based on context
    utmSource: 'utm_source',
    utmMedium: 'utm_medium',
    utmCampaign: 'utm_campaign',
    utmId: 'utm_id',
    utmTerm: 'utm_term',
    utmContent: 'utm_content',
  });
  const [pageSettings, setPageSettings] = useState(null);
  const [buy_data, set_buy_data] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // Added error state to track errors
  const host = window.location.hostname;



  // Fetching page settings from API
  useEffect(() => {
    const fetchPageSettings = async () => {
      try {
        const response = await fetch(`https://${host}:5000/api/page?page_id=5&page_title=lido`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: jquery{response.status}`); // Handle non-2xx HTTP status
        }

        const data = await response.json();

        if (data && data.data) {
          setPageSettings(data.data);
        } else {
          throw new Error('Error: No data received or incorrect format');
        }
      } catch (error) {
        console.error('Error fetching homepage settings:', error);
        setError(error.message); // Set error message to display in UI
      } finally {
        setLoading(false); // Ensure loading is set to false after fetching
      }
    };

    fetchPageSettings();
  }, []); // Empty array means this will run only once on component mount

// Owl Carousel js
  useEffect(() => {
    // Initialize Owl Carousel when the pageSettings are loaded
    if (pageSettings) {
      // This ensures the carousel initializes after content is injected into the DOM
      jquery(document).ready(function () {
    
  
        jquery(".show-more").click(function () {
            jquery(".show-content-btns").addClass("active-btn");
            jquery(".lido-sec-row .right-col-content").addClass("show-content");
            jquery(".show-less").click(function () {
                jquery(".show-content-btns").removeClass("active-btn");
                jquery(".lido-sec-row .right-col-content").removeClass("show-content");
            });
        });
    
        jquery(".slider-btn").click(function () {
            jquery(".banner-sec-two").addClass("show-slider");
            jquery(".close-slider-btn").click(function () {
                jquery(".banner-sec-two").removeClass("show-slider");
            });
        });
    
        jquery(".share-btn").click(function () {
            jquery(".share-popup").addClass("share-popup-active");
            jquery(".share-popup-close").click(function () {
                jquery(".share-popup").removeClass("share-popup-active");
            });
        });
    
        jquery(".popup-btn").click(function () {
            jquery(".request-info-popup").addClass("request-popup-active");
            jquery(".request-info-popup .request-popup-close").click(function () {
                jquery(".request-info-popup").removeClass("request-popup-active");
            });
        });

        jquery(".view-popup-btn").click(function () {
          jquery(".request-view-popup").addClass("request-view-active");
          jquery(".request-view-popup .request-popup-close").click(function () {
              jquery(".request-view-popup").removeClass("request-view-active");
          });
      });

    
    
        var sync1 = jquery("#sync1");
        var sync2 = jquery("#sync2");
        //var slidesPerPage = 3; //globaly define number of elements per page
        var syncedSecondary = true;
    
    
        sync1.owlCarousel({
            items: 1,
            slideSpeed: 2000,
            autoplay: true,
            animateIn: 'fadeIn',
            animateOut: 'fadeOut',
            autoplaySpeed: 1400,
            nav: false,
            dots: false,
            loop: true,
            responsiveRefreshRate: 200,
            navText: ['<svg width="100%" height="100%" viewBox="0 0 11 20"><path style="fill:none;stroke-width: 1px;stroke: #000;" d="M9.554,1.001l-8.607,8.607l8.607,8.606"/></svg>', '<svg width="100%" height="100%" viewBox="0 0 11 20" version="1.1"><path style="fill:none;stroke-width: 1px;stroke: #000;" d="M1.054,18.214l8.606,-8.606l-8.606,-8.607"/></svg>'],
        }).on('changed.owl.carousel', syncPosition);
    
        sync2
            .on('initialized.owl.carousel', function () {
                sync2.find(".owl-item").eq(0).addClass("current");
            })
            .owlCarousel({
                items: 3,
                margin: 10,
                dots: false,
                nav: false,
                smartSpeed: 200,
                slideSpeed: 500,
                slideBy: 3, //alternatively you can slide by 1, this way the active slide will stick to the first item in the second carousel
                responsiveRefreshRate: 100,
                responsive: {
                    0: {
                        items: 2,
                        slideBy: 2,
                    },
    
                    767: {
                        items: 3,
                        slideBy: 3
                    }
                }
    
            }).on('changed.owl.carousel', syncPosition2);
    
        function syncPosition(el) {
            //if you set loop to false, you have to restore this next line
            //var current = el.item.index;
    
            //if you disable loop you have to comment this block
            var count = el.item.count - 1;
            var current = Math.round(el.item.index - (el.item.count / 2) - .5);
    
            if (current < 0) {
                current = count;
            }
            if (current > count) {
                current = 0;
            }
    
            //end block
    
            sync2
                .find(".owl-item")
                .removeClass("current")
                .eq(current)
                .addClass("current");
            var onscreen = sync2.find('.owl-item.active').length - 1;
            var start = sync2.find('.owl-item.active').first().index();
            var end = sync2.find('.owl-item.active').last().index();
    
            if (current > end) {
                sync2.data('owl.carousel').to(current, 100, true);
            }
            if (current < start) {
                sync2.data('owl.carousel').to(current - onscreen, 100, true);
            }
        }
    
        function syncPosition2(el) {
            if (syncedSecondary) {
                var number = el.item.index;
                sync1.data('owl.carousel').to(number, 100, true);
            }
        }
    
        sync2.on("click", ".owl-item", function (e) {
            e.preventDefault();
            var number = jquery(this).index();
            sync1.data('owl.carousel').to(number, 300, true);
        });
    });
    
    var sync3 = jquery("#sync3");
    var sync4 = jquery("#sync4");
    //var slidesPerPage = 3; //globaly define number of elements per page
    var syncedSecondary = true;
    
    
    sync3.owlCarousel({
        items: 1,
        slideSpeed: 2000,
        autoplay: false,
        animateIn: 'fadeIn',
        animateOut: 'fadeOut',
        autoplaySpeed: 1400,
        nav: true,
        autoplay: false,
        dots: false,
        loop: true,
        responsiveRefreshRate: 200,
        navText: ['<svg width="100%" height="100%" viewBox="0 0 11 20"><path style="fill:none;stroke-width: 1px;stroke: #000;" d="M9.554,1.001l-8.607,8.607l8.607,8.606"/></svg>', '<svg width="100%" height="100%" viewBox="0 0 11 20" version="1.1"><path style="fill:none;stroke-width: 1px;stroke: #000;" d="M1.054,18.214l8.606,-8.606l-8.606,-8.607"/></svg>'],
    }).on('changed.owl.carousel', syncPosition);
    
    sync4
        .on('initialized.owl.carousel', function () {
            sync4.find(".owl-item").eq(0).addClass("current");
        })
        .owlCarousel({
            items: 3,
            margin: 10,
            dots: false,
            nav: false,
            smartSpeed: 200,
            slideSpeed: 500,
            slideBy: 3, //alternatively you can slide by 1, this way the active slide will stick to the first item in the second carousel
            responsiveRefreshRate: 100,
            responsive: {
                0: {
                    items: 2,
                    slideBy: 2,
                },
    
                767: {
                    items: 4,
                    slideBy: 4
                }
            }
    
        }).on('changed.owl.carousel', syncPosition2);
    
    function syncPosition(el) {
        //if you set loop to false, you have to restore this next line
        //var current = el.item.index;
    
        //if you disable loop you have to comment this block
        var count = el.item.count - 1;
        var current = Math.round(el.item.index - (el.item.count / 2) - .5);
    
        if (current < 0) {
            current = count;
        }
        if (current > count) {
            current = 0;
        }
    
        //end block
    
        sync4
            .find(".owl-item")
            .removeClass("current")
            .eq(current)
            .addClass("current");
        var onscreen = sync4.find('.owl-item.active').length - 1;
        var start = sync4.find('.owl-item.active').first().index();
        var end = sync4.find('.owl-item.active').last().index();
    
        if (current > end) {
            sync4.data('owl.carousel').to(current, 100, true);
        }
        if (current < start) {
            sync4.data('owl.carousel').to(current - onscreen, 100, true);
        }
    }
    
    function syncPosition2(el) {
        if (syncedSecondary) {
            var number = el.item.index;
            sync3.data('owl.carousel').to(number, 100, true);
        }
    }
    
    sync4.on("click", ".owl-item", function (e) {
        e.preventDefault();
        var number = jquery(this).index();
        sync3.data('owl.carousel').to(number, 300, true);
    });
    
    
    }
  }, [pageSettings]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //share with friend
const handleSubmitSWF = async (e) => {
  e.preventDefault();

  try {
    const response = await fetch(`https://${host}:5000/api/share-property-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    const data = await response.json();

    if (response.ok) {
      jquery('.share-popup-close').trigger('click');
      const name = document.getElementById('name');
      const email = document.getElementById('email');
      email.value ='';
      name.value ='';

      toast.success(`Email sent successfully!`, { autoClose: 3000 });
      const timer = setTimeout(() => {
        window.location.reload();
      }, 5000); 
      return () => clearTimeout(timer);
    } else {
      toast.error('Failed to send the email. Please try again later.', { autoClose: 3000 });
    }
  } catch (error) {
    alert('An error occurred while sending the email. Please try again later.');
  }
};
//request info form
  /* const handleSubmitRIF = async (e) => {
  }; */
  const handleSubmitRIF = async (e) => {
    e.preventDefault(); // Prevent the default form submission
  
    try {
      const response = await fetch(`https://${host}:5000/api/get-in-touch`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Set content type as JSON
        },
        body: JSON.stringify(formData), // Send formData as JSON
      });
  
      if (!response.ok) {
        throw new Error(`Failed to send form: ${response.status}`);
      }
  
      const result = await response.json();
      console.log(result); // Log the response to check its structure
  
      // Check the response for success
      if (result.success) {
        jquery('.request-popup-close').trigger('click');
        /* const name = document.getElementById('name');
        const email = document.getElementById('emailrpf');
        const phone = document.getElementById('phone');
        const message = document.getElementById('message');
        email.value ='';
        name.value ='';
        phone.value ='';
        message.value =''; */
        toast.success('Your message has been sent successfully!');
        const timer = setTimeout(() => {
          window.location.reload();
        }, 5000); // 5000 milliseconds (5 seconds)
  
        return () => clearTimeout(timer);
      } else {
        throw new Error('Failed to send message');
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`); // Error toast
    }
  };

  const handleSubmitRVI = async (e) => {
    e.preventDefault(); // Prevent the default form submission
  
    try {
      const response = await fetch(`https://${host}:5000/api/get-in-touch`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Set content type as JSON
        },
        body: JSON.stringify(formData), // Send formData as JSON
      });
  
      if (!response.ok) {
        throw new Error(`Failed to send form: ${response.status}`);
      }
  
      const result = await response.json();
      console.log(result); // Log the response to check its structure
  
      // Check the response for success
      if (result.success) {
        jquery('.request-popup-close').trigger('click');
        /* const name = document.getElementById('name');
        const email = document.getElementById('emailrpf');
        const phone = document.getElementById('phone');
        const message = document.getElementById('message');
        email.value ='';
        name.value ='';
        phone.value ='';
        message.value =''; */
        toast.success('Your message has been sent successfully!');
        const timer = setTimeout(() => {
          window.location.reload();
        }, 5000); // 5000 milliseconds (5 seconds)
  
        return () => clearTimeout(timer);
      } else {
        throw new Error('Failed to send message');
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`); // Error toast
    }
  };
  


  // Loading state
  if (loading) {
    return <div>{loading && <Loader />}</div>;
  }

  // Error state
  if (error) {
    return <div>Error: {error}</div>; // Display error message if an error occurred
  }

  // If no page settings are available
  if (!pageSettings) {
    return <div>No homepage settings available.</div>;
  }

  return (
    <div>
      {/* Add head content dynamically using Helmet */}
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>lido Exclusive -Property One</title>

        {/* jQuery and Owl Carousel CDN Links */}
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.1/jquery.min.js"></script>
        <link 
          rel="stylesheet" 
          href="https://cdnjs.cloudflare.com/ajax/libs/owl-carousel/1.3.3/owl.carousel.min.css" 
        />
        <link 
          rel="stylesheet" 
          href="https://cdnjs.cloudflare.com/ajax/libs/owl-carousel/1.3.3/owl.theme.default.min.css" 
        />
        <script src="https://cdnjs.cloudflare.com/ajax/libs/owl-carousel/1.3.3/owl.carousel.min.js"></script>
        <script src="https://fast.wistia.com/embed/medias/npn2zem7h1.jsonp" async></script>
        <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
       

      </Helmet>

      <Header activeMarket="home" />
      <ToastContainer className="toast-container" /> 
      {pageSettings.banner_section && (
        <div dangerouslySetInnerHTML={{ __html: pageSettings.banner_section }} />
      )}

      {pageSettings.content_section && (
        <div dangerouslySetInnerHTML={{ __html: pageSettings.content_section }} />
      )}
        {/* share popup */}
      <div className="share-popup">
        <div className="share-popup-container">
          <h3>Share with a Friend</h3>
          <span className="share-popup-close">
            <i className="fa-solid fa-xmark"></i>
          </span>
          <form
            className="share-with-fr-form"
            onSubmit={handleSubmitSWF} // handle submit through React
            encType="multipart/form-data"
            id="sh_with_friend"
          >
            <ul className="share-form">
              <li>
                <input
                  type="text"
                  name="name"
                  id='name'
                  value={formData.name} // controlled input
                  onChange={handleInputChange} // update state on change
                  placeholder="Name"
                />
              </li>
              <li>
                <input
                  type="email"
                  name="email"
                  id='email'
                  value={formData.email} // controlled input
                  onChange={handleInputChange} // update state on change
                  placeholder="Email"
                />
              </li>
              <input
                type="hidden"
                value={formData.propertyUrl}
                id="page_url"
                name="page_url"
              />
              <input
                type="submit"
                className="btn"
                value="Submit"
              />
            </ul>
          </form>
        </div>
      </div>
      {pageSettings.partners_section && (
        <div dangerouslySetInnerHTML={{ __html: pageSettings.partners_section }} />
      )}
      {/* request and view popup*/}
   {/*  <div class="request-info-popup ">
        <div class="request-popup-container">
            <h3>Request Info</h3>
            <span class="request-popup-close"><i class="fa-solid fa-xmark"></i></span>
             <form 
                className="request-info-form"
                encType="multipart/form-data"
                method="post"
                id="request_info_form"
                onSubmit={handleSubmitRIF}
             >
                <ul class="request-form-main">
                    <li> <input type="text" placeholder="Name"/></li>
                    <li> <input type="email" placeholder="Email"/></li>
                    <li><input type="number" placeholder="Phone"/></li>
                    <li><textarea type="Message" placeholder="Message"></textarea></li>
                    <input type='hidden'  value={formData.propertyUrl} id='page_url' name='page_url'/>
                    <input type="submit " class="btn" placeholder="Submit"/>
                </ul>
             </form>
        </div>
    </div> */}

<div className="request-info-popup ">
    <div className="request-popup-container">
      <h3>Request Info</h3>
      <span className="request-popup-close">
        <i className="fa-solid fa-xmark"></i>
      </span>
      <form
        className="request-info-form"
        encType="multipart/form-data"
        method="post"
        id="request_info_form"
        onSubmit={handleSubmitRIF} // Handle form submit in React
      >
        <ul className="request-form-main">
          <li>
            <input
              type="text"
              name="name" // Add name attribute for controlled form
              id="namerpf"
              value={formData.name} // Bind to state
              onChange={handleInputChange} // Update state on change
              placeholder="Name"
            />
          </li>
          <li>
            <input
              type="email"
              name="email"
              id="emailrpf"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email"
            />
          </li>
          <li>
            <input
              type="number"
              name="phone"
              id="phone"
              value={formData.phone}
              onChange={handleInputChange}
              placeholder="Phone"
            />
          </li>
          <li>
            <textarea
              name="message"
              value={formData.message}
              id='message'
              onChange={handleInputChange}
              placeholder="Message"
            />
          </li>
          <input
            type="hidden"
            value={formData.propertyUrl} // Set the hidden field's value
            id="page_url"
            name="page_url"
          />
          <input
            type="submit"
            className="btn"
            value="Submit"
          />
        </ul>
      </form>
    </div>
  </div>

   {/*  <div class="request-view-popup">
        <div class="request-popup-container">
            <h3>Request Viewing</h3>
            <span class="request-popup-close"><i class="fa-solid fa-xmark"></i></span>
            <form
                className="request-view-form"
                encType="multipart/form-data"
                method="post"
                id="request_view_form"
                onSubmit={handleSubmitRVI}
            >
                <ul class="request-form-main">
                    <li> <input type="text" name="name" id="namerpf"  value={formData.name} onChange={handleInputChange} placeholder="Name"/></li>
                    <li> <input type="email" name="email" id="email"  value={formData.email} onChange={handleInputChange} placeholder="Email"/></li>
                    <li><input type="number" name="phone" id="phone"  value={formData.phone} onChange={handleInputChange} placeholder="Phone"/></li>
                    <li><textarea type="Message" name="message" id="message"  value={formData.message} onChange={handleInputChange} placeholder="Message"></textarea></li>
                    <input type='hidden' value={formData.propertyUrl} id='page_url' name='page_url'/>
                    <input type="submit " class="btn" placeholder="Submit"/>
                </ul>
            </form>
        </div>
    </div>
  */}
   <div className="request-view-popup">
    <div className="request-popup-container">
      <h3>Request Viewing</h3>
      <span className="request-popup-close">
        <i className="fa-solid fa-xmark"></i>
      </span>
      <form
        className="request-view-form"
        encType="multipart/form-data"
        method="post"
        id="request_view_form"
        onSubmit={handleSubmitRVI}
      >
        <ul className="request-form-main">
          <li>
            <input
              type="text"
              name="name"
              id="namerpf"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Name"
            />
          </li>
          <li>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email"
            />
          </li>
          <li>
            <input
              type="number"
              name="phone"
              id="phone"
              value={formData.phone}
              onChange={handleInputChange}
              placeholder="Phone"
            />
          </li>
          <li>
            <textarea
              name="message"
              id="message"
              value={formData.message}
              onChange={handleInputChange}
              placeholder="Message"
            ></textarea>
          </li>
          <input
            type="hidden"
            value={formData.propertyUrl}
            id="page_url"
            name="page_url"
          />
          <input type="submit" className="btn" placeholder="Submit" />
        </ul>
      </form>
    </div>
  </div>
      {pageSettings.testimonials_section && (
        <div dangerouslySetInnerHTML={{ __html: pageSettings.testimonials_section }} />
      )}

        {pageSettings.team_section && (
        <div dangerouslySetInnerHTML={{ __html: pageSettings.team_section }} />
      )}

      {/* Footer */}
      <Footer />

    
    </div>
  );
};

export default Pages;
