import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head
import './newstyle.css'; 
import Header from './Header';
import Footer from './Footer'; 

const Pages = () => {
  const [pageSettings, setPageSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // Added error state to track errors
  const domainName = window.location.hostname;

  useEffect(() => {
    const fetchPageSettings = async () => {
      try {
        const response = await fetch(`https://${domainName}:5000/api/page?page_id=6&page_title=thank_you_page`);
        console.log(response);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`); // Handle non-2xx HTTP status
        }

        const data = await response.json();

        if (data && data.data) {
          setPageSettings(data.data);
        } else {
          throw new Error('Error: No data received or incorrect format');
        }
      } catch (error) {
        console.error('Error fetching homepage settings:', error);
        setError(error.message); // Set error message to display in UI
      } finally {
        setLoading(false); // Ensure loading is set to false after fetching
      }
    };

    fetchPageSettings();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = `http://${domainName}/`;
    }, 5000); // 5000 milliseconds (5 seconds)

    return () => clearTimeout(timer); // Clean up timeout on component unmount
  }, []);


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>; // Display error message if an error occurred
  }

  if (!pageSettings) {
    return <div>No homepage settings available.</div>;
  }

  return (
    <div>
        <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Thank you-Property One</title>
        <style>
            {`
            header.main-header-sec {
                background-color: black;
            }
            `}
        </style>
      </Helmet>
      {/* Add head content dynamically using Helmet */}
     <div>
     <Header activeMarket="thank you" />
     </div>

      {/* Render HTML sections dynamically using dangerouslySetInnerHTML */}
      {pageSettings.banner_section && (
        <div dangerouslySetInnerHTML={{ __html: pageSettings.banner_section }} />
      )}

      {pageSettings.content_section && (
        <div dangerouslySetInnerHTML={{ __html: pageSettings.content_section }} />
      )}

      {pageSettings.partners_section && (
        <div dangerouslySetInnerHTML={{ __html: pageSettings.partners_section }} />
      )}

      {pageSettings.testimonials_section && (
        <div dangerouslySetInnerHTML={{ __html: pageSettings.testimonials_section }} />
      )}

      {pageSettings.team_section && (
        <div dangerouslySetInnerHTML={{ __html: pageSettings.team_section }} />
      )}

      {/* Add scripts dynamically */}
          <Footer />
    </div>
  );
};

export default Pages;
