    import React, { useState ,useEffect} from "react";
    import { toast, ToastContainer } from 'react-toastify'; // Importing toast and ToastContainer
    import 'react-toastify/dist/ReactToastify.css'; // Importing the default styles
    import Header from './Header';
    import Footer from './Footer';
    import { useNavigate } from 'react-router-dom';

    const ResetPasswordPage = ({}) => {
    const [formData, setFormData] = useState({
      
        resetPassword: "", // New field for password reset
        user_email : localStorage.getItem('email'),
    });
    const [username, setUsername] = useState(null);  // Track the logged-in username
    const [submittedData, setSubmittedData] = useState(null); // Store the submitted form data here

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value, // Handle changes for resetPassword input
        });
    };


// Inside your component
const navigate = useNavigate();
const domainName = window.location.hostname;

const handleSubmit = async (e) => {
    e.preventDefault();
  
    const payload = {
        resetPassword: formData.resetPassword,
        email: formData.user_email
    };
  
    try {
        const response = await fetch(`https://${domainName}:5000/api/userreset-passwowrd`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });
  
        const data = await response.json();
  
        if (response.ok) {
            if (data.success) {
                document.getElementById('resetPassword').value = '';
                toast.success(data.message || 'Password reset successfully!', { autoClose: 3000 });
  
                // Log out the user
                localStorage.removeItem('email');
                localStorage.removeItem('userToken');
                localStorage.removeItem('username');
                localStorage.removeItem('id');
  
                // Redirect to login page using react-router
                setTimeout(() => {
                    navigate('/login');
                }, 2000);
            } else {
                toast.error(data.message || 'Failed to reset the password. Please try again later.', { autoClose: 3000 });
            }
        } else {
            toast.error(data.message || 'An error occurred. Please try again later.', { autoClose: 3000 });
        }
    } catch (error) {
        console.error('Error during form submission:', error);
        toast.error('Error occurred during submission. Please try again later.', { autoClose: 3000 });
    }
};
useEffect(() => {
    const url = window.location.pathname.slice(1); // Remove the leading slash
    const headerMenuSec = document.querySelector('.main-header-sec'); // Select the element
    
    if (url === 'update-password' && headerMenuSec) {
      // Apply the style if the condition matches
      headerMenuSec.style.backgroundColor = 'black';
    } else if (headerMenuSec) {
      // Reset the style if the condition doesn't match
      headerMenuSec.style.backgroundColor = '';
    }
  }, []); // 

    
    return (
        <div className="login-page">
          
        <Header activeMarket='reset-password'  style={{ backgroundColor: 'black' }}/>
        <ToastContainer className="toast-container" /> 
        <div className="alert-popup">
            <div className="filter-sec">
            <div className="container">
                <h4>Reset Password</h4>

                {/* Reset Password Form */}
                <form
                encType="multipart/form-data"
                method="post"
                id="wppb-reset-password"
                className="wppb-user-forms wppb-reset-password"
                onSubmit={handleSubmit}
                >
                <ul>
                    <li className="wppb-form-field">
                    <label htmlFor="resetPassword">
                        New Password<span className="wppb-required">*</span>
                    </label>
                    <input
                        className="text-input"
                        name="resetPassword"
                        type="password"
                        id="resetPassword"
                        value={formData.resetPassword}
                        onChange={handleChange}
                        required
                    />
                    </li>
                </ul>

                <p className="form-submit">
                    <input
                    name="reset-password"
                    type="submit"
                    className="submit button"
                    value="Reset Password"
                    />
                </p>
                </form>

                {/* Display the form data after submission */}
                {submittedData && (
                <div className="form-data">
                    <h4>Form Data Submitted:</h4>
                    <pre>{JSON.stringify(submittedData, null, 2)}</pre>
                </div>
                )}
            </div>
            </div>
        </div>
        <Footer />
        </div>
    );
    };

    export default ResetPasswordPage;
