import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import { FaTimes } from "react-icons/fa";
import { toast } from 'react-toastify'; 

const PropertyPage = () => {
  const [properties, setApiData] = useState([]);    
  const [session_id, setSessionid] = useState(''); 
  const [saveproperty1, setSaveids] = useState([]); 
  const [saveproperty, setSavedFields] = useState(null); 
  const [deletedProperties, setDeletedProperties] = useState([]); // State to track the selected property URLs
  const [selectedProperties, setSelectedProperties] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    date: '',
    propertyUrl: '',  // Initialize propertyUrl with selectedProperties
  });

  const host = window.location.hostname;

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      propertyUrl: selectedProperties, // Update propertyUrl with selectedProperties
    }));
  }, [selectedProperties]);


  useEffect(() => {
    const sessionid = localStorage.getItem('id'); 
    if (sessionid) {
      setSessionid(sessionid); // Set the sessionid state if found
    }
  }, []);

  useEffect(() => {
    if (session_id !== '') {
        fetchData();
    }
  }, [session_id]);
    // Use environment variable or fallback
    const apiUrl1 = `https://${host}:5000/api/getalertlistingsproperty`;

  const fetchData = async () => { 
      const requestData = {
          session_id,
      };
      try {
          // Use POST instead of GET
          const response = await axios.post(apiUrl1, requestData);
          if (response.data.token) {
          console.log('data get');
          setSaveids(response.data.user.listingsids);
          } else {
          console.log('data failed');
          }
      } catch (error) {
          console.error('Error fetching data:', error);
      }
  };

    useEffect(() => {
        let newSavedFields = '';      
        saveproperty1.forEach((item, index) => {
            if (item !== "") {
                if (newSavedFields === '') {
                    newSavedFields = item;
                } else {
                    newSavedFields = `${newSavedFields},${item}`; // Add comma between IDs
                }
            }
        });

        // Update the savedFields state with the new values
        setSavedFields(newSavedFields);
    }, [saveproperty1]);



  useEffect(() => {
    if (saveproperty && typeof saveproperty === 'string' && saveproperty.length > 0) {
        fetchDataget();  
    }
  }, [saveproperty, session_id]); 
  const apiUrlids = `https://${host}:5000/api/getsavepropertydata`;
  const fetchDataget = async () => { 
    if (saveproperty && typeof saveproperty === 'string' && saveproperty.length > 0) {
      const requestData = {
        saveproperty: saveproperty.split(',') // Split the comma-separated string into an array
      };

      try {
        // Use POST to send the request
        const response = await axios.post(apiUrlids, requestData);
        
        if (response.data.token) {
          console.log('Data fetched successfully');
          setApiData(response.data.user); // Assuming 'user' contains the data
        } else {
          console.log('Data fetching failed');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else {
      console.log('Invalid saveproperty value:', saveproperty);
    }  
  };

  // Function to handle checkbox change
  const handleCheckboxChange = (event) => {
    const propertyUrl = event.target.value;

    if (event.target.checked) {
      // If checked, add the property URL to the selectedProperties string
      setSelectedProperties((prevSelected) => {
        // If there are no selected properties yet, initialize with the current URL
        if (!prevSelected) {
          return propertyUrl;
        }
        // Append the new URL with a delimiter (e.g., comma)
        return prevSelected + '\n' + propertyUrl;
      });
    } else {
      // If unchecked, remove the property URL from the selectedProperties string
      setSelectedProperties((prevSelected) => {
        if (!prevSelected) return null; // No selected properties to remove from

        // Remove the URL from the string
        const updatedSelected = prevSelected
          .split(',')
          .filter((url) => url !== propertyUrl)
          .join(',');

        // If the string is empty after removal, return null
        return updatedSelected || null;
      });
    }
  }; 

  const handleRemoveProperty = async (event) => {
    const apiUrlidsdlt = `https://${host}:5000/api/alertpropertydelete`;
    
    const target = event.target.closest('a'); // Ensure we're targeting the <a> element
  
    if (target) {
      const propertyId = target.getAttribute('data-id');
      const requestData = {
        propertyId,
        session_id,
      };
  
      try {
        // Use POST instead of GET
        const response = await axios.post(apiUrlidsdlt, requestData);
    
        if (response.data.token) {
          console.log('Property deleted successfully');
          // Track the deleted property ID
          setDeletedProperties((prevDeleted) => [...prevDeleted, propertyId]);
        
        } else {
          console.log('Failed to delete property');
        }
      } catch (error) {
        console.error('Error removing property:', error);
      }
    }
    // You can also take further actions here, like updating the UI or state after the property is removed
  };

  const [loading, setLoading] = useState(false);
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [successmsg, setsuccessmsg] = useState(false);

  const handleRequestViewings = () => {
    // Set loading to true when the "Request Viewings" is clicked
    setLoading(false);

    // Simulate an API call or action and set loading back to false after a delay
    setTimeout(() => {
      setLoading(false);
      // Toggle the visibility of the share-popup
      setShowSharePopup((prevState) => !prevState);
    }, 50); // Example timeout of 2 seconds 
  };
  const closehandleRequestViewings = () => {
    setShowSharePopup(false);
  };


  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form from submitting the default way
  
    try {
      // Make the POST request to the API with form data
      const response = await fetch(`https://${host}:5000/api/save-property-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Set the content type as JSON
        },
        body: JSON.stringify(formData), // Convert formData to JSON string
      });
  
      const data = await response.json(); // Parse the JSON response from the server
  
      if (response.ok) {        
       setsuccessmsg(true);
        // If the response is successful, show a success message
        toast.success(`Email sent successfully!`, {
          autoClose: 5000,
        });
       setTimeout(() => {
          closehandleRequestViewings();  // Call the function to close the popup after 5 seconds
        }, 3000);
      } else {
        // If the response is not successful, show an error message
        toast.error('Failed to send the email. Please try again later.', {
          autoClose: 3000,
        });
        //alert('Failed to send the email. Please try again later.');
      }
    } catch (error) {
      // If there is an error in the request, catch it and show an error message
      alert('An error occurred while sending the email. Please try again later.');
    }
  };

  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (properties.length <= 0) {
      const timer = setTimeout(() => {
        setShowMessage(true);
      }, 5000); // 5 seconds delay

      return () => clearTimeout(timer); // Clean up the timer if the component is unmounted
    } else {
      setShowMessage(false); // Reset message if properties are available
    }
  }, [properties]);
  
  
  return (
    <div className="">
       <Helmet>
        <title>Property Alert Listing – Property One</title>
      </Helmet>
     <Header activeMarket="buy" />
        <div className="banner-header">
            <h1>Alert Listings</h1>
        </div>
    <div className="main-sec">
      <div className="container">
        
           
        {/* Property cards */}         
        {properties.length > 0 && (
          <div className="my-list">
            <a href="javascript:void(0)" className="request selected-viewing-popup-form" onClick={handleRequestViewings}> Request Viewings For All Selected</a>
          </div>
        )}
         {showMessage && (
          <div className="my-list">
            <h3>No Matching Properties Found</h3>
          </div>
        )}

        {loading && (
          <div className="loader">
            <img src="../images/loader-img.gif" alt="Loading..." />
          </div>
        )}
         {showSharePopup && (
        <div id="share-popup" className="request-form">
          <div className="inner-request-form">
            <span className="share-popup-close" onClick={closehandleRequestViewings}>
              <FaTimes />
            </span>
            <h3>Request Viewings For All Selected</h3>
            <form action={`https://${host}:5000/api/share-property-email`} onSubmit={handleSubmit} className="wpcf7-form" aria-label="Share form">
              <input type="text" name="name" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} placeholder="Name" required />
              <input type="email" name="email" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} placeholder="Email" required />
              <input type="tel" name="phone" value={formData.phone} onChange={(e) => setFormData({ ...formData, phone: e.target.value })} placeholder="Phone" required />
              <input type="date" name="date" value={formData.date} onChange={(e) => setFormData({ ...formData, date: e.target.value })} required />
              <input className="wpcf7-form-control wpcf7-hidden" id="prop_url" value={formData.propertyUrl} type="hidden" name="prop_url" />
              <input className="wpcf7-form-control wpcf7-submit" type="submit" value="Submit" />
              {successmsg && (
                <div class="success-message green-msg">Thank you for your submission our team will revert shortly to arrange viewings.</div>
              )}
            </form>
          </div>
        </div>
         )}
        <div className="card-container">            
        {session_id !== '' ? (
         properties.length > 0 ? (
          properties.flat().map((property) => ( // Use flat() to flatten the nested arrays
            
            <div className="card" key={property.property_id} style={{display: deletedProperties.includes(property.property_id) ? 'none' : 'block',}}>
              <Link 
  to={`/properties/${property.property_id.split('-')[1]}_${property.property_type ==='Land' ? '' : property.bedrooms +'-bedroom-'}${ property.property_type ==='Land' ? '' : property.property_type.toLowerCase() +'-to-'}${property.property_type ==='Land' ? '' : property.buy_rent_category}`} 
  target="_blank" rel="noopener noreferrer"  className="view-more-btn">
                <div
                  className="card-image"
                  style={{
                    backgroundImage: `url(${property.features_image || ''})`,
                  }}
                ></div>
                <div className="card-category">
                  {property.bedrooms === '0' ? '' : property.bedrooms + ' Beds'} {property.property_type} For {property.buy_rent_category
                  }
                </div>
                <div className="card-price">
                  € {property.price ? new Intl.NumberFormat().format(property.price) : 'N/A'} 
                  <p>{property.rental_yield !== '0' && property.rental_yield !== '' ? `${Math.round(property.rental_yield)}% Yield` : ''} </p>
                </div>
                <div className="card-yield"></div>

                <div className="card-details">
                  <h2>{property.property_title === 'N/A' ? '' : property.property_title}</h2>
                  <p className="location-region">
                    <strong>{property.property_type || 'N/A'}</strong> | {property.location || 'N/A'}, {property.region || 'N/A'}
                  </p>

                  <div className="card-hover-details">
                    <p>€{property.price ? new Intl.NumberFormat().format(property.price) : 'N/A'}</p>
                    <p>{property.property_type || 'N/A'}</p>
                    <p>{property.location || 'N/A'}</p>
                    <p>{property.region || 'N/A'}</p>
                    <p className="see-more-btn">See More</p>
                  </div>
                </div>
              </Link>
              <div className="check-property">
                <div className="check-text">
                  <input type="checkbox" className="check-property" name="check_property" value={`https://${host}/properties/${property.property_id.split('-')[1]}_${property.property_type ==='Land' ? '' : property.bedrooms +'-bedroom-'}${ property.property_type ==='Land' ? '' : property.property_type.toLowerCase().replace(/\s+/g, '-')+'-to-'}${property.property_type ==='Land' ? '' : property.buy_rent_category}`} 
                    onChange={(e) => handleCheckboxChange(e)} />
                  <label>Select</label>
                </div>  
                <div className="remove-text"  onClick={handleRemoveProperty}>
                  <a href="javascript:void(0)" className="remove-property" id="remove_property1"  data-id={property.property_id}>
                    Remove <span><img src="../images/close-icon.png" alt="close icon" /></span>
                  </a>
                </div>
              </div>
            </div>
          ))
          ) :  (
            <div className="no-properties-found">
              <img src="../images/loader-img.gif" alt="Loading..." />
            </div>
          )
        ) : (
          <ul class="list-property">
            <div class="login-register-sec">For View Alert Listing Properties Please  
              <a href={`https://${host}/login/`}> Login</a>
            </div>
			    </ul>
        )}
        
        </div>
      </div>
      <Footer />
     
    </div>
    </div>
  );
};

export default PropertyPage;
