import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head
import './newstyle.css';
import Header from './Header';
import Footer from './Footer';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Importing the default styles
import Loader from './Loader';

const Pages = () => {
  const [pageSettings, setPageSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // Added error state to track errors
  const host = window.location.hostname; 


  useEffect(() => {
    const fetchPageSettings = async () => {
      try {  
        const response = await fetch(`https://${host}:5000/api/page?page_id=4&page_title=contact`);
        console.log(response);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`); // Handle non-2xx HTTP status
        }

        const data = await response.json();

        if (data && data.data) {
          setPageSettings(data.data);
        } else {
          throw new Error('Error: No data received or incorrect format');
        }
      } catch (error) {
        console.error('Error fetching homepage settings:', error);
        setError(error.message); // Set error message to display in UI
      } finally {
        setLoading(false); // Ensure loading is set to false after fetching
      }
    };

    fetchPageSettings();
  }, []);


   const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
  
    const formData = new FormData(e.target); // Gather form data
    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });
    // Prevent the form submission if validation fails
    let isFormValid = true; // To track if the form is valid
  
    // Check each required field and set red border if empty
    if (!formData.get('name')) {  // Use get() to access FormData values
      document.getElementById('Name').style.borderColor = "red";
      isFormValid = false;  // Mark the form as invalid
    } else {
      document.getElementById('Name').style.borderColor = "";
    }
  
    if (!formData.get('message')) {  // Using 'message' key for the message field
      document.getElementById('CONTACTCF5').style.borderColor = "red";
      isFormValid = false;  // Mark the form as invalid
    } else {
      document.getElementById('CONTACTCF5').style.borderColor = "";
    }
  
    if (!formData.get('email')) {
      document.getElementById('Email').style.borderColor = "red";
      isFormValid = false;  // Mark the form as invalid
    } else {
      document.getElementById('Email').style.borderColor = "";
    }
  
    if (!formData.get('phone')) {
      document.getElementById('Phone').style.borderColor = "red";
      isFormValid = false;  // Mark the form as invalid
    } else {
      document.getElementById('Phone').style.borderColor = "";
    }
  
    // If any required field is empty, prevent form submission
    if (!isFormValid) {
      return;
    }

  
    try {
      const response = await fetch(`https://${host}:5000/api/get-in-touch`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Set content type as JSON
        },
        body: JSON.stringify(data), // Send data as JSON
      });
  
      if (!response.ok) {
        throw new Error(`Failed to send form: ${response.status}`);
      }
  
      const result = await response.json();
      console.log(result); // Log the response to check its structure
  
      // Check the response for success
      if (result.success) {
        toast.success('Your message has been sent successfully!'); // Success toast
        const timer = setTimeout(() => {
          window.location.href = `https://${host}/`;
        }, 5000); // 5000 milliseconds (5 seconds)
    
        return () => clearTimeout(timer);
        //formRef.current.reset(); // Use the formRef to reset the form
      } else {
        throw new Error('Failed to send message');
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`); // Error toast
    }
  };

  const formRef = useRef(null); // Create a ref for the form

  const handleReset = () => {
   
  
    // Manually clear each input field
    document.getElementById('Name').value = "";
    document.getElementById('Email').value = "";
    document.getElementById('Phone').value = "";
    document.getElementById('CONTACTCF5').value = "";
  
    // Reset border color
    ['Name', 'Email', 'Phone', 'CONTACTCF5'].forEach((id) => {
      const element = document.getElementById(id);
      if (element) {
        element.style.borderColor = "";
      }
    });
  };
  

  if (loading) {
    return <div>{loading && <Loader />}</div>;
  }

  if (error) {
    return <div>Error: {error}</div>; // Display error message if an error occurred
  }

  if (!pageSettings) {
    return <div>No homepage settings available.</div>;
  }

  return (
    <div>
      {/* Add head content dynamically using Helmet */}
      <Helmet>
        <title>Contact-Property One</title>
        <meta name="title" content="Contact PropertyOne | Leading Real Estate Agency in Cyprus" data-react-helmet="true"></meta>
        <meta name="description" content="Get in touch with PropertyOne for all your real estate needs in Cyprus. Contact us today to find your dream property." data-react-helmet="true"></meta>
        
      </Helmet>
      <Header activeMarket="contact" />
      <ToastContainer /> 
      {/* Render HTML sections dynamically using dangerouslySetInnerHTML */}
      {pageSettings.banner_section && (
        <div dangerouslySetInnerHTML={{ __html: pageSettings.banner_section }} />
      )}
      <div className="form-main-sec">
        <div className="container">
          <div className="form-main-row">
            <div className="form-left-col">
              <div className="map-col">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3545.8455331998753!2d33.331043!3d35.173362!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14de1767ca494d55%3A0x7d936f1e1d23a657!2zNThDVitNUjYsIExlZmtvxZ9hLCBDeXBydXM!5e1!3m2!1sen!2sus!4v1736748203965!5m2!1sen!2sus"
                  width="600"
                  height="450"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <div className="form-left-img">
                <img src="images/PropertyOne_Logo.png" alt="" />
              </div>
            </div>
            <div className="form-right-col">
              <div className="form-title-row">
                <div className="form-title-left">
                  <h2>Get in Touch</h2>
                </div>
                <div className="form-title-right">
                  <ul>
                    <li><a href="tel:+35722285635">+35722285635</a></li>
                    <li><a href="mailto:info@propertyone.com.cy">info@propertyone.com.cy</a></li>
                  </ul>
                </div>
              </div>
              <form
               ref={formRef}
                className="contact-form"
                encType="multipart/form-data"
                method="post"
                id="get-in-tuch"
                onSubmit={handleSubmit}
              >
                <ul className="form-input-row-sec">
                  <li className="input-group">
                    <input type="text" id="Name" name="name" placeholder="Name" />
                  </li>
                  <li className="input-group">
                    <input type="email" id="Email" name="email" placeholder="Email" />
                  </li>
                  <li className="input-group">
                    <input type="text" id="Phone" name="phone" maxLength="50" placeholder="Phone" />
                  </li>
                  <li className="input-group">
                    <textarea id="CONTACTCF5" name="message" placeholder="Message"></textarea>
                  </li>
                </ul>
                <div className="submit-row">
                  <input type="submit" id="formsubmit" className="formsubmit btn" value="Submit" title="Submit" />
                  <input type="button" className="btn-2" name="reset" value="Reset" title="Reset" onClick={handleReset}/>
                </div>
              </form>
              <div className="contact-social">
                <ul>
                  <li><a href="mailto:info@propertyone.com.cy"><i className="fas fa-at"></i></a></li>
                  <li><a href="https://www.instagram.com/propertyonehq/" target="_blank"><i className="fab fa-instagram"></i></a></li>
                  <li><a href="https://www.facebook.com/propertyonehq" target="_blank"><i className="fab fa-facebook"></i></a></li>
                  <li><a href="https://t.me/+35796371372" target="_blank"><i className="fab fa-telegram"></i></a></li>
                  <li><a href="https://wa.me/+35796371372" target="_blank"><i className="fab fa-whatsapp"></i></a></li>
                  <li><a href="tel:+35722285635"><i className="fa-solid fa-square-phone"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {pageSettings.content_section && (
        <div dangerouslySetInnerHTML={{ __html: pageSettings.content_section }} />
      )}

      {pageSettings.partners_section && (
        <div dangerouslySetInnerHTML={{ __html: pageSettings.partners_section }} />
      )}

      {pageSettings.testimonials_section && (
        <div dangerouslySetInnerHTML={{ __html: pageSettings.testimonials_section }} />
      )}

      {pageSettings.team_section && (
        <div dangerouslySetInnerHTML={{ __html: pageSettings.team_section }} />
      )}

      <Footer />
    </div>
  );
};

export default Pages;
