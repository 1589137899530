import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import Loader from './Loader';
import Popup from "./uesrRegisterForm";
import { Helmet } from 'react-helmet';

const MarketPaphos = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [propertyLocation, setPropertyLocation] = useState([]); // Store locations
  const [marketData, setMarketData] = useState({}); // Store the market data
  const [aggregatedData, setAggregatedData] = useState({}); // Store aggregated data
  const [hideRowsRent, setHideRowsRent] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(''); // Track selected location rent 

   // my hr code
   const [apiData, setApiData] = useState([]);  
   const [session_id, setSessionid] = useState(null); 
   const [metchpropertyIds, setmetchpropertyIds] = useState('');
   const [metchpropertyIdsrpa, setmetchpropertyIdsrpa] = useState('');
   const [metchpropertyIdsari, setmetchpropertyIdsari] = useState('');
   const [metchpropertyIdsaryi, setmetchpropertyIdsaryi] = useState('');
 
   const [formType, setSpaFormType] = useState("spa_form");
   const [selectedRegions, setSelectedRegions] = useState('');
   const [selectedLocationval, setSelectedLocationval] = useState(""); 
   const [selectedTypes, setSelectedTypes] = useState(""); // Property types selected for filtering
   const [minPrice, setMinPrice] = useState('');
   const [maxPrice, setMaxPrice] = useState('');    
   const [selectedConditions, setSelectedConditions] = useState("");
   const [minBeds, setMinBeds] = useState('');
   const [maxBeds, setMaxBeds] = useState('');
   const [minSqft, setMinSqft] = useState('');
   const [maxSqft, setMaxSqft] = useState('');
   
   // rpa form
   const [formTyperpa, setSpaFormTyperpa] = useState("rpa_form");
   const [selectedRegionsrpa, setSelectedRegionsrpa] = useState('');
   const [selectedLocationvalrpa, setSelectedLocationvalrpa] = useState(""); 
   const [selectedTypesrpa, setSelectedTypesrpa] = useState(""); // Property types selected for filtering
   const [minPricerpa, setMinPricerpa] = useState('');
   const [maxPricerpa, setMaxPricerpa] = useState('');
   const [selectedConditionsrpa, setSelectedConditionsrpa] = useState("");
   const [minBedsrpa, setMinBedsrpa] = useState('');
   const [maxBedsrpa, setMaxBedsrpa] = useState('');
   const [minSqftrpa, setMinSqftrpa] = useState('');
   const [maxSqftrpa, setMaxSqftrpa] = useState('');
  
   // ari form
   const [formTypeari, setSpaFormTypeari] = useState("ari_form");
   const [selectedRegionsari, setSelectedRegionsari] = useState('');
   const [selectedLocationvalari, setSelectedLocationvalari] = useState(""); 
   const [selectedTypesari, setSelectedTypesari] = useState(""); // Property types selected for filtering
   const [minAnnual, setMinAnnual] = useState('');
   const [maxAnnual, setMaxAnnual] = useState('');
   const [selectedConditionsari, setSelectedConditionsari] = useState("");
   const [minBedsari, setMinBedsari] = useState('');
   const [maxBedsari, setMaxBedsari] = useState('');
   const [minSqftari, setMinSqftari] = useState('');
   const [maxSqftari, setMaxSqftari] = useState('');
 
   // aryi form
   const [formTypearyi, setSpaFormTypearyi] = useState("aryi_form");
   const [selectedRegionsaryi, setSelectedRegionsaryi] = useState('');
   const [selectedLocationvalaryi, setSelectedLocationvalaryi] = useState(""); 
   const [selectedTypesaryi, setSelectedTypesaryi] = useState(""); // Property types selected for filtering
   const [minYield, setMinYield] = useState('');
   const [maxYield, setMaxAYield] = useState('');
   const [selectedConditionsaryi, setSelectedConditionsaryi] = useState("");
   const [minBedsaryi, setMinBedsaryi] = useState('');
   const [maxBedsaryi, setMaxBedsaryi] = useState('');
   const [minSqftaryi, setMinSqftaryi] = useState('');
   const [maxSqftaryi, setMaxSqftaryi] = useState('');
 
   const [isDropdownVisible, setIsDropdownVisible] = useState(false);
   const [typeisDropdownVisible, typeDropdownVisible] = useState(false);
   const [condisDropdownVisible, condDropdownVisible] = useState(false);
   const [bedisDropdownVisible, bedDropdownVisible] = useState(false);
   const [sqmisDropdownVisible, sqmDropdownVisible] = useState(false);
   const [regisDropdownVisible, regDropdownVisible] = useState(false);
   // hr code off

  // for buy 
  const [aggregatedDataForBuy, setAggregatedDataForBuy] = useState({});
  const [hideRowsBuy , setHideRowsBuy] = useState(false);
  const [selectedLocationForBuy, setSelectedLocationForBuy] = useState('');

  // for annual rent data 
  const [selectedLocationAnnualRent, setSelectedLocationAnnualRent] = useState('');
  const [hideRowsAnnualRent, setHideRowsAnnualRent] = useState(false);   // 3rd block on chnage hide
  
// for yield 
const [selectedLocationForYield, setSelectedLocationForYield] = useState('');
const [hideRowsYield, setHideRowsAnnualYield] = useState(false);   // 4th block on chnage hide
const [yieldData, setYieldData] = useState(null);
const [username, setUsername] = useState(null);

  //default values

  const default_market = 'Paphos';
  const default_region = 'Paphos';
  const host = window.location.hostname;

    // Register Popup
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const openPopup = () => {
      setIsPopupOpen(true);
    };

    const openalertpopup = () => {
      const popupDiv = document.getElementById('spa-popup');
      if (popupDiv) {
        popupDiv.style.display = 'block'; // Change display to 'block' to make it visible
      }
    };
    const closepopupspa = () => {
      const popupDiv = document.getElementById('spa-popup');
      if (popupDiv) {
        popupDiv.style.display = 'none'; // Change display to 'block' to make it visible
      }
    };
    const openalertpopuprpa = () => {
      const popupDiv = document.getElementById('rpa-popup');
      if (popupDiv) {
        popupDiv.style.display = 'block'; // Change display to 'block' to make it visible
      }
    };
    const closepopuprpa = () => {
      const popupDiv = document.getElementById('rpa-popup');
      if (popupDiv) {
        popupDiv.style.display = 'none'; // Change display to 'block' to make it visible
      }
    };
    const openalertpopupari = () => {
      const popupDiv = document.getElementById('ari-popup');
      if (popupDiv) {
        popupDiv.style.display = 'block'; // Change display to 'block' to make it visible
      }
    };
    const closepopupari = () => {
      const popupDiv = document.getElementById('ari-popup');
      if (popupDiv) {
        popupDiv.style.display = 'none'; // Change display to 'block' to make it visible
      }
    };
    const openalertpopuparyi = () => {
      const popupDiv = document.getElementById('aryi-popup');
      if (popupDiv) {
        popupDiv.style.display = 'block'; // Change display to 'block' to make it visible
      }
    };
    const closepopuparyi = () => {
      const popupDiv = document.getElementById('aryi-popup');
      if (popupDiv) {
        popupDiv.style.display = 'none'; // Change display to 'block' to make it visible
      }
    };
  
  
    const closePopup = () => {
      setIsPopupOpen(false);
    };

  // Fetch locations and market data from the backend
const fetchLocations = async () => {
    try {
      const response = await axios.get(`https://${host}:5000/api/market-data/${default_market}/${default_region}`);
      console.log(`https://${host}:5000/api/market-data/${default_market}/${default_region}`);
  
      // Filter market data for 'rent' and 'buy' categories
      const filteredMarketDataRent = response.data.marketData.filter(item => item.buy_rent_category === 'rent');
      const filteredMarketDataBuy = response.data.marketData.filter(item => item.buy_rent_category === 'buy');
  
      // Set the filtered market data for rent and buy
      setMarketData({
        rent: filteredMarketDataRent,
        buy: filteredMarketDataBuy
      });
  
      // Filter locations based on the market data for 'rent' category
      const filteredLocationsRent = response.data.allLocations.filter(location =>
        filteredMarketDataRent.some(item => item.location === location.location)
      );
  
      // Remove duplicates and ensure unique locations for rent
      const uniqueLocationsRent = Array.from(new Set(filteredLocationsRent.map(location => location.location)))
        .map(location => {
          return filteredLocationsRent.find(item => item.location === location);
        });
  
      // Filter locations based on the market data for 'buy' category
      const filteredLocationsBuy = response.data.allLocations.filter(location =>
        filteredMarketDataBuy.some(item => item.location === location.location)
      );
  
      // Remove duplicates and ensure unique locations for buy
      const uniqueLocationsBuy = Array.from(new Set(filteredLocationsBuy.map(location => location.location)))
        .map(location => {
          return filteredLocationsBuy.find(item => item.location === location);
        });
  
      // Set the locations for both rent and buy categories
      setPropertyLocation({
        rent: uniqueLocationsRent,
        buy: uniqueLocationsBuy
      });
  
      // Calculate aggregated data for 'rent' properties
      calculateAggregatedData(response.data.marketData);
    } catch (err) {
      console.error('Error fetching property data:', err);
      setError('There was an error fetching the property data');
    } finally {
      setLoading(false);
    }
  };
  
  // Fetch data when the component is first mounted
  useEffect(() => {
    fetchLocations();
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);  // Set the username if found
      console.log('Username from sessionStorage limassol file:', storedUsername); // Log it for debugging
    } else {
      console.log('No username found in sessionStorage limassol file'); // Log if no username is found
    }
  }, []); // This only runs once when the component mounts
  // Function to calculate aggregated data (count, total, average, annual rent)
 const calculateAggregatedData = (data) => {
  // Filter only 'rent' properties
  const filteredData = data.filter(item => item.buy_rent_category === 'rent');
  
  // Sort the filtered data by the number of bedrooms in ascending order (1, 2, 3)
  const sortedData = filteredData.sort((a, b) => parseInt(a.location) - parseInt(b.location));

  // Initialize global aggregation for all bedrooms (1, 2, 3) combined
  const globalAggregation = { 1: { count: 0, totalPrice: 0 }, 2: { count: 0, totalPrice: 0 }, 3: { count: 0, totalPrice: 0 }, total: { count: 0, totalPrice: 0, totalArea: 0 }};

  const aggregated = sortedData.reduce((acc, item) => {
      const { location, bedrooms, price, cost_per_square_meter } = item;

      // Validate the data
      if (!location || !bedrooms || !price || !cost_per_square_meter) {
          console.warn("Invalid data detected", item);
          return acc;
      }

      // Initialize location object if it doesn't exist
      if (!acc[location]) {
          acc[location] = { 1: { count: 0, totalPrice: 0 }, 2: { count: 0, totalPrice: 0 }, 3: { count: 0, totalPrice: 0 }, 4: { count: 0, totalArea: 0, pricePerM2: 0 }};
      }

      // Ensure the price and cost_per_square_meter are valid numbers
      const validPrice = parseFloat(price.replace(/[^0-9.-]+/g, ''));  // Clean up any non-numeric characters
      const validCostPerM2 = parseFloat(cost_per_square_meter.replace(/[^0-9.-]+/g, ''));
      if (isNaN(validPrice) || isNaN(validCostPerM2)) {
          console.warn("Invalid price or cost_per_square_meter detected for", item);
          return acc;
      }

      // Accumulate data based on the number of bedrooms
      if (bedrooms === '1') {
          acc[location][1].count += 1;
          acc[location][1].totalPrice += validPrice;
          globalAggregation[1].count += 1;
          globalAggregation[1].totalPrice += validPrice;
      } else if (bedrooms === '2') {
          acc[location][2].count += 1;
          acc[location][2].totalPrice += validPrice;
          globalAggregation[2].count += 1;
          globalAggregation[2].totalPrice += validPrice;
      } else if (bedrooms === '3') {
          acc[location][3].count += 1;
          acc[location][3].totalPrice += validPrice;
          globalAggregation[3].count += 1;
          globalAggregation[3].totalPrice += validPrice;
      }

      // Accumulate global total area
      acc[location][4].count += 1;
      acc[location][4].totalArea += validCostPerM2;

      // Calculate price per square meter
      if (acc[location][4].count > 0) {
          acc[location][4].pricePerM2 = `${Intl.NumberFormat().format(Math.round(acc[location][4].totalArea / acc[location][4].count))} m²`;
      }

      // Update global total area
      globalAggregation.total.count += 1;
      globalAggregation.total.totalPrice += validPrice;
      globalAggregation.total.totalArea += validCostPerM2;

      return acc;
  }, {});

  // Format values and calculate average price, price per m², and annual rent for each bedroom type
  for (const location in aggregated) {
      for (const bedrooms in aggregated[location]) {
          const count = aggregated[location][bedrooms].count;
          const totalPrice = aggregated[location][bedrooms].totalPrice;
          const totalArea = aggregated[location][bedrooms].totalArea;
          
          if (count > 0) {
              aggregated[location][bedrooms].averagePrice = `€${Intl.NumberFormat().format(Math.round(totalPrice / count))}`;
              aggregated[location][bedrooms].annualRent = `€${Intl.NumberFormat().format(Math.round((totalPrice / count) * 12))}`;
          } else {
              aggregated[location][bedrooms].averagePrice = 'Not Enough Data';
              aggregated[location][bedrooms].annualRent = 'Not Enough Data';
          }
      }
  }

  // Add global aggregation data with the "Paphos(Avg)" key
  aggregated["Paphos(Avg)"] = {
      1: { count: globalAggregation[1].count, totalPrice: globalAggregation[1].totalPrice },
      2: { count: globalAggregation[2].count, totalPrice: globalAggregation[2].totalPrice },
      3: { count: globalAggregation[3].count, totalPrice: globalAggregation[3].totalPrice },
      total: { count: globalAggregation.total.count, totalPrice: globalAggregation.total.totalPrice, totalArea: globalAggregation.total.totalArea, pricePerM2: `${Intl.NumberFormat().format(Math.round(globalAggregation.total.totalArea / globalAggregation.total.count))} m²` }
  };

  // Format the global aggregated data under "Paphos(Avg)"
  for (const bedrooms in aggregated["Paphos(Avg)"]) {
      const count = aggregated["Paphos(Avg)"][bedrooms].count;
      const totalPrice = aggregated["Paphos(Avg)"][bedrooms].totalPrice;
      const totalArea = aggregated["Paphos(Avg)"][bedrooms].totalArea;

      if (count > 0) {
          aggregated["Paphos(Avg)"][bedrooms].averagePrice = `€${Intl.NumberFormat().format(Math.round(totalPrice / count))}`;
          aggregated["Paphos(Avg)"][bedrooms].annualRent = `€${Intl.NumberFormat().format(Math.round((totalPrice / count) * 12))}`;
      } else {
          aggregated["Paphos(Avg)"][bedrooms].averagePrice = 'Not Enough Data';
          aggregated["Paphos(Avg)"][bedrooms].annualRent = 'Not Enough Data';
      }
  }

  // Sort the locations to ensure 'Paphos' comes first
  const sortedAggregated = Object.keys(aggregated).sort((a, b) => {
      if (a === 'Paphos(Avg)') return -1;
      if (b === 'Paphos(Avg)') return 1;
      return a.localeCompare(b);  // Sort other locations alphabetically
  });

  // Create a new object with sorted locations
  const sortedAggregatedData = {};
  sortedAggregated.forEach(location => {
      sortedAggregatedData[location] = aggregated[location];
  });

  console.log("Aggregated Data (sorted):", sortedAggregatedData);
  setAggregatedData(sortedAggregatedData);
}; 

// Handle location selection change
  const handleLocationChange = (event) => {
    const selected = event.target.value;
    console.log('Selected Location:', selected);
    if (selected === "") {
      setHideRowsRent(false); 
    } else {
      setHideRowsRent(true); 
    }
    // Set the selected location
    setSelectedLocation(selected);
  };
// for buy property market data 

const calculateAggregatedDataForBuy = (data) => {
  // Filter only 'buy' properties
  const filteredData = data.filter(item => item.buy_rent_category === 'buy');
  
  // Sort the filtered data by the number of bedrooms in ascending order (1, 2, 3)
  const sortedData = filteredData.sort((a, b) => parseInt(a.location) - parseInt(b.location));

  // Initialize global aggregation for all bedrooms (1, 2, 3) combined
  const globalAggregation = { 1: { count: 0, totalPrice: 0 }, 2: { count: 0, totalPrice: 0 }, 3: { count: 0, totalPrice: 0 }, total: { count: 0, totalPrice: 0, totalArea: 0 }};

  const aggregated = sortedData.reduce((acc, item) => {
      const { location, bedrooms, price, cost_per_square_meter } = item;

      // Validate the data
      if (!location || !bedrooms || !price || !cost_per_square_meter) {
          console.warn("Invalid data detected", item);
          return acc;
      }

      // Initialize location object if it doesn't exist
      if (!acc[location]) {
          acc[location] = { 1: { count: 0, totalPrice: 0 }, 2: { count: 0, totalPrice: 0}, 3: { count: 0, totalPrice: 0 }, 4:{ count: 0, totalArea: 0 , pricePerM2:0}};
      }

      // Ensure the price and cost_per_square_meter are valid numbers
      const validPrice = parseFloat(price);
      const validCostPerM2 = parseFloat(cost_per_square_meter);
      if (isNaN(validPrice) || isNaN(validCostPerM2)) {
          console.warn("Invalid price or cost_per_square_meter detected for", item);
          return acc;
      }

      // Accumulate data based on the number of bedrooms
      if (bedrooms === '1') {
          acc[location][1].count += 1;
          acc[location][1].totalPrice += validPrice;
          globalAggregation[1].count += 1;
          globalAggregation[1].totalPrice += validPrice;
      } else if (bedrooms === '2') {
          acc[location][2].count += 1;
          acc[location][2].totalPrice += validPrice;
          globalAggregation[2].count += 1;
          globalAggregation[2].totalPrice += validPrice;
      } else if (bedrooms === '3') {
          acc[location][3].count += 1;
          acc[location][3].totalPrice += validPrice;
          globalAggregation[3].count += 1;
          globalAggregation[3].totalPrice += validPrice;
      }
    
      acc[location][4].count += 1;
      acc[location][4].totalArea += validCostPerM2;
      
      // Calculate price per square meter
      if (acc[location][4].count > 0) {
        acc[location][4].pricePerM2 = `${Intl.NumberFormat().format(Math.round(acc[location][4].totalArea / acc[location][4].count))} m²`;
    }
     /*  acc[location][4].pricePerM2 = `${Intl.NumberFormat().format(Math.round(acc[location][4].totalArea/acc[location][4].count))} m²`; */

       // Update global total area
       globalAggregation.total.count += 1;
       globalAggregation.total.totalPrice += validPrice;
       globalAggregation.total.totalArea += validCostPerM2;

      return acc;
  }, {});

  // Format values and calculate average price, price per m² for each bedroom type
  for (const location in aggregated) {
      for (const bedrooms in aggregated[location]) {
          const count = aggregated[location][bedrooms].count;
          const totalPrice = aggregated[location][bedrooms].totalPrice;
          
          if (count > 0) {
              // Use Intl.NumberFormat to format and round the values
              aggregated[location][bedrooms].averagePrice = `€${Intl.NumberFormat().format(Math.round(totalPrice / count))}`;
              aggregated[location][bedrooms].annualRent = `€${Intl.NumberFormat().format(Math.round((totalPrice / count) * 12))}`;
          } else {
              aggregated[location][bedrooms].averagePrice = 'Not Enough Data';
              aggregated[location][bedrooms].annualRent = 'Not Enough Data';
          }
      }
  }

  aggregated["Paphos(Avg)"] = {
    1: { count: globalAggregation[1].count, totalPrice: globalAggregation[1].totalPrice },
    2: { count: globalAggregation[2].count, totalPrice: globalAggregation[2].totalPrice },
    3: { count: globalAggregation[3].count, totalPrice: globalAggregation[3].totalPrice },
    total: { count: globalAggregation.total.count, totalPrice: globalAggregation.total.totalPrice, totalArea: globalAggregation.total.totalArea, pricePerM2: `${Intl.NumberFormat().format(Math.round(globalAggregation.total.totalArea / globalAggregation.total.count))} m²` }
};

// Format the global aggregated data under "Paphos(Avg)"
for (const bedrooms in aggregated["Paphos(Avg)"]) {
    const count = aggregated["Paphos(Avg)"][bedrooms].count;
    const totalPrice = aggregated["Paphos(Avg)"][bedrooms].totalPrice;
    const totalArea = aggregated["Paphos(Avg)"][bedrooms].totalArea;

    if (count > 0) {
        aggregated["Paphos(Avg)"][bedrooms].averagePrice = `€${Intl.NumberFormat().format(Math.round(totalPrice / count))}`;
        aggregated["Paphos(Avg)"][bedrooms].annualRent = `€${Intl.NumberFormat().format(Math.round((totalPrice / count) * 12))}`;
    } else {
        aggregated["Paphos(Avg)"][bedrooms].averagePrice = 'Not Enough Data';
        aggregated["Paphos(Avg)"][bedrooms].annualRent = 'Not Enough Data';
    }
}
  // Sort the locations to ensure 'Paphos' comes first
  const sortedAggregated = Object.keys(aggregated).sort((a, b) => {
      if (a === 'Paphos(Avg)') return -1; // Paphos should come first
      if (b === 'Paphos(Avg)') return 1;  // If b is Paphos, it should come after a
      return 0; // Keep other locations in the same order
  });

  // Create a new object with sorted locations
  const sortedAggregatedData = {};
  sortedAggregated.forEach(location => {
      sortedAggregatedData[location] = aggregated[location];
  });

  console.log("Aggregated Data for Buy (sorted):", sortedAggregatedData);
  setAggregatedDataForBuy(sortedAggregatedData);
};   

const handleLocationChangeForBuy = (event) => {
  const selected = event.target.value;
  console.log('Selected Location for Buy:', selected);
  if (selected === "") {
      setHideRowsBuy(false); 
  } else {
      setHideRowsBuy(true); 
  }
  // Set the selected location for buy properties
  setSelectedLocationForBuy(selected);
};

useEffect(() => {
  if (marketData && marketData.buy) {
    calculateAggregatedDataForBuy(marketData.buy);
  }
}, [marketData]);

const handleLocationChangeAnnualRent = (event) => {
  const selected = event.target.value;
  console.log('Selected Location for Annual Rent:', selected);
  if (selected === "") {
    setHideRowsAnnualRent(false); 
} else {
  setHideRowsAnnualRent(true); 
}

  // Set the selected location for Annual Rent
  setSelectedLocationAnnualRent(selected);
};

// Function to calculate yield percentage
const calculateYield = (buyData, rentData, selectedLocation) => {
  if (!buyData[selectedLocation] || !rentData[selectedLocation]) {
    return null; // If no data is available for the location
  }

  // Parse prices and rents as floats
  const avgPrice1 = parseFloat(buyData[selectedLocation][1].averagePrice.replace('€', '').replace(/,/g, ''));
  const avgPrice2 = parseFloat(buyData[selectedLocation][2].averagePrice.replace('€', '').replace(/,/g, ''));
  const avgPrice3 = parseFloat(buyData[selectedLocation][3].averagePrice.replace('€', '').replace(/,/g, ''));

  const annualRent1 = parseFloat(rentData[selectedLocation][1].annualRent.replace('€', '').replace(/,/g, ''));
  const annualRent2 = parseFloat(rentData[selectedLocation][2].annualRent.replace('€', '').replace(/,/g, ''));
  const annualRent3 = parseFloat(rentData[selectedLocation][3].annualRent.replace('€', '').replace(/,/g, ''));

  // Calculate yields
  const yield1 = avgPrice1 && annualRent1 ? (annualRent1 / avgPrice1) * 100 : null;
  const yield2 = avgPrice2 && annualRent2 ? (annualRent2 / avgPrice2) * 100 : null;
  const yield3 = avgPrice3 && annualRent3 ? (annualRent3 / avgPrice3) * 100 : null;

  const formattedyield1 = (yield1) ? `${Intl.NumberFormat().format(Math.round(yield1))}%` : 'Not Enough Data';
const formattedyield2 = ( yield2) ? `${Intl.NumberFormat().format(Math.round(yield2))}%` : 'Not Enough Data';
const formattedyield3 = ( yield3) ? `${Intl.NumberFormat().format(Math.round(yield3))}%` : 'Not Enough Data';

  return { formattedyield1, formattedyield2, formattedyield3 };
};
const handleLocationChangeForYield = (event) => {
  const selected = event.target.value;
  console.log('Selected Location for Annual Rent:', selected);
  if (selected === "") {
    setHideRowsAnnualYield(false); 
} else {
  setHideRowsAnnualYield(true); 
}

  setSelectedLocationForYield(event.target.value);
};


// Calculate yields for all locations on initial load
useEffect(() => {
  const allYields = {};
  Object.keys(aggregatedDataForBuy).forEach((location) => {
    const yieldDataForLocation = calculateYield(aggregatedDataForBuy, aggregatedData, location);
    if (yieldDataForLocation) {
      allYields[location] = yieldDataForLocation;
    }
  });
  setYieldData(allYields);

  // Set the initial location (can be the first available location)
  const initialLocation = Object.keys(aggregatedDataForBuy)[0];
  //setSelectedLocationForYield(initialLocation);
}, [aggregatedDataForBuy, aggregatedData]);

// Recalculate yield when the selected location changes
useEffect(() => {
  if (selectedLocationForYield) {
    const calculatedYield = calculateYield(aggregatedDataForBuy, aggregatedData, selectedLocationForYield);
    setYieldData((prevData) => ({
      ...prevData,
      [selectedLocationForYield]: calculatedYield,
    }));
  }
}, [selectedLocationForYield, aggregatedDataForBuy, aggregatedData]);


// hr code


useEffect(() => {
  fetch(`https://${host}:5000/api/filtermarket`)
    .then(response => response.json()) // Parse the JSON response
    .then(data => {
     // console.log('Fetched data:', data);
      setApiData(data);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
}, []);

  // Filter properties where region is "Limassol"
const allProperties = apiData.filter(property => property.region && property.region === "Paphos");

// Get unique property types only for the "Limassol" region
const uniquePropertyTypes = [...new Set(allProperties.map(property => property.property_type))];
const uniquePropertycondition = [...new Set(allProperties.map(property => property.condition_type))];


// Group locations by region
const groupedLocations = allProperties.reduce((acc, property) => {
  const { region, location } = property;
  if (!region || !location) return acc; // Ensure Region and location exist
  if (!acc[region]) {
    acc[region] = [];
  }
  if (!acc[region].includes(location)) {
    acc[region].push(location); // Add unique locations for each region
  }
  return acc;
}, {});

 // Get unique regions from groupedLocations
 const uniquePropertyRegion = Object.keys(groupedLocations);

  const handleRegionChange = (event) => {
    const { value, checked } = event.target;  
    setSelectedRegions((prevSelectedRegions) => {
      if (value) {
        if (checked) {
          return prevSelectedRegions ? `${prevSelectedRegions},${value}` : value;
        } else {
          const updatedRegions = prevSelectedRegions
            .split(",")
            .filter((region) => region !== value)
            .join(",");
          return updatedRegions;
        }
      }      
      return prevSelectedRegions;
    });
  };
  
  const handleLocationChangesale = (event) => {
    const { value, checked } = event.target;
    setSelectedLocationval((prevSelectedLocation) => {
      if (checked) {
        // When checked, add the value to the string with a separator (e.g., comma)
        return prevSelectedLocation ? `${prevSelectedLocation},${value}` : value;
      } else {
        // When unchecked, remove the value from the string
        const updatedLocations = prevSelectedLocation
          .split(",")
          .filter((loc) => loc !== value)
          .join(",");
        return updatedLocations; 
      }
    });
  }; 

  const handleTypeChange = (event) => {
    const { value, checked } = event.target;
    setSelectedTypes((prevSelectedTypes) => {
      if (value) { // Ensure the value is not empty
        if (checked) {
          // Add the value as a comma-separated string if it's not already present
          return prevSelectedTypes ? `${prevSelectedTypes},${value}` : value;
        } else {
          // Remove the value from the comma-separated string
          const updatedTypes = prevSelectedTypes
            .split(",")
            .filter((type) => type !== value)
            .join(",");
          return updatedTypes;
        }
      }
      return prevSelectedTypes; // Return unchanged if value is empty
    });
  }; 

  const handleMinPriceChange = (e) => setMinPrice(e.target.value);
  const handleMaxPriceChange = (e) => setMaxPrice(e.target.value);
  
  const handleConditionChange = (event) => {
    const { value, checked } = event.target;
  
    setSelectedConditions((prevConditions) => {
      if (value) { // Ensure the value is not empty
        if (checked) {
          // Add the value as a comma-separated string if it's not already present
          return prevConditions ? `${prevConditions},${value}` : value;
        } else {
          // Remove the value from the comma-separated string
          const updatedConditions = prevConditions
            .split(",")
            .filter((condition) => condition !== value)
            .join(",");
          return updatedConditions;
        }
      }
      return prevConditions; // Return unchanged state if value is empty
    });
  };
  
  const handleMinBedsChange = (e) => setMinBeds(e.target.value);
  const handleMaxBedsChange = (e) => setMaxBeds(e.target.value);

  const handleMinSqftChange = (e) => setMinSqft(e.target.value);
  const handleMaxSqftChange = (e) => setMaxSqft(e.target.value);

  // rpa form

  const handleRegionChangerpa = (event) => {
    const { value, checked } = event.target;  
    setSelectedRegionsrpa((prevSelectedRegions) => {
      if (value) {
        if (checked) {
          return prevSelectedRegions ? `${prevSelectedRegions},${value}` : value;
        } else {
          const updatedRegions = prevSelectedRegions
            .split(",")
            .filter((region) => region !== value)
            .join(",");
          return updatedRegions;
        }
      }      
      return prevSelectedRegions;
    });
  };
  const handleLocationChangesalerpa = (event) => {
    const { value, checked } = event.target;
    setSelectedLocationvalrpa((prevSelectedLocation) => {
      if (checked) {
        // When checked, add the value to the string with a separator (e.g., comma)
        return prevSelectedLocation ? `${prevSelectedLocation},${value}` : value;
      } else {
        // When unchecked, remove the value from the string
        const updatedLocations = prevSelectedLocation
          .split(",")
          .filter((loc) => loc !== value)
          .join(",");
        return updatedLocations; 
      }
    });
  }; 

  const handleTypeChangerpa = (event) => {
    const { value, checked } = event.target;
    setSelectedTypesrpa((prevSelectedTypes) => {
      if (value) { // Ensure the value is not empty
        if (checked) {
          // Add the value as a comma-separated string if it's not already present
          return prevSelectedTypes ? `${prevSelectedTypes},${value}` : value;
        } else {
          // Remove the value from the comma-separated string
          const updatedTypes = prevSelectedTypes
            .split(",")
            .filter((type) => type !== value)
            .join(",");
          return updatedTypes;
        }
      }
      return prevSelectedTypes; // Return unchanged if value is empty
    });
  }; 

  const handleMinPriceChangerpa = (e) => setMinPricerpa(e.target.value);
  const handleMaxPriceChangerpa = (e) => setMaxPricerpa(e.target.value);

  const handleConditionChangerpa = (event) => {
    const { value, checked } = event.target;
  
    setSelectedConditionsrpa((prevConditions) => {
      if (value) { // Ensure the value is not empty
        if (checked) {
          // Add the value as a comma-separated string if it's not already present
          return prevConditions ? `${prevConditions},${value}` : value;
        } else {
          // Remove the value from the comma-separated string
          const updatedConditions = prevConditions
            .split(",")
            .filter((condition) => condition !== value)
            .join(",");
          return updatedConditions;
        }
      }
      return prevConditions; // Return unchanged state if value is empty
    });
  };
  
  const handleMinBedsChangerpa = (e) => setMinBedsrpa(e.target.value);
  const handleMaxBedsChangerpa = (e) => setMaxBedsrpa(e.target.value);

  const handleMinSqftChangerpa = (e) => setMinSqftrpa(e.target.value);
  const handleMaxSqftChangerpa = (e) => setMaxSqftrpa(e.target.value);

  
  // ari form

  const handleRegionChangeari = (event) => {
    const { value, checked } = event.target;  
    setSelectedRegionsari((prevSelectedRegions) => {
      if (value) {
        if (checked) {
          return prevSelectedRegions ? `${prevSelectedRegions},${value}` : value;
        } else {
          const updatedRegions = prevSelectedRegions
            .split(",")
            .filter((region) => region !== value)
            .join(",");
          return updatedRegions;
        }
      }      
      return prevSelectedRegions;
    });
  };
  const handleLocationChangesaleari = (event) => {
    const { value, checked } = event.target;
    setSelectedLocationvalari((prevSelectedLocation) => {
      if (checked) {
        // When checked, add the value to the string with a separator (e.g., comma)
        return prevSelectedLocation ? `${prevSelectedLocation},${value}` : value;
      } else {
        // When unchecked, remove the value from the string
        const updatedLocations = prevSelectedLocation
          .split(",")
          .filter((loc) => loc !== value)
          .join(",");
        return updatedLocations; 
      }
    });
  }; 

  const handleTypeChangeari = (event) => {
    const { value, checked } = event.target;
    setSelectedTypesari((prevSelectedTypes) => {
      if (value) { // Ensure the value is not empty
        if (checked) {
          // Add the value as a comma-separated string if it's not already present
          return prevSelectedTypes ? `${prevSelectedTypes},${value}` : value;
        } else {
          // Remove the value from the comma-separated string
          const updatedTypes = prevSelectedTypes
            .split(",")
            .filter((type) => type !== value)
            .join(",");
          return updatedTypes;
        }
      }
      return prevSelectedTypes; // Return unchanged if value is empty
    });
  }; 

  const handleMinAnnualChange = (e) => setMinAnnual(e.target.value);
  const handleMaxAnnualChange = (e) => setMaxAnnual(e.target.value);

  const handleConditionChangeari = (event) => {
    const { value, checked } = event.target;
  
    setSelectedConditionsari((prevConditions) => {
      if (value) { // Ensure the value is not empty
        if (checked) {
          // Add the value as a comma-separated string if it's not already present
          return prevConditions ? `${prevConditions},${value}` : value;
        } else {
          // Remove the value from the comma-separated string
          const updatedConditions = prevConditions
            .split(",")
            .filter((condition) => condition !== value)
            .join(",");
          return updatedConditions;
        }
      }
      return prevConditions; // Return unchanged state if value is empty
    });
  };
  
  const handleMinBedsChangeari = (e) => setMinBedsari(e.target.value);
  const handleMaxBedsChangeari = (e) => setMaxBedsari(e.target.value);

  const handleMinSqftChangeari = (e) => setMinSqftari(e.target.value);
  const handleMaxSqftChangeari = (e) => setMaxSqftari(e.target.value);

  // aryi form
  const handleRegionChangearyi = (event) => {
    const { value, checked } = event.target;  
    setSelectedRegionsaryi((prevSelectedRegions) => {
      if (value) {
        if (checked) {
          return prevSelectedRegions ? `${prevSelectedRegions},${value}` : value;
        } else {
          const updatedRegions = prevSelectedRegions
            .split(",")
            .filter((region) => region !== value)
            .join(",");
          return updatedRegions;
        }
      }      
      return prevSelectedRegions;
    });
  };
  const handleLocationChangesalearyi = (event) => {
    const { value, checked } = event.target;
    setSelectedLocationvalaryi((prevSelectedLocation) => {
      if (checked) {
        // When checked, add the value to the string with a separator (e.g., comma)
        return prevSelectedLocation ? `${prevSelectedLocation},${value}` : value;
      } else {
        // When unchecked, remove the value from the string
        const updatedLocations = prevSelectedLocation
          .split(",")
          .filter((loc) => loc !== value)
          .join(",");
        return updatedLocations; 
      }
    });
  }; 

  const handleTypeChangearyi = (event) => {
    const { value, checked } = event.target;
    setSelectedTypesaryi((prevSelectedTypes) => {
      if (value) { // Ensure the value is not empty
        if (checked) {
          // Add the value as a comma-separated string if it's not already present
          return prevSelectedTypes ? `${prevSelectedTypes},${value}` : value;
        } else {
          // Remove the value from the comma-separated string
          const updatedTypes = prevSelectedTypes
            .split(",")
            .filter((type) => type !== value)
            .join(",");
          return updatedTypes;
        }
      }
      return prevSelectedTypes; // Return unchanged if value is empty
    });
  }; 

  
  const handleMinYieldChange = (e) => setMinYield(e.target.value);
  const handleMaxYieldChange = (e) => setMaxAYield(e.target.value);

  const handleConditionChangearyi = (event) => {
    const { value, checked } = event.target;
  
    setSelectedConditionsaryi((prevConditions) => {
      if (value) { // Ensure the value is not empty
        if (checked) {
          // Add the value as a comma-separated string if it's not already present
          return prevConditions ? `${prevConditions},${value}` : value;
        } else {
          // Remove the value from the comma-separated string
          const updatedConditions = prevConditions
            .split(",")
            .filter((condition) => condition !== value)
            .join(",");
          return updatedConditions;
        }
      }
      return prevConditions; // Return unchanged state if value is empty
    });
  };
  
  const handleMinBedsChangearyi = (e) => setMinBedsaryi(e.target.value);
  const handleMaxBedsChangearyi = (e) => setMaxBedsaryi(e.target.value);

  const handleMinSqftChangearyi = (e) => setMinSqftaryi(e.target.value);
  const handleMaxSqftChangearyi = (e) => setMaxSqftaryi(e.target.value);

  
  
  
  const filterProperties = () => {
  
    if (
      selectedRegions.length === 0 &&
      selectedLocationval.length === 0 &&
      selectedTypes.length === 0 &&
      minPrice === '' &&
      maxPrice === '' &&
      selectedConditions.length === 0 &&
      minBeds === '' &&
      maxBeds === '' &&
      minSqft === '' &&
      maxSqft === '' 
    ) {
     // return properties; // Show all properties if no checkbox is selected
    }
  //  console.log('Filtering properties based on selected regions OR TYPES:');
    return allProperties.filter(property => {
      const matchesRegion = selectedRegions.length === 0 || selectedRegions.includes(property.region);
      const matchesLocation = selectedLocationval.length === 0 || selectedLocationval.includes(property.location);
      const matchesType = selectedTypes.length === 0 || selectedTypes.includes(property.property_type);
      // Check if the property price is within the selected range
      const price = parseFloat(property.price);
      const bedrooms = parseFloat(property.bedrooms);
      const costsqmeter = parseFloat(property.cost_per_square_meter);
      const matchesPrice =
        (minPrice === '' || price >= parseFloat(minPrice)) &&
        (maxPrice === '' || price <= parseFloat(maxPrice));

      const matchesCondition = selectedConditions.length === 0 || selectedConditions.includes(property.condition_type);
            
      const matchesbedrooms =
        (minBeds === '' || bedrooms >= parseFloat(minBeds)) &&
        (maxBeds === '' || bedrooms <= parseFloat(maxBeds));

      const matchescostsqmeter =
        (minSqft === '' || costsqmeter >= parseFloat(minSqft)) &&
        (maxSqft === '' || costsqmeter <= parseFloat(maxSqft));

      return matchesRegion && matchesLocation && matchesType && matchesPrice &&  matchesCondition && matchesbedrooms && matchescostsqmeter;
    });
  };
   // Apply filters and sorting
   const metchpropertyid = filterProperties();
   const metchpropertyIdsArray = metchpropertyid.map(property => property.property_id);  
   // Convert the array of property_ids to a comma-separated string
   useEffect(() => {
     if (metchpropertyIdsArray.length > 0) {
       setmetchpropertyIds(metchpropertyIdsArray.join(','));
     } else {
       setmetchpropertyIds(''); // If no properties, set as empty
     }
   }, [metchpropertyid]); // Update state when the filtered properties change

   const filterPropertiesrpa = () => {
  
    if (
      selectedRegionsrpa.length === 0 &&
      selectedLocationvalrpa.length === 0 &&
      selectedTypesrpa.length === 0 &&
      minPricerpa === '' &&
      maxPricerpa === '' &&
      selectedConditionsrpa.length === 0 &&
      minBedsrpa === '' &&
      maxBedsrpa === '' &&
      minSqftrpa === '' &&
      maxSqftrpa === '' 
    ) {
     // return properties; // Show all properties if no checkbox is selected
    }
  //  console.log('Filtering properties based on selected regions OR TYPES:');
    return allProperties.filter(property => {
      
      //rpa form filter
      const matchesRegionrpa = selectedRegionsrpa.length === 0 || selectedRegionsrpa.includes(property.region);
      const matchesLocationrpa = selectedLocationvalrpa.length === 0 || selectedLocationvalrpa.includes(property.location);
      const matchesTyperpa = selectedTypesrpa.length === 0 || selectedTypesrpa.includes(property.property_type);
      const price = parseFloat(property.price);
      const bedrooms = parseFloat(property.bedrooms);
      const costsqmeter = parseFloat(property.cost_per_square_meter);
      const matchesPricerpa =
        (minPricerpa === '' || price >= parseFloat(minPricerpa)) &&
        (maxPricerpa === '' || price <= parseFloat(maxPricerpa));
      const matchesConditionrpa = selectedConditionsrpa.length === 0 || selectedConditionsrpa.includes(property.condition_type);    
      const matchesbedroomsrpa =
        (minBedsrpa === '' || bedrooms >= parseFloat(minBedsrpa)) &&
        (maxBedsrpa === '' || bedrooms <= parseFloat(maxBedsrpa));
      const matchescostsqmeterrpa =
        (minSqftrpa === '' || costsqmeter >= parseFloat(minSqftrpa)) &&
        (maxSqftrpa === '' || costsqmeter <= parseFloat(maxSqftrpa));
             
      return matchesRegionrpa && matchesLocationrpa && matchesTyperpa &&  matchesPricerpa && matchesConditionrpa && matchesbedroomsrpa && matchescostsqmeterrpa ;
    });
  };
   // Apply filters and sorting
   const metchpropertyidrpa = filterPropertiesrpa();
   const metchpropertyIdsArrayrpa = metchpropertyidrpa.map(property => property.property_id);  
   // Convert the array of property_ids to a comma-separated string
   useEffect(() => {
     if (metchpropertyIdsArrayrpa.length > 0) {
      setmetchpropertyIdsrpa(metchpropertyIdsArrayrpa.join(','));
     } else {
      setmetchpropertyIdsrpa(''); // If no properties, set as empty
     }
   }, [metchpropertyidrpa]); // Update state when the filtered properties change


   // ari filter
   const filterPropertiesari = () => {
  
    if (
      selectedRegionsari.length === 0 &&
      selectedLocationvalari.length === 0 &&
      selectedTypesari.length === 0 &&
      minAnnual === '' &&
      maxAnnual === '' &&
      selectedConditionsari.length === 0 &&
      minBedsari === '' &&
      maxBedsari === '' &&
      minSqftari === '' &&
      maxSqftari === '' 
    ) {
     // return properties; // Show all properties if no checkbox is selected
    }
  //  console.log('Filtering properties based on selected regions OR TYPES:');
    return allProperties.filter(property => {
      const annualincome = parseFloat(property.annual_income);
      const bedrooms = parseFloat(property.bedrooms);
      const costsqmeter = parseFloat(property.cost_per_square_meter);
      
      //ari form filter
      const matchesRegionari = selectedRegionsari.length === 0 || selectedRegionsari.includes(property.region);
      const matchesLocationari = selectedLocationvalari.length === 0 || selectedLocationvalari.includes(property.location);
      const matchesTypeari = selectedTypesari.length === 0 || selectedTypesari.includes(property.property_type);
      const matchesAnnual =
        (minAnnual === '' || annualincome >= parseFloat(minAnnual)) &&
        (maxAnnual === '' || annualincome <= parseFloat(maxAnnual));
      const matchesConditionari = selectedConditionsari.length === 0 || selectedConditionsari.includes(property.condition_type);    
      const matchesbedroomsari =
        (minBedsari === '' || bedrooms >= parseFloat(minBedsari)) &&
        (maxBedsari === '' || bedrooms <= parseFloat(maxBedsari));
      const matchescostsqmeterari =
        (minSqftari === '' || costsqmeter >= parseFloat(minSqftari)) &&
        (maxSqftari === '' || costsqmeter <= parseFloat(maxSqftari));

              
      return matchesRegionari && matchesLocationari && matchesTypeari &&  matchesAnnual && matchesConditionari && matchesbedroomsari && matchescostsqmeterari ;
    });
  };
   // Apply filters and sorting
   const metchpropertyidari = filterPropertiesari();
   const metchpropertyIdsArrayari = metchpropertyidari.map(property => property.property_id);  
   // Convert the array of property_ids to a comma-separated string
   useEffect(() => {
     if (metchpropertyIdsArrayari.length > 0) {
       setmetchpropertyIdsari(metchpropertyIdsArrayari.join(','));
     } else {
       setmetchpropertyIdsari(''); // If no properties, set as empty
     }
   }, [metchpropertyidari]); 

   // aryi filter
   const filterPropertiesaryi = () => {
  
    if (
      selectedRegionsaryi.length === 0 &&
      selectedLocationvalaryi.length === 0 &&
      selectedTypesaryi.length === 0 &&
      minYield === '' &&
      maxYield === '' &&
      selectedConditionsaryi.length === 0 &&
      minBedsaryi === '' &&
      maxBedsaryi === '' &&
      minSqftaryi === '' &&
      maxSqftaryi === '' 
    ) {
     // return properties; // Show all properties if no checkbox is selected
    }
  //  console.log('Filtering properties based on selected regions OR TYPES:');
    return allProperties.filter(property => {
     
      const rentalyield = parseFloat(property.rental_yield);
      const bedrooms = parseFloat(property.bedrooms);
      const costsqmeter = parseFloat(property.cost_per_square_meter);
      
      //arYi form filter
      const matchesRegionaryi = selectedRegionsaryi.length === 0 || selectedRegionsaryi.includes(property.region);
      const matchesLocationaryi = selectedLocationvalaryi.length === 0 || selectedLocationvalaryi.includes(property.location);
      const matchesTypearyi = selectedTypesaryi.length === 0 || selectedTypesaryi.includes(property.property_type);
      const matchesYield =
        (minYield === '' || rentalyield >= parseFloat(minYield)) &&
        (maxYield === '' || rentalyield <= parseFloat(maxYield));
      const matchesConditionaryi = selectedConditionsaryi.length === 0 || selectedConditionsaryi.includes(property.condition_type);    
      const matchesbedroomsaryi =
        (minBedsaryi === '' || bedrooms >= parseFloat(minBedsaryi)) &&
        (maxBedsaryi === '' || bedrooms <= parseFloat(maxBedsaryi));
      const matchescostsqmeteraryi =
        (minSqftaryi === '' || costsqmeter >= parseFloat(minSqftaryi)) &&
        (maxSqftaryi === '' || costsqmeter <= parseFloat(maxSqftaryi));
        
      return matchesRegionaryi && matchesLocationaryi && matchesTypearyi &&  matchesYield && matchesConditionaryi && matchesbedroomsaryi && matchescostsqmeteraryi;
    });
  };
   // Apply filters and sorting
   const metchpropertyidaryi = filterPropertiesaryi();
   const metchpropertyIdsArrayaryi = metchpropertyidaryi.map(property => property.property_id);  
   // Convert the array of property_ids to a comma-separated string
   useEffect(() => {
     if (metchpropertyIdsArrayaryi.length > 0) {
       setmetchpropertyIdsaryi(metchpropertyIdsArrayaryi.join(','));
     } else {
       setmetchpropertyIdsaryi(''); // If no properties, set as empty
     }
   }, [metchpropertyidaryi]);


   
    console.log(metchpropertyid, 'metch value spa12');
  //  console.log(metchpropertyidrpa, 'metch value rpa12');
  // console.log(metchpropertyidari, 'metch value ari');
   // console.log(metchpropertyidaryi, 'metch value aryi');

  useEffect(() => {
    const sessionid = localStorage.getItem('id');  // Retrieve 'id' from sessionStorage
 //  const sessionid = '34';  // Retrieve 'id' from sessionStorage
    console.log(sessionid,'session id value');
    if (sessionid) {
      setSessionid(sessionid); // Set the sessionid state if found
    }
  }, []);



  const apiUrl = `https://${host}:5000/api/alertsavepaspa`;

  const handleSubmit = async (e) => { 
    e.preventDefault();
    if (minPrice === "" || maxPrice === "") {
      const errorDiv = document.getElementById('visible-price-spa'); 
      if (errorDiv) {
        errorDiv.style.border = '2px solid red';
      }
      return; // Prevent further form submission if validation fails
    }
    try {
      const requestData = {
        selectedRegions,
        selectedLocationval,
        selectedTypes,
        minPrice,
        maxPrice,
        selectedConditions,
        minBeds,
        maxBeds,
        minSqft,
        maxSqft,
        formType,
        metchpropertyIds,
        session_id,
      };
  
      // Make the POST request with the form data
      const response = await axios.post(apiUrl, requestData);

      if (response.data.message === 'User data updated successfully') {       
        console.log('data update limassol usermeta');
        const successDiv = document.getElementById('success_msg');
        if (successDiv) {
          successDiv.style.display = 'block'; // Change display to 'block' to make it visible
        }
      } else if (response.data.message === 'User data inserted successfully') {       
        console.log('data insert limassol usermeta');
        const successDiv = document.getElementById('success_msg');
        if (successDiv) {
          successDiv.style.display = 'block'; // Change display to 'block' to make it visible
        }
      } else {
        console.log('Failed to update user data');
        const errorDiv = document.getElementById('error_msg');
        if (errorDiv) {
          errorDiv.style.display = 'block'; // Change display to 'block' to make it visible
        }    
      }

    } catch (error) {      
      console.error('Error during form submission:', error);   
      const errorDiv = document.getElementById('error_msg');
        if (errorDiv) {
          errorDiv.style.display = 'block'; // Change display to 'block' to make it visible
        }  
    }
  };
  const apiUrlrpa = `https://${host}:5000/api/alertsaveparpa`;
  const handleSubmitrpa = async (e) => {
    e.preventDefault();
    if (minPricerpa === "" || maxPricerpa === "") {
      const errorDiv = document.getElementById('visible-price-rpa'); 
      if (errorDiv) {
        errorDiv.style.border = '2px solid red';
      }
      return; // Prevent further form submission if validation fails
    }
    try {
      const requestData = {
        selectedRegionsrpa,
        selectedLocationvalrpa,
        selectedTypesrpa,
        minPricerpa,
        maxPricerpa,
        selectedConditionsrpa,
        minBedsrpa,
        maxBedsrpa,
        minSqftrpa,
        maxSqftrpa,
        formTyperpa,
        metchpropertyIdsrpa,
        session_id,
      };
  
      // Make the POST request with the form data
      const response = await axios.post(apiUrlrpa, requestData);

      if (response.data.message === 'User data updated rpa') {       
        console.log('data update limassol usermeta rpa');
        const successDiv = document.getElementById('success_msgrpa');
        if (successDiv) {
          successDiv.style.display = 'block'; // Change display to 'block' to make it visible
        }
      } else if (response.data.message === 'User data inserted rpa') {       
        console.log('data insert limassol usermeta');
        const successDiv = document.getElementById('success_msgrpa');
        if (successDiv) {
          successDiv.style.display = 'block'; // Change display to 'block' to make it visible
        }
      } else {
        const errorDiv = document.getElementById('error_msgrpa');
        if (errorDiv) {
          errorDiv.style.display = 'block'; // Change display to 'block' to make it visible
        }    
      }

    } catch (error) {      
      console.error('Error during form submission:', error);   
      const errorDiv = document.getElementById('error_msgrpa');
        if (errorDiv) {
          errorDiv.style.display = 'block'; // Change display to 'block' to make it visible
        }  
    }
  };
  const apiUrlari = `https://${host}:5000/api/alertsavepaari`;
  const handleSubmitari = async (e) => {
    e.preventDefault();
    if (minAnnual === "" || maxAnnual === "") {
      const errorDiv = document.getElementById('visible-annual-ari'); 
      if (errorDiv) {
        errorDiv.style.border = '2px solid red';
      }
      return; // Prevent further form submission if validation fails
    }
    try {
      const requestData = {
        selectedRegionsari,
        selectedLocationvalari,
        selectedTypesari,
        minAnnual,
        maxAnnual,
        selectedConditionsari,
        minBedsari, 
        maxBedsari, 
        minSqftari,
        maxSqftari,
        formTypeari,
        metchpropertyIdsari,
        session_id,
      };
  
      // Make the POST request with the form data
      const response = await axios.post(apiUrlari, requestData);

      if (response.data.message === 'User data updated ari') {           
        console.log('data update limassol usermeta ari');
        const successDiv = document.getElementById('success_msgari');
        if (successDiv) {
          successDiv.style.display = 'block'; // Change display to 'block' to make it visible
        }
      }else if (response.data.message === 'User data inserted ari') {         
        console.log('data update limassol usermeta ari');
        const successDiv = document.getElementById('success_msgari');
        if (successDiv) {
          successDiv.style.display = 'block'; // Change display to 'block' to make it visible
        }
      } else {  
        const errorDiv = document.getElementById('error_msgari');
        if (errorDiv) {
          errorDiv.style.display = 'block'; // Change display to 'block' to make it visible
        }    
      }

    } catch (error) {      
      console.error('Error during form submission:', error);   
      const errorDiv = document.getElementById('error_msgari');
        if (errorDiv) {
          errorDiv.style.display = 'block'; // Change display to 'block' to make it visible
        }  
    }
  };
  const apiUrlaryi = `https://${host}:5000/api/alertsavepaaryi`;
  const handleSubmitaryi = async (e) => {
    e.preventDefault();
    if (minYield === "" || maxYield === "") {
      const errorDiv = document.getElementById('visible-yield-aryi'); 
      if (errorDiv) {
        errorDiv.style.border = '2px solid red';
      }
      return; // Prevent further form submission if validation fails
    }
    try {
      const requestData = {
        selectedRegionsaryi,
        selectedLocationvalaryi,
        selectedTypesaryi,
        minYield,
        maxYield,
        selectedConditionsaryi,
        minBedsaryi,
        maxBedsaryi,
        minSqftaryi,
        maxSqftaryi,
        formTypearyi,
        metchpropertyIdsaryi,
        session_id,
      };
  
      // Make the POST request with the form data
      const response = await axios.post(apiUrlaryi, requestData);

      if (response.data.message === 'User data updated ary') {        
        console.log('data update limassol usermeta');
        const successDiv = document.getElementById('success_msgaryi');
        if (successDiv) {
          successDiv.style.display = 'block'; // Change display to 'block' to make it visible
        }
      } else if (response.data.message === 'User data inserted ary') {        
        console.log('data update limassol usermeta');
        const successDiv = document.getElementById('success_msgaryi');
        if (successDiv) {
          successDiv.style.display = 'block'; // Change display to 'block' to make it visible
        }
      } else {  
        const errorDiv = document.getElementById('success_msgaryi');
        if (errorDiv) {
          errorDiv.style.display = 'block'; // Change display to 'block' to make it visible
        }    
      }

    } catch (error) {      
      console.error('Error during form submission:', error);   
      const errorDiv = document.getElementById('success_msgaryi');
        if (errorDiv) {
          errorDiv.style.display = 'block'; // Change display to 'block' to make it visible
        }  
    }
  };


  const toggleDropdown = () => {
    setIsDropdownVisible((prevState) => !prevState); // Toggle the visibility of the dropdown
  };
  const toggleDropdowntype = () => {
    typeDropdownVisible((prevState) => !prevState); // Toggle the visibility of the dropdown
  };
  const toggleDropdowncond = () => {
    condDropdownVisible((prevState) => !prevState); // Toggle the visibility of the dropdown
  };
  const toggleDropdowncondbeds = () => {
    bedDropdownVisible((prevState) => !prevState); // Toggle the visibility of the dropdown
  };
  const toggleDropdowncondsqm = () => {
    sqmDropdownVisible((prevState) => !prevState); // Toggle the visibility of the dropdown
  };
  const toggleDropdownreg = () => {
    regDropdownVisible((prevState) => !prevState); // Toggle the visibility of the dropdown
  };

  const apiUrl1 = `https://${host}:5000/api/form-paphos-userval`;

const fetchData = async () => { // Adding async here

  const requestData = {
    session_id,
  };
  try {
    // Use POST instead of GET
    const response = await axios.post(apiUrl1, requestData);

    if (response.data.token) {
      console.log('data get');
      console.log(response.data.user.regionval, 'region value');
      setSelectedRegions(response.data.user.regionval);
      setSelectedTypes(response.data.user.type);
      setSelectedLocationval(response.data.user.location);
      setMinPrice(response.data.user.minprice);
      setMaxPrice(response.data.user.maxprice);
      setSelectedConditions(response.data.user.condition);
      setMinBeds(response.data.user.minbed);
      setMaxBeds(response.data.user.maxbed);
      setMinSqft(response.data.user.minsqm);
      setMaxSqft(response.data.user.maxsqm);

      setSelectedRegionsrpa(response.data.user.regionvalrpa);
      setSelectedTypesrpa(response.data.user.typerpa);
      setSelectedLocationvalrpa(response.data.user.locationrpa);
      setMinPricerpa(response.data.user.minpricerpa);
      setMaxPricerpa(response.data.user.maxpricerpa);
      setSelectedConditionsrpa(response.data.user.conditionrpa);
      setMinBedsrpa(response.data.user.minbedrpa);
      setMaxBedsrpa(response.data.user.maxbedrpa);
      setMinSqftrpa(response.data.user.minsqmrpa);
      setMaxSqftrpa(response.data.user.maxsqmrpa);

      setSelectedRegionsari(response.data.user.regionvalari);
      setSelectedTypesari(response.data.user.typeari);
      setSelectedLocationvalari(response.data.user.locationari);
      setMinAnnual(response.data.user.minAnnual);
      setMaxAnnual(response.data.user.maxAnnual);
      setSelectedConditionsari(response.data.user.conditionari);
      setMinBedsari(response.data.user.minbedari);
      setMaxBedsari(response.data.user.maxbedari);
      setMinSqftari(response.data.user.minsqmari);
      setMaxSqftari(response.data.user.maxsqmari);

      setSelectedRegionsaryi(response.data.user.regionvalaryi);
      setSelectedTypesaryi(response.data.user.typearyi);
      setSelectedLocationvalaryi(response.data.user.locationaryi);
      setMinYield(response.data.user.minYield);
      setMaxAYield(response.data.user.maxYield);
      setSelectedConditionsaryi(response.data.user.conditionaryi);
      setMinBedsaryi(response.data.user.minbedaryi);
      setMaxBedsaryi(response.data.user.maxbedaryi);
      setMinSqftaryi(response.data.user.minsqmaryi);
      setMaxSqftaryi(response.data.user.maxsqmaryi);


    } else {
      console.log('data failed');
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

useEffect(() => {
  // Delay execution by 5 seconds
  const timer = setTimeout(() => {
    fetchData();  // Fetch data after 5 seconds
  }, 5000); // 5000 milliseconds = 5 seconds

  // Cleanup the timer when the component unmounts or before running the effect again
  return () => clearTimeout(timer);
}, [session_id]);



  if (loading) {
    return <div>{loading && <Loader />}</div>;
  }

  return (
    <div className="property-detail">
       <Helmet>
        <title>Market-Paphos – Property One</title>
        <meta name="title" content="Real Estate in Paphos | Properties for Sale & Rent"></meta>
        <meta name="description" content="Find your dream property in Paphos with PropertyOne. Explore listings for sale and rent in this historic city."></meta>
    </Helmet>
      <Header activeMarket="paphos" />

      <div
        className="banner-header"
        style={{ backgroundImage: 'url(/images/market-paphos.jpg)' }}
      >
        <h1>Current Market Insights {default_market}</h1>
      </div>

      {/* Rent Section */}
      <div className="insight-repeater-sec rent">
        <div className="container">
          <h2>Current AVG Rental Pricing</h2>

          {/* Location Select Dropdown for Rent */}
          {!username ? (<div className="limassol-location"></div>):(
             <div className="limassol-location">
                <select
                  className="location-select select2 select2-hidden-accessible"
                  name="location_rent"
                  value={selectedLocation} // Set a state variable for selected location here, if needed
                  onChange={handleLocationChange} // Call the function to handle location selection change
                >
                  <option value="">Select a location</option>
                  {/* Dynamically populate dropdown options from propertyLocation.rent */}
                  {propertyLocation.rent.map((locationObj, index) => (
                    <option key={index} value={locationObj.location}>
                      {locationObj.location}
                    </option>
                  ))}
                </select>
             </div>
          )}

          {/* Table for Rent Market Data */}
          <div className="insight-table-main">
                <table className="rent_list">
                  <thead>
                    <tr>
                      <th>Location</th>
                      <th>1 Bed Rent Price</th>
                      <th>2 Bed Rent Price</th>
                      <th>3 Bed Rent Price</th>
                      <th>Price Per m²</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Loop through the first 4 locations in the aggregated data */}
                   {Object.keys(aggregatedData).slice(0, 4).map((loc, index) => (
                    !hideRowsRent || (index !== 1 && index !== 2 && index !== 3 && index !== 4) ? (
                      <tr key={loc} className="table-row-rent" id={`row-${index}`}>
                        <td>{loc}</td>
                        
                        {/* Average Price for Bedrooms 1, 2, 3 */}
                        <td>{aggregatedData[loc][1].averagePrice}</td>
                        <td>{aggregatedData[loc][2].averagePrice}</td>
                        <td>{aggregatedData[loc][3].averagePrice}</td>

                        {/* Conditional rendering for pricePerM2 */}
                        <td>
                          {loc === "Paphos(Avg)"
                            ? aggregatedData[loc]["total"].pricePerM2 // For Paphos(Avg), use the 'total' key
                            : aggregatedData[loc][4].pricePerM2  // For other locations, use the regular location data
                          }
                        </td>
                      </tr>
                    ) : null
                  ))}


                     {/* Filter the aggregated data to only show Paphos and selected location */}
                    {Object.keys(aggregatedData).filter(location => location === selectedLocation).map((location, index) => (
                      <tr key={location} className="table-row-rent" id={`row-${index}`}>
                        <td>{location}</td>
                        <td>{aggregatedData[location][1].averagePrice}</td>
                        <td>{aggregatedData[location][2].averagePrice}</td>
                        <td>{aggregatedData[location][3].averagePrice}</td>
                        <td>{aggregatedData[location][4].pricePerM2}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
            </div>
            <ul className="insight-button-list">
             <li className="insight-register-btn" style={{ display: session_id ? 'none' : 'block' }}>
           <a onClick={openPopup} className="register-popup-form">
                Register To View All Towns
          </a>
         </li>
            <li>
              <a className="rpa-popup-form" onClick={session_id ? openalertpopup : openPopup}>
                Get Rental Price Alert
              </a>
            </li>
          </ul>
          {isPopupOpen && (
        <Popup closePopup={closePopup} />
      )}
       <div className='spa-popup-form-alert alert-popup' id="spa-popup" style={{ display: 'none' }}>
            <div className="filter-sec">
              <div className="container">
                <h4 id="form_heading">
                  Request Rent Price Alert
                  <span id="close-spa-popup-alert-form" onClick={closepopupspa}><i className="fa-solid fa-xmark"></i></span>
                </h4>

                <form onSubmit={handleSubmit} className="filterform spa-form" id="spa_form">
                <input type="hidden" value={formType} id="spa_form_type" className="spa_form_type" />
                  <div className="location-column">
                    <div className="visible-location selected-visible"  onClick={toggleDropdownreg}>
                    <i className="fa fa-map-pin"></i>
                      <input type="text" id="region_location" placeholder="Locations" value={`${selectedRegions} ${selectedLocationval}`} disabled/>
                    </div>
                    <div className="location-toggle select-details" style={{ display: regisDropdownVisible ? 'block' : 'none' }}>
                      <ul className="location-list">
                        {uniquePropertyRegion.map((propertyRegion) => (
                          <li key={propertyRegion} className={`${propertyRegion}-reg1`}>
                            <input type="checkbox" className="regionval" value={propertyRegion} id={`region-${propertyRegion}`} onChange={handleRegionChange} checked={selectedRegions.includes(propertyRegion)} />
                            <span>{propertyRegion}</span>
        
                            {/* Nested locations for each region */}
                            {selectedRegions.includes(propertyRegion) && (
                              <ul className={`${propertyRegion}-locations`}>
                                {groupedLocations[propertyRegion].map((propertyLocation) => (
                                  <li key={propertyLocation}>
                                    <input type="checkbox" className="locationval" value={propertyLocation} id={`location-${propertyLocation}`} onChange={handleLocationChangesale} checked={selectedLocationval.includes(propertyLocation)} />
                                    <span>{propertyLocation}</span>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="type-column">
                    <div className="visible-type selected-visible" onClick={toggleDropdowntype}>
                      <input type="text" id="type" placeholder="Type" value={selectedTypes} onChange={handleTypeChange} disabled />
                      <i className="fa fa-chevron-down"></i>
                    </div>  
                    <div className="type-toggle select-details" style={{ display: typeisDropdownVisible ? 'block' : 'none' }}>              
                      <ul className="type-list">
                      {uniquePropertyTypes.map((propertyType) => (
                        <li key={propertyType}><input type="checkbox" className="typeval" value={propertyType} onChange={handleTypeChange} checked={selectedTypes.includes(propertyType)}/>
                          <span>{propertyType}</span>
                        </li>              
                      ))}
                      </ul>
                    </div>
                  </div>
                  
                  <div className="price-column">
                    <div className="visible-price selected-visible" id="visible-price-spa" onClick={toggleDropdown}>
                      <input type="text" id="pricerange" placeholder="Price"  value={`€${minPrice} - €${maxPrice}`} disabled />
                      <i className="fa fa-chevron-down"></i>
                    </div>        
                    <div className="price-toggle select-details" style={{ display: isDropdownVisible ? 'block' : 'none' }}>
                      <span>Min Price (€)</span>
                      <input type="text" name="price-min" id="pricemin" placeholder="-" value={minPrice} onChange={handleMinPriceChange} />
                      <span>Max Price (€)</span>
                      <input type="text" name="price-max" id="pricemax" placeholder="-" value={maxPrice} onChange={handleMaxPriceChange} />
                    </div>
                  </div>

                  <div className="condition-column">
                    <div className="visible-condition selected-visible" onClick={toggleDropdowncond}>
                      <input type="text" id="condition" placeholder="Condition" value={selectedConditions} disabled />
                      <i class="fa fa-chevron-down"></i>
                    </div>  
                    <div className="condition-toggle select-details" style={{ display: condisDropdownVisible ? 'block' : 'none' }}>
                      <ul className="condition-list">
                        {uniquePropertycondition.map((propertycondition) => (
                          <li key={propertycondition}><input type="checkbox" className="condval" value={propertycondition} onChange={handleConditionChange} checked={selectedConditions.includes(propertycondition)}/>
                          <span>{propertycondition}</span>
                          </li>              
                        ))}
                        </ul>
                    </div>
                  </div>

                  <div className="beds-column">
                    <div className="visible-beds selected-visible" onClick={toggleDropdowncondbeds}>
                      <input type="text" id="bedsrange" placeholder="Beds" value={`${minBeds} - ${maxBeds} Beds`} disabled /><i class="fa fa-chevron-down"></i>
                    </div>       
                    <div className="beds-toggle select-details"  style={{ display: bedisDropdownVisible ? 'block' : 'none' }}>
                      <span>Min Beds</span>
                      <input type="text" name="bed-min" id="bedmin" placeholder="-" value={minBeds} onChange={handleMinBedsChange} />
                      <span>Max Beds</span>
                      <input type="text" name="bed-max" id="bedmix" placeholder="-" value={maxBeds} onChange={handleMaxBedsChange} />
                    </div>
                  </div>

                  <div className="area-column">
                    <div className="visible-area selected-visible" onClick={toggleDropdowncondsqm}>
                      <input type="text" id="sqftrange" placeholder="SQM"  value={`${minSqft} - ${maxSqft} SQM`} disabled/>
                      <i class="fa fa-chevron-down"></i>
                    </div>
                    <div className="area-toggle select-details" style={{ display: sqmisDropdownVisible ? 'block' : 'none' }}>
                      <span>Min Sq (m<sup>2</sup>)</span>
                      <input type="text" name="sqft-min" id="sqdmin" placeholder="-" value={minSqft} onChange={handleMinSqftChange} />
                      <span>Max Sq (m<sup>2</sup>)</span>
                      <input type="text" name="sqft-max" id="sqdmix" placeholder="-" value={maxSqft} onChange={handleMaxSqftChange} />
                    </div>
                  </div>

                  <div className="spa-submit alert-spa-submit">
                    <div className="loader" style={{ display: 'none' }}>
                      <img src="../images/loader-img.gif" alt="loader" />
                    </div>
                    <input type="submit" value="Submit & Save Alerts" />
                  </div>
                </form>

                <div className="success_msg" id="success_msg" style={{ display: 'none' }}>
                  Alert Form For Rent Pricing is Successfully Submitted
                </div>
                <div className="error_msg" id="error_msg" style={{ display: 'none' }}>
                  Alert Form For Rent Pricing is not Submitted
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Buy Section */}
      <div className="insight-repeater-sec buy">
        <div className="container">
          <h2>Current AVG Sale Pricing</h2>

          {/* Location Select Dropdown for Rent */}
          {!username ? (<div className="limassol-location"></div>):(
          <div className="limassol-location">
              <select
                  className="location-select select2 select2-hidden-accessible"
                  name="location_buy"
                  value={selectedLocationForBuy} // Set a state variable for selected location here, if needed
                  onChange={handleLocationChangeForBuy} // Call the function to handle location selection change
              >
                  <option value="">Select a location</option>
                  {/* Dynamically populate dropdown options from propertyLocation.buy */}
                  {propertyLocation.buy.map((locationObj, index) => (
                      <option key={index} value={locationObj.location}>
                          {locationObj.location}
                      </option>
                  ))}
              </select>
          </div>
          )}
         {/* Table for Buy Market Data */}
            <div className="insight-table-main">
                  <table className="buy_list">
                      <thead>
                          <tr>
                              <th>Location</th>
                              <th>1 Bed Buy Price</th>
                              <th>2 Bed Buy Price</th>
                              <th>3 Bed Buy Price</th>
                              <th>Price Per m²</th>
                          </tr>
                      </thead>
                      <tbody>
                          {/* Loop through the first 4 locations in the aggregated data */}
                          {Object.keys(aggregatedDataForBuy).slice(0, 4).map((loc, index) => (
                              !hideRowsBuy || (index !== 1 && index !== 2 && index !== 3 && index !== 4) ? (
                                  <tr key={loc} className="table-row-buy" id={`row-${index}`}>
                                      <td>{loc}</td>
                                      <td>{aggregatedDataForBuy[loc][1].averagePrice}</td>
                                      <td>{aggregatedDataForBuy[loc][2].averagePrice}</td>
                                      <td>{aggregatedDataForBuy[loc][3].averagePrice}</td>
                                      <td>
                          {loc === "Paphos(Avg)"
                            ? aggregatedDataForBuy[loc]["total"].pricePerM2 // For Paphos(Avg), use the 'total' key
                            : aggregatedDataForBuy[loc][4].pricePerM2  // For other locations, use the regular location data
                          }
                        </td>
                                  </tr>
                              ) : null
                          ))}

                          {/* Filter the aggregated data to only show selected location for Buy */}
                          {Object.keys(aggregatedDataForBuy).filter(location => location === selectedLocationForBuy).map((location, index) => (
                              <tr key={location} className="table-row-buy" id={`row-${index}`}>
                                  <td>{location}</td>
                                  <td>{aggregatedDataForBuy[location][1].averagePrice}</td>
                                  <td>{aggregatedDataForBuy[location][2].averagePrice}</td>
                                  <td>{aggregatedDataForBuy[location][3].averagePrice}</td>
                                  <td>{aggregatedDataForBuy[location][4].pricePerM2}</td>
                              </tr>
                          ))}
                      </tbody>
                  </table>
              </div>

              <ul className="insight-button-list">
              <li className="insight-register-btn"  style={{ display: session_id ? 'none' : 'block' }}>
           <a onClick={openPopup} className="register-popup-form">
                Register To View All Towns
          </a>
         </li>
            <li>
              <a className="rpa-popup-form"  onClick={session_id ? openalertpopuprpa : openPopup}>
              Get Sale Price Alert
              </a>
            </li>
          </ul>
          <div className='rpa-popup-form-alert alert-popup' id="rpa-popup" style={{ display: 'none' }}>
            <div className="filter-sec">
              <div className="container">
                <h4>Request Sale Price Alert<span id="close-rpa-popup-alert-form" onClick={closepopuprpa}><i class="fa-solid fa-xmark"></i></span></h4>

                <form onSubmit={handleSubmitrpa} className="filterform spa-form" id="spa_form">
                <input type="hidden" value={formTyperpa} id="spa_form_type" className="spa_form_type" />
                  <div className="location-column">
                    <div className="visible-location selected-visible"  onClick={toggleDropdownreg}>
                    <i class="fa fa-map-pin"></i>
                      <input type="text" id="region_location" placeholder="Locations" value={`${selectedRegionsrpa} ${selectedLocationvalrpa}`} disabled/>
                    </div>
                    <div className="location-toggle select-details" style={{ display: regisDropdownVisible ? 'block' : 'none' }}>
                      <ul className="location-list">
                        {uniquePropertyRegion.map((propertyRegion) => (
                          <li key={propertyRegion} className={`${propertyRegion}-reg1`}>
                            <input type="checkbox" className="regionval" value={propertyRegion} id={`region-${propertyRegion}`} onChange={handleRegionChangerpa} checked={selectedRegionsrpa.includes(propertyRegion)} />
                            <span>{propertyRegion}</span>
        
                            {/* Nested locations for each region */}
                            {selectedRegionsrpa.includes(propertyRegion) && (
                              <ul className={`${propertyRegion}-locations`}>
                                {groupedLocations[propertyRegion].map((propertyLocation) => (
                                  <li key={propertyLocation}>
                                    <input type="checkbox" className="locationval" value={propertyLocation} id={`location-${propertyLocation}`} onChange={handleLocationChangesalerpa} checked={selectedLocationvalrpa.includes(propertyLocation)} />
                                    <span>{propertyLocation}</span>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="type-column">
                    <div className="visible-type selected-visible" onClick={toggleDropdowntype}>
                      <input type="text" id="type" placeholder="Type" value={selectedTypesrpa} onChange={handleTypeChangerpa} disabled />
                      <i class="fa fa-chevron-down"></i>
                    </div>  
                    <div className="type-toggle select-details" style={{ display: typeisDropdownVisible ? 'block' : 'none' }}>              
                      <ul className="type-list">
                      {uniquePropertyTypes.map((propertyType) => (
                        <li key={propertyType}><input type="checkbox" className="typeval" value={propertyType} onChange={handleTypeChangerpa} checked={selectedTypesrpa.includes(propertyType)}/>
                          <span>{propertyType}</span>
                        </li>              
                      ))}
                      </ul>
                    </div>
                  </div>

                  <div className="price-column">
                    <div className="visible-price selected-visible" id="visible-price-rpa" onClick={toggleDropdown}>
                      <input type="text" id="pricerange" placeholder="Price"  value={`€${minPricerpa} - €${maxPricerpa}`} disabled />
                      <i class="fa fa-chevron-down"></i>
                    </div>        
                    <div className="price-toggle select-details" style={{ display: isDropdownVisible ? 'block' : 'none' }}>
                      <span>Min Price (€)</span>
                      <input type="text" name="price-min" id="pricemin" placeholder="-" value={minPricerpa} onChange={handleMinPriceChangerpa} />
                      <span>Max Price (€)</span>
                      <input type="text" name="price-max" id="pricemax" placeholder="-" value={maxPricerpa} onChange={handleMaxPriceChangerpa} />
                    </div>
                  </div>

                  <div className="condition-column">
                    <div className="visible-condition selected-visible" onClick={toggleDropdowncond}>
                      <input type="text" id="condition" placeholder="Condition" value={selectedConditionsrpa} disabled />
                      <i class="fa fa-chevron-down"></i>
                    </div>  
                    <div className="condition-toggle select-details" style={{ display: condisDropdownVisible ? 'block' : 'none' }}>
                      <ul className="condition-list">
                        {uniquePropertycondition.map((propertycondition) => (
                          <li key={propertycondition}><input type="checkbox" className="condval" value={propertycondition} onChange={handleConditionChangerpa} checked={selectedConditionsrpa.includes(propertycondition)}/>
                          <span>{propertycondition}</span>
                          </li>              
                        ))}
                        </ul>
                    </div>
                  </div>

                  <div className="beds-column">
                    <div className="visible-beds selected-visible" onClick={toggleDropdowncondbeds}>
                      <input type="text" id="bedsrange" placeholder="Beds" value={`${minBedsrpa} - ${maxBedsrpa} Beds`} disabled /><i class="fa fa-chevron-down"></i>
                    </div>       
                    <div className="beds-toggle select-details"  style={{ display: bedisDropdownVisible ? 'block' : 'none' }}>
                      <span>Min Beds</span>
                      <input type="text" name="bed-min" id="bedmin" placeholder="-" value={minBedsrpa} onChange={handleMinBedsChangerpa} />
                      <span>Max Beds</span>
                      <input type="text" name="bed-max" id="bedmix" placeholder="-" value={maxBedsrpa} onChange={handleMaxBedsChangerpa} />
                    </div>
                  </div>

                  <div className="area-column">
                    <div className="visible-area selected-visible" onClick={toggleDropdowncondsqm}>
                      <input type="text" id="sqftrange" placeholder="SQM"  value={`${minSqftrpa} - ${maxSqftrpa} SQM`} disabled/>
                      <i class="fa fa-chevron-down"></i>
                    </div>
                    <div className="area-toggle select-details" style={{ display: sqmisDropdownVisible ? 'block' : 'none' }}>
                      <span>Min Sq (m<sup>2</sup>)</span>
                      <input type="text" name="sqft-min" id="sqdmin" placeholder="-" value={minSqftrpa} onChange={handleMinSqftChangerpa} />
                      <span>Max Sq (m<sup>2</sup>)</span>
                      <input type="text" name="sqft-max" id="sqdmix" placeholder="-" value={maxSqftrpa} onChange={handleMaxSqftChangerpa} />
                    </div>
                  </div>

                  <div className="spa-submit alert-spa-submit">
                    <div className="loader" style={{ display: 'none' }}>
                      <img src="../images/loader-img.gif" alt="loader" />
                    </div>
                    <input type="submit" value="Get Alert & Property Portfolio" />
                  </div>
                </form>

                <div className="success_msg" id="success_msgrpa" style={{ display: 'none' }}>
                  Alert Form For Sale Pricing is Successfully Submitted
                </div>
                <div className="error_msg" id="error_msgrpa" style={{ display: 'none' }}>
                  Alert Form For Sale Pricing is not Submitted
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Annual Rent Section */}
      <div className="insight-repeater-sec annual-rent">
        <div className="container">
          <h2>Current AVG Rental Annual Income</h2>

          {/* Location Select Dropdown for Rent */}
          {!username ? (<div className="limassol-location"></div>):(
          <div className="limassol-location">
            <select
              className="location-select select2 select2-hidden-accessible"
              name="location_rent"
              value={selectedLocationAnnualRent} // Bind to the state variable
              onChange={handleLocationChangeAnnualRent} // Handle change
            >
              <option value="">Select a location</option>
              {propertyLocation.rent.map((locationObj, index) => (
                <option key={index} value={locationObj.location}>
                  {locationObj.location}
                </option>
              ))}
            </select>
          </div>
          )}
          {/* Table for Rent Market Data */}
          <div className="insight-table-main">
            <table className="rent_list">
              <thead>
                <tr>
                  <th>Location</th>
                  <th>1 Bed Rent Price</th>
                  <th>2 Bed Rent Price</th>
                  <th>3 Bed Rent Price</th>
                </tr>
              </thead>
              <tbody>
                {/* Loop through the first 4 locations in the aggregated data */}
                {Object.keys(aggregatedData).slice(0, 4).map((loc, index) => (
                  (!hideRowsAnnualRent || (index !== 1 && index !== 2 && index !== 3 && index !== 4)) && (
                    <tr key={loc} className="table-row-rent" id={`row-${index}`}>
                      <td>{loc}</td>
                      <td>{aggregatedData[loc][1].annualRent}</td>
                      <td>{aggregatedData[loc][2].annualRent}</td>
                      <td>{aggregatedData[loc][3].annualRent}</td>
                    </tr>
                  )
                ))}

                {/* Filter the aggregated data to only show the selected location */}
                {selectedLocationAnnualRent && Object.keys(aggregatedData).filter(location => location === selectedLocationAnnualRent).map((location, index) => (
                  <tr key={location} className="table-row-rent" id={`row-${index}`}>
                    <td>{location}</td>
                    <td>{aggregatedData[location][1].annualRent}</td>
                    <td>{aggregatedData[location][2].annualRent}</td>
                    <td>{aggregatedData[location][3].annualRent}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <ul className="insight-button-list">
          <li className="insight-register-btn" style={{ display: session_id ? 'none' : 'block' }}>
           <a onClick={openPopup} className="register-popup-form">
                Register To View All Towns
          </a>
         </li>
            <li>
              <a className="rpa-popup-form"  onClick={session_id ? openalertpopupari : openPopup}>
              Get AVG Rental Income Alert
              </a>
            </li>
          </ul>
          <div className='ari-popup-form-alert alert-popup' id="ari-popup" style={{ display: 'none' }}>
            <div className="filter-sec">
              <div className="container">
                <h4>Request Rent Income Alert<span id="close-rpa-popup-alert-form" onClick={closepopupari}><i class="fa-solid fa-xmark"></i></span></h4>

                <form onSubmit={handleSubmitari} className="filterform spa-form" id="spa_form">
                <input type="hidden" value={formTypeari} id="spa_form_type" className="spa_form_type" />
                  <div className="location-column">
                    <div className="visible-location selected-visible"  onClick={toggleDropdownreg}>
                    <i class="fa fa-map-pin"></i>
                      <input type="text" id="region_location" placeholder="Locations" value={`${selectedRegionsari} ${selectedLocationvalari}`} disabled/>
                    </div>
                    <div className="location-toggle select-details" style={{ display: regisDropdownVisible ? 'block' : 'none' }}>
                      <ul className="location-list">
                        {uniquePropertyRegion.map((propertyRegion) => (
                          <li key={propertyRegion} className={`${propertyRegion}-reg1`}>
                            <input type="checkbox" className="regionval" value={propertyRegion} id={`region-${propertyRegion}`} onChange={handleRegionChangeari} checked={selectedRegionsari.includes(propertyRegion)} />
                            <span>{propertyRegion}</span>
        
                            {/* Nested locations for each region */}
                            {selectedRegionsari.includes(propertyRegion) && (
                              <ul className={`${propertyRegion}-locations`}>
                                {groupedLocations[propertyRegion].map((propertyLocation) => (
                                  <li key={propertyLocation}>
                                    <input type="checkbox" className="locationval" value={propertyLocation} id={`location-${propertyLocation}`} onChange={handleLocationChangesaleari} checked={selectedLocationvalari.includes(propertyLocation)} />
                                    <span>{propertyLocation}</span>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="type-column">
                    <div className="visible-type selected-visible" onClick={toggleDropdowntype}>
                      <input type="text" id="type" placeholder="Type" value={selectedTypesari} onChange={handleTypeChangeari} disabled />
                      <i class="fa fa-chevron-down"></i>
                    </div>  
                    <div className="type-toggle select-details" style={{ display: typeisDropdownVisible ? 'block' : 'none' }}>              
                      <ul className="type-list">
                      {uniquePropertyTypes.map((propertyType) => (
                        <li key={propertyType}><input type="checkbox" className="typeval" value={propertyType} onChange={handleTypeChangeari} checked={selectedTypesari.includes(propertyType)}/>
                          <span>{propertyType}</span>
                        </li>              
                      ))}
                      </ul>
                    </div>
                  </div>

                  <div className="price-column">
                    <div className="visible-price selected-visible" id="visible-annual-ari" onClick={toggleDropdown}>
                      <input type="text" id="pricerange" placeholder="Annual Income"  value={`€${minAnnual} - €${maxAnnual}`} disabled />
                      <i class="fa fa-chevron-down"></i>
                    </div>        
                    <div className="price-toggle select-details" style={{ display: isDropdownVisible ? 'block' : 'none' }}>
                      <span>Min (€)</span>
                      <input type="text" name="price-min" id="pricemin" placeholder="-" value={minAnnual} onChange={handleMinAnnualChange} />
                      <span>Max (€)</span>
                      <input type="text" name="price-max" id="pricemax" placeholder="-" value={maxAnnual} onChange={handleMaxAnnualChange} />
                    </div>
                  </div>

                  <div className="condition-column">
                    <div className="visible-condition selected-visible" onClick={toggleDropdowncond}>
                      <input type="text" id="condition" placeholder="Condition" value={selectedConditionsari} disabled />
                      <i class="fa fa-chevron-down"></i>
                    </div>  
                    <div className="condition-toggle select-details" style={{ display: condisDropdownVisible ? 'block' : 'none' }}>
                      <ul className="condition-list">
                        {uniquePropertycondition.map((propertycondition) => (
                          <li key={propertycondition}><input type="checkbox" className="condval" value={propertycondition} onChange={handleConditionChangeari} checked={selectedConditionsari.includes(propertycondition)}/>
                          <span>{propertycondition}</span>
                          </li>              
                        ))}
                        </ul>
                    </div>
                  </div>

                  <div className="beds-column">
                    <div className="visible-beds selected-visible" onClick={toggleDropdowncondbeds}>
                      <input type="text" id="bedsrange" placeholder="Beds" value={`${minBedsari} - ${maxBedsari} Beds`} disabled /><i class="fa fa-chevron-down"></i>
                    </div>       
                    <div className="beds-toggle select-details"  style={{ display: bedisDropdownVisible ? 'block' : 'none' }}>
                      <span>Min Beds</span>
                      <input type="text" name="bed-min" id="bedmin" placeholder="-" value={minBedsari} onChange={handleMinBedsChangeari} />
                      <span>Max Beds</span>
                      <input type="text" name="bed-max" id="bedmix" placeholder="-" value={maxBedsari} onChange={handleMaxBedsChangeari} />
                    </div>
                  </div>

                  <div className="area-column">
                    <div className="visible-area selected-visible" onClick={toggleDropdowncondsqm}>
                      <input type="text" id="sqftrange" placeholder="SQM"  value={`${minSqftari} - ${maxSqftari} SQM`} disabled/>
                      <i class="fa fa-chevron-down"></i>
                    </div>
                    <div className="area-toggle select-details" style={{ display: sqmisDropdownVisible ? 'block' : 'none' }}>
                      <span>Min Sq (m<sup>2</sup>)</span>
                      <input type="text" name="sqft-min" id="sqdmin" placeholder="-" value={minSqftari} onChange={handleMinSqftChangeari} />
                      <span>Max Sq (m<sup>2</sup>)</span>
                      <input type="text" name="sqft-max" id="sqdmix" placeholder="-" value={maxSqftari} onChange={handleMaxSqftChangeari} />
                    </div>
                  </div>

                  <div className="spa-submit alert-spa-submit">
                    <div className="loader" style={{ display: 'none' }}>
                      <img src="../images/loader-img.gif" alt="loader" />
                    </div>
                    <input type="submit" value="Get Alert & Property Portfolio" />
                  </div>
                </form>

                <div className="success_msg" id="success_msgari" style={{ display: 'none' }}>
                  Alert Form For AVG Rental Annual Income Pricing is Successfully Submitted
                </div>
                <div className="error_msg" id="error_msgari" style={{ display: 'none' }}>
                  Alert Form For AVG Rental Annual Income Pricing is not Submitted
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
         
        {/* Yield Calculation Section */}
        <div className="insight-repeater-sec yield">
        <div className="container">
          <h2>Average Yield Calculation</h2>

          {/* Location Select Dropdown for Yield */}
          {!username ? (<div className="limassol-location"></div>):(
          <div className="limassol-location">
            <select
              className="location-select select2 select2-hidden-accessible"
              name="location_yield"
              value={selectedLocationForYield}
              onChange={handleLocationChangeForYield}
            >
              <option value="">Select a location</option>
              {propertyLocation.buy.map((locationObj, index) => (
                <option key={index} value={locationObj.location}>
                  {locationObj.location}
                </option>
              ))}
            </select>
          </div>
          )}
          {/* Yield Table */}
          <div className="insight-table-main">
            <table className="yield_list">
              <thead>
                <tr>
                  <th>Location</th>
                  <th>1 Bed Yield</th>
                  <th>2 Bed Yield</th>
                  <th>3 Bed Yield</th>
                </tr>
              </thead>
              <tbody>
               {/* Loop through the first 4 locations for yield data */}
                      {Object.keys(yieldData).slice(0, 4).map((loc, index) => (
                        (!hideRowsYield || (index !== 1 && index !== 2 && index !== 3 && index !== 4)) && (
                          <tr key={loc} className="table-row-yield" id={`row-${index}`}>
                            <td>{loc}</td>
                            <td>{yieldData[loc]?.formattedyield1 && yieldData[loc].formattedyield1 !== 0 ? `${yieldData[loc].formattedyield1}` : 'Not Enough Data'}</td>
                            <td>{yieldData[loc]?.formattedyield2 && yieldData[loc].formattedyield2 !== 0 ? `${yieldData[loc].formattedyield2}` : 'Not Enough Data'}</td>
                            <td>{yieldData[loc]?.formattedyield3 && yieldData[loc].formattedyield3 !== 0 ? `${yieldData[loc].formattedyield3}` : 'Not Enough Data'}</td>
                          </tr>
                        )
                      ))}

                      {/* Filter the aggregated data to only show the selected location */}
                      {selectedLocationForYield && Object.keys(yieldData).filter(location => location === selectedLocationForYield).map((location, index) => (
                        <tr key={location} className="table-row-yield" >
                          <td>{location}</td>
                          <td>{yieldData[location]?.formattedyield1 && yieldData[location].formattedyield1 !== 0 ? `${yieldData[location].formattedyield1}` : 'Not Enough Data'}</td>
                          <td>{yieldData[location]?.formattedyield2 && yieldData[location].formattedyield2 !== 0 ? `${yieldData[location].formattedyield2}` : 'Not Enough Data'}</td>
                          <td>{yieldData[location]?.formattedyield3 && yieldData[location].formattedyield3 !== 0 ? `${yieldData[location].formattedyield3}` : 'Not Enough Data'}</td>
                        </tr>
                         
                      ))}

              </tbody>
            </table>
          </div>

          {/* Register & Alert buttons */}
          <ul className="insight-button-list">
          <li className="insight-register-btn" style={{ display: session_id ? 'none' : 'block' }}>
           <a onClick={openPopup} className="register-popup-form">
                Register To View All Towns
          </a>
         </li>
            <li>
              <a className="rpa-popup-form" onClick={session_id ? openalertpopuparyi : openPopup}>
               Get Yield % Alert
              </a>
            </li>
          </ul>
          <div className='aryi-popup-form-alert alert-popup' id="aryi-popup" style={{ display: 'none' }}>
            <div className="filter-sec">
              <div className="container">
                <h4>Request Rent Yield% Alert
                  <span id="close-rpa-popup-alert-form" onClick={closepopuparyi}><i class="fa-solid fa-xmark"></i></span></h4>

                <form onSubmit={handleSubmitaryi} className="filterform spa-form" id="spa_form">
                <input type="hidden" value={formTypearyi} id="spa_form_type" className="spa_form_type" />
                  <div className="location-column">
                    <div className="visible-location selected-visible"  onClick={toggleDropdownreg}>
                    <i class="fa fa-map-pin"></i>
                      <input type="text" id="region_location" placeholder="Locations" value={`${selectedRegionsaryi} ${selectedLocationvalaryi}`} disabled/>
                    </div>
                    <div className="location-toggle select-details" style={{ display: regisDropdownVisible ? 'block' : 'none' }}>
                      <ul className="location-list">
                        {uniquePropertyRegion.map((propertyRegion) => (
                          <li key={propertyRegion} className={`${propertyRegion}-reg1`}>
                            <input type="checkbox" className="regionval" value={propertyRegion} id={`region-${propertyRegion}`} onChange={handleRegionChangearyi} checked={selectedRegionsaryi.includes(propertyRegion)} />
                            <span>{propertyRegion}</span>
        
                            {/* Nested locations for each region */}
                            {selectedRegionsaryi.includes(propertyRegion) && (
                              <ul className={`${propertyRegion}-locations`}>
                                {groupedLocations[propertyRegion].map((propertyLocation) => (
                                  <li key={propertyLocation}>
                                    <input type="checkbox" className="locationval" value={propertyLocation} id={`location-${propertyLocation}`} onChange={handleLocationChangesalearyi} checked={selectedLocationvalaryi.includes(propertyLocation)} />
                                    <span>{propertyLocation}</span>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="type-column">
                    <div className="visible-type selected-visible" onClick={toggleDropdowntype}>
                      <input type="text" id="type" placeholder="Type" value={selectedTypesaryi} onChange={handleTypeChange} disabled />
                      <i class="fa fa-chevron-down"></i>
                    </div>  
                    <div className="type-toggle select-details" style={{ display: typeisDropdownVisible ? 'block' : 'none' }}>              
                      <ul className="type-list">
                      {uniquePropertyTypes.map((propertyType) => (
                        <li key={propertyType}><input type="checkbox" className="typeval" value={propertyType} onChange={handleTypeChangearyi} checked={selectedTypesaryi.includes(propertyType)}/>
                          <span>{propertyType}</span>
                        </li>              
                      ))}
                      </ul>
                    </div>
                  </div>

                  <div className="price-column">
                    <div className="visible-price selected-visible" id="visible-yield-aryi" onClick={toggleDropdown}>
                      <input type="text" id="pricerange" placeholder="Annual Yield %"  value={`€${minYield} - €${maxYield}`} disabled />
                      <i class="fa fa-chevron-down"></i>
                    </div>        
                    <div className="price-toggle select-details" style={{ display: isDropdownVisible ? 'block' : 'none' }}>
                      <span>Min Yield %</span>
                      <input type="text" name="price-min" id="pricemin" placeholder="-" value={minYield} onChange={handleMinYieldChange} />
                      <span>Max Yield %</span>
                      <input type="text" name="price-max" id="pricemax" placeholder="-" value={maxYield} onChange={handleMaxYieldChange} />
                    </div>
                  </div>

                  <div className="condition-column">
                    <div className="visible-condition selected-visible" onClick={toggleDropdowncond}>
                      <input type="text" id="condition" placeholder="Condition" value={selectedConditionsaryi} disabled />
                      <i class="fa fa-chevron-down"></i>
                    </div>  
                    <div className="condition-toggle select-details" style={{ display: condisDropdownVisible ? 'block' : 'none' }}>
                      <ul className="condition-list">
                        {uniquePropertycondition.map((propertycondition) => (
                          <li key={propertycondition}><input type="checkbox" className="condval" value={propertycondition} onChange={handleConditionChangearyi} checked={selectedConditionsaryi.includes(propertycondition)}/>
                          <span>{propertycondition}</span>
                          </li>              
                        ))}
                        </ul>
                    </div>
                  </div>

                  <div className="beds-column">
                    <div className="visible-beds selected-visible" onClick={toggleDropdowncondbeds}>
                      <input type="text" id="bedsrange" placeholder="Beds" value={`${minBedsaryi} - ${maxBedsaryi} Beds`} disabled /><i class="fa fa-chevron-down"></i>
                    </div>       
                    <div className="beds-toggle select-details"  style={{ display: bedisDropdownVisible ? 'block' : 'none' }}>
                      <span>Min Beds</span>
                      <input type="text" name="bed-min" id="bedmin" placeholder="-" value={minBedsaryi} onChange={handleMinBedsChangearyi} />
                      <span>Max Beds</span>
                      <input type="text" name="bed-max" id="bedmix" placeholder="-" value={maxBedsaryi} onChange={handleMaxBedsChangearyi} />
                    </div>
                  </div>

                  <div className="area-column">
                    <div className="visible-area selected-visible" onClick={toggleDropdowncondsqm}>
                      <input type="text" id="sqftrange" placeholder="SQM"  value={`${minSqftaryi} - ${maxSqftaryi} SQM`} disabled/>
                      <i class="fa fa-chevron-down"></i>
                    </div>
                    <div className="area-toggle select-details" style={{ display: sqmisDropdownVisible ? 'block' : 'none' }}>
                      <span>Min Sq (m<sup>2</sup>)</span>
                      <input type="text" name="sqft-min" id="sqdmin" placeholder="-" value={minSqftaryi} onChange={handleMinSqftChangearyi} />
                      <span>Max Sq (m<sup>2</sup>)</span>
                      <input type="text" name="sqft-max" id="sqdmix" placeholder="-" value={maxSqftaryi} onChange={handleMaxSqftChangearyi} />
                    </div>
                  </div>

                  <div className="spa-submit alert-spa-submit">
                    <div className="loader" style={{ display: 'none' }}>
                      <img src="../images/loader-img.gif" alt="loader" />
                    </div>
                    <input type="submit" value="Get Alert & Property Portfolio" />
                  </div>
                </form>

                <div className="success_msg" id="success_msgaryi" style={{ display: 'none' }}>
                  Alert Form For Average Yield Pricing is Successfully Submitted
                </div>
                <div className="error_msg" id="success_msgaryi" style={{ display: 'none' }}>
                  Alert Form For Average Yield Pricing is not Submitted
                </div>
              </div>
            </div>
          </div> 
        </div>
      </div>



      <Footer />
    </div>
  );

 };

export default MarketPaphos;
