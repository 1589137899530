import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head
import './newstyle.css'; 
import Header from './Header';
import Footer from './Footer'; 
import Loader from './Loader';

const Pages = () => {
  const [pageSettings, setPageSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // Added error state to track errors
  const host = window.location.hostname; 

  useEffect(() => {
    const fetchPageSettings = async () => {
      try {
        const response = await fetch(`https://${host}:5000/api/page?page_id=2&page_title=about_us`);
        console.log(response);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`); // Handle non-2xx HTTP status
        }

        const data = await response.json();

        if (data && data.data) {
          setPageSettings(data.data);
        } else {
          throw new Error('Error: No data received or incorrect format');
        }
      } catch (error) {
        console.error('Error fetching homepage settings:', error);
        setError(error.message); // Set error message to display in UI
      } finally {
        setLoading(false); // Ensure loading is set to false after fetching
      }
    };

    fetchPageSettings();
  }, []);

  if (loading) {
    return <div>{loading && <Loader />}</div>;
  }

  if (error) {
    return <div>Error: {error}</div>; // Display error message if an error occurred
  }

  if (!pageSettings) {
    return <div>No homepage settings available.</div>;
  }

  return (
    <div>
      {/* Add head content dynamically using Helmet */}
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>About-Property One</title>
        <meta name="title" content="About PropertyOne: Leading Real Estate Experts in Cyprus"></meta>
        <meta name="description" content="Learn about PropertyOne's commitment to excellence in the Cyprus real estate market. Discover our mission, vision, and team."></meta>
        <script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.7.2/css/all.min.css"
        integrity="sha512-Evv84Mr4kqVGRNSgIGL/F/aIDqQb7xQ2vcrdIwxfjThSH8CSR7PBEakCr51Ck+w+/U6swU2Im1vVX0SVk9ABhg=="
        crossorigin="anonymous" referrerpolicy="no-referrer" />
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css"
        integrity="sha512-tS3S5qG0BlhnQROyJXvNjeEM4UpMXHrQfTGmbQ1gKmelCxlSEBUaxhRBj/EFTzpbP4RVSrpEikbmdJobCvhE3g=="
        crossorigin="anonymous" referrerpolicy="no-referrer" />
    <link rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.theme.default.min.css"
        integrity="sha512-sMXtMNL1zRzolHYKEujM2AqCLUR9F2C4/05cdbxjjLSRvMQIciEPCQZo++nk7go3BtSuK9kfa/s+a4f4i5pLkw=="
        crossorigin="anonymous" referrerpolicy="no-referrer" />
      </Helmet>
      <Header activeMarket="about" />
      {/* Render HTML sections dynamically using dangerouslySetInnerHTML */}
      {pageSettings.banner_section && (
        <div dangerouslySetInnerHTML={{ __html: pageSettings.banner_section }} />
      )}

      {pageSettings.content_section && (
        <div dangerouslySetInnerHTML={{ __html: pageSettings.content_section }} />
      )}

      {pageSettings.partners_section && (
        <div dangerouslySetInnerHTML={{ __html: pageSettings.partners_section }} />
      )}

      {pageSettings.testimonials_section && (
        <div dangerouslySetInnerHTML={{ __html: pageSettings.testimonials_section }} />
      )}

      {pageSettings.team_section && (
        <div dangerouslySetInnerHTML={{ __html: pageSettings.team_section }} />
      )}

      {/* Add scripts dynamically */}
      <Helmet>
        
      <script src="https://fast.wistia.com/embed/medias/3ebvt41ub3.jsonp" async></script>
      <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.1/jquery.min.js"
          integrity="sha512-v2CJ7UaYy4JwqLDIrZUI/4hqeoQieOmAZNXBeQyjo21dadnwR+8ZaIJVT8EE2iyI61OV8e6M8PP2/4hpQINQ/g=="
          crossorigin="anonymous"
          referrerpolicy="no-referrer"
        ></script>
      
        
      </Helmet>
      <Footer />
    </div>
  );
};

export default Pages;
