import React, { useState } from "react";
import { toast , ToastContainer } from 'react-toastify'; // Importing toast and ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Importing the default styles
import Header from './Header';
import Footer from './Footer';


const LoginPage = ({}) => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    repeatPassword: "",
    sendCredentialsViaEmail: false,
    rememberMe: false, // Added rememberMe for login form
  });

  const [submittedData, setSubmittedData] = useState(null); // Store the submitted form data here
  const [isLoginForm, setIsLoginForm] = useState(true); // Set to show login form first
  const [username, setUsername] = useState(null);
  const domainName = window.location.hostname;
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleClick = () => {
    setIsLoginForm(true); // Switch to login form when the login link is clicked
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);

    const payload = {
      username: formData.username,
      email: formData.email,
      password: formData.password,
      repeatPassword: formData.repeatPassword,
      sendCredentialsViaEmail: formData.sendCredentialsViaEmail,
      rememberMe: formData.rememberMe, // Include rememberMe in payload for login form
    };

     
    const apiUrl = isLoginForm
      ? `https://${domainName}:5000/api/user-login`  // Login API endpoint
      : `https://${domainName}:5000/api/user-register`;  // Registration API endpoint

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        console.log(`${isLoginForm ? 'Login' : 'Registration'} successful:`, data);
        setSubmittedData(data);

        // Check if login, then save token and user data to localStorage/sessionStorage
        if (isLoginForm) {
          const token = data.token;
          const user = data.user;

          // Save token and user info in localStorage or sessionStorage
          if (formData.rememberMe) {
            localStorage.setItem("authToken", token);  // Save token in localStorage
          } else {
            localStorage.setItem("authToken", token);  // Save token in sessionStorage
          }
          localStorage.setItem("user", JSON.stringify(user));  // Save user info
          
          // Redirect to homepage after login
          window.location.href = `https://${domainName}/buy`;
        }

        if (data && data.token) {
          // Store the token and username in sessionStorage
          localStorage.setItem('userToken', data.token);
          localStorage.setItem('username', data.user.username);
          localStorage.setItem('id', data.user.id);  
          localStorage.setItem('email', data.user.email);   
        }
        const storedUsername = localStorage.getItem('username');
        if (storedUsername) {
          setUsername(storedUsername);  // Set the username if found
          console.log('Username from sessionStorage user form page:', storedUsername); // Log it for debugging
        } else {
          console.log('No username found in sessionStorage user form page12'); // Log if no username is found
        }

        toast.success(`${isLoginForm ? 'Login' : 'Registration'} successful!`, {
          autoClose: 3000,
        });
        window.location.href = `https://${domainName}/buy`;
      } else {
        const errorData = await response.json();
        console.error(`${isLoginForm ? 'Login' : 'Registration'} failed:`, errorData);

        // Handle specific error messages
        if (errorData.message === 'Invalid credentials') {
          toast.error('Invalid credentials. Please check your username/email and password.', {
            autoClose: 3000,
          });
        } else if (errorData.message === 'All fields are mandatory') {
          toast.error('All fields are mandatory. Please fill in all fields.', {
            autoClose: 3000,
          });
        } else if (errorData.message === 'Passwords do not match') {
          toast.error('Passwords do not match. Please make sure both passwords are identical.', {
            autoClose: 3000,
          });
        } else if (errorData.message === 'Email already in use') {
          toast.error('This email is already in use. Please try with a different email.', {
            autoClose: 3000,
          });
        } else if (errorData.message === 'Database insertion failed') {
          toast.error('Server error occurred. Please try again later.', {
            autoClose: 3000,
          });
        } else {
          toast.error(errorData.message || `${isLoginForm ? 'Login' : 'Registration'} failed. Please try again.`, {
            autoClose: 3000,
          });
        }
      }
    } catch (error) {
      console.error('Error during form submission:', error);
      toast.error('Error occurred during submission. Please try again later.', {
        autoClose: 3000,
      });
    }
  };
//header.main-header-sec {

  return (
    <div className="login-page">
       <Header activeMarket='login' />
       <ToastContainer className="toast-container toast_pos" />
        <div className="alert-popup">
        <div className="filter-sec">
            <div className="container">
            <h4>
                {isLoginForm ? "Login" : "Register"} {/* Conditional Heading */}
            </h4>

            {/* Conditional Rendering for Login and Register Forms */}
            {!isLoginForm ? (
                // Registration Form
                <form
                encType="multipart/form-data"
                method="post"
                id="wppb-register-user"
                className="wppb-user-forms wppb-register-user"
                action={`https://${domainName}:5000/api/user-register`}
                onSubmit={handleSubmit}
                >
                <ul>
                    <li className="wppb-form-field">
                    <label htmlFor="username">
                        Name<span className="wppb-required">*</span>
                    </label>
                    <input
                        className="text-input"
                        name="username"
                        type="text"
                        id="username"
                        value={formData.username}
                        onChange={handleChange}
                        required
                    />
                    </li>

                    <li className="wppb-form-field">
                    <h4>Contact Info</h4>
                    </li>

                    <li className="wppb-form-field">
                    <label htmlFor="email">
                        E-mail<span className="wppb-required">*</span>
                    </label>
                    <input
                        className="text-input"
                        name="email"
                        type="email"
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    </li>

                    <li className="wppb-form-field">
                    <label htmlFor="password">
                        Password<span className="wppb-required">*</span>
                    </label>
                    <input
                        className="text-input"
                        name="password"
                        type="password"
                        id="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                    </li>

                    <li className="wppb-form-field">
                    <label htmlFor="repeatPassword">
                        Repeat Password<span className="wppb-required">*</span>
                    </label>
                    <input
                        className="text-input"
                        name="repeatPassword"
                        type="password"
                        id="repeatPassword"
                        value={formData.repeatPassword}
                        onChange={handleChange}
                        required
                    />
                    </li>

                    <li className="wppb-form-field">
                    <label htmlFor="sendCredentialsViaEmail">
                        <input
                        id="sendCredentialsViaEmail"
                        type="checkbox"
                        name="sendCredentialsViaEmail"
                        checked={formData.sendCredentialsViaEmail}
                        onChange={handleChange}
                        />
                        Send these credentials via email.
                    </label>
                    </li>
                </ul>

                <p className="form-submit">
                    <input
                    name="register"
                    type="submit"
                    className="submit button"
                    value="Register"
                    />
                </p>
                </form>
            ) : (
                // Login Form
                <form
                className="wppb-user-forms wppb-login-user"
                onSubmit={handleSubmit} // Using the same handleSubmit for both login and register forms
                >
                <ul>
                    <li className="wppb-form-field">
                    <label htmlFor="username-or-email">
                        Username or Email
                        <span className="wppb-required">*</span>
                    </label>
                    <input
                        className="text-input"
                        name="email"
                        type="email"
                        id="username-or-email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    </li>

                    <li className="wppb-form-field">
                    <label htmlFor="login-password">
                        Password
                        <span className="wppb-required">*</span>
                    </label>
                    <input
                        className="text-input"
                        name="password"
                        type="password"
                        id="login-password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                    </li>

                    <li className="wppb-form-field">
                    <label htmlFor="rememberMe">
                        <input
                        id="rememberMe"
                        type="checkbox"
                        name="rememberMe"
                        checked={formData.rememberMe}
                        onChange={handleChange}
                        />
                        Remember Me
                    </label>
                    </li>
                </ul>

                <p className="form-submit">
                    <input
                    name="login"
                    type="submit"
                    className="submit button"
                    value="Login"
                    />
                </p>
                </form>
            )}

            {/* Display link for Register on login form */}
            {isLoginForm && (
                <p className="already-register">
                You do not have an account?{" "}
                <a onClick={() => setIsLoginForm(false)} className="register-popup-form">
                    Register
                </a>
                </p>
            )}

            {/* Display link for Login on registration form */}
            {!isLoginForm && (
                <p className="already-register">
                Already have an account?{" "}
                <a onClick={handleClick} className="login-popup-form">
                    Login
                </a>
                </p>
                
            )}

            {/* Display the form data after submission */}
            {submittedData && (
                <div className="form-data">
               {/*  <h4>Form Data Submitted:</h4> */}
                <pre>{/*JSON.stringify(submittedData, null, 2)*/}</pre>
                </div>
            )}
            </div>
        </div>
        </div>
        <Footer />
    </div>    
  );
};

export default LoginPage;
