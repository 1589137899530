import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head
import './newstyle.css'; // Your styles
import Header from './Header';
import Footer from './Footer';
import { $ }  from 'react-jquery-plugin';
import Loader from './Loader';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


const Pages = () => {
  const [pageSettings, setPageSettings] = useState(null);
  const [buy_data, set_buy_data] =useState(null);// 
  const [exclusivedata, set_exclusive_data] =useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // Added error state to track errors
  const host = window.location.hostname;

  // Fetching page settings from API
  useEffect(() => {
    const fetchPageSettings = async () => {
      try {
        const response = await fetch(`https://${host}:5000/api/page?page_id=1&page_title=home_page`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`); // Handle non-2xx HTTP status
        }

        const data = await response.json();

        if (data && data.data) {
          setPageSettings(data.data);
        } else {
          throw new Error('Error: No data received or incorrect format');
        }
        
      } catch (error) {
        console.error('Error fetching homepage settings:', error);
        setError(error.message); // Set error message to display in UI
      } finally {
        setLoading(false); // Ensure loading is set to false after fetching
      }
    };

    fetchPageSettings();
  }, []); // Empty array means this will run only once on component mount
  
  useEffect(() => {
    // Initialize Owl Carousel when the pageSettings are loaded
    if (pageSettings && pageSettings.testimonials_section) {
      // This ensures the carousel initializes after content is injected into the DOM
      $('.testimonial-slider').owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        navText: ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>'],
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
        
      });
    }
  }, [pageSettings]);

  // Fetching Buy Property Data
  useEffect(() => {
    const fetchBuyData = async () => {
      try {
        const buy_property = await fetch(`https://${host}:5000/api/fetch_buy_property`);
        if (!buy_property.ok) {
          throw new Error(`HTTP error! Status: ${buy_property.status}`); // Handle non-2xx HTTP status
        }

        const buydata = await buy_property.json();
        console.log("Buy property data :", buydata);

        if (buydata) {
          set_buy_data(buydata);
        } else {
          throw new Error('Error: No Buy property data received or incorrect format');
        }
        
      } catch (error) {
        console.error('Error fetching buy property data:', error);
        setError(error.message); // Set error message to display in UI
      }
    };

    fetchBuyData();
  }, []);

  // Fetching Exclusive Property Data
  useEffect(() => {
    const fetchExclusiveData = async () => {
      try {
        const exclusive_property = await fetch(`https://${host}:5000/api/fetch_exclusive_property`);
        if (!exclusive_property.ok) {
          throw new Error(`HTTP error! Status: ${exclusive_property.status}`); // Handle non-2xx HTTP status
        }

        const exclusivedata = await exclusive_property.json();
        console.log("Exclusive property data :", exclusivedata);

        if (exclusivedata) {
          set_exclusive_data(exclusivedata);
        } else {
          throw new Error('Error: No Buy property data received or incorrect format');
        }
        
      } catch (error) {
        console.error('Error fetching buy property data:', error);
        setError(error.message); // Set error message to display in UI
      }
    };

    fetchExclusiveData();
  }, []);

  if (typeof $ === "undefined") {
    // Load jQuery if not already loaded
    const script = document.createElement('script');
    script.src = "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.1/jquery.min.js";
    script.async = true;
    script.onload = () => {
      // jQuery loaded, execute code here
      console.log('jQuery loaded:', $);
      // Initialize carousel or other jQuery-dependent code
    };
    document.body.appendChild(script);
  } else {
    // jQuery is already loaded, execute code directly
    console.log('jQuery is already available');
    // Initialize carousel or other jQuery-dependent code
  }
  
  
  // Loading state
  if (loading) {
    return <div>{loading && <Loader />}</div>;
  }

  // Error state
  if (error) {
    return <div>Error: {error}</div>; // Display error message if an error occurred
  }

  // If no page settings are available
  if (!pageSettings) {
    return <div>No homepage settings available.</div>;
  }

  return (
    <div>
      {/* Add head content dynamically using Helmet */}
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Property One</title>
        <meta name="title" content="PropertyOne: Premier Real Estate Services in Cyprus"></meta>
        <meta name="description" content="Explore properties for sale and rent across Cyprus with PropertyOne. Your trusted partner in Cyprus real estate."></meta>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.1/jquery.min.js"></script>
      </Helmet>

      <Header activeMarket="home" />
      {pageSettings.banner_section && (
        <div dangerouslySetInnerHTML={{ __html: pageSettings.banner_section }} />
      )}

      {pageSettings.content_section && (
        <div dangerouslySetInnerHTML={{ __html: pageSettings.content_section }} />
      )}

      {pageSettings.partners_section && (
        <div dangerouslySetInnerHTML={{ __html: pageSettings.partners_section }} />
      )}
      <section className="exclusive-listings">
        <div className="container">
            <div className="exclusive-title">
                <h2>Featured Listings</h2>
            </div>
            <ul className="list-property">
            {Array.isArray(exclusivedata) && exclusivedata.length > 0 ? (
              exclusivedata.map((property) => (
                  <li key={property.id}>
                    <a href={`/properties/${property.property_id.split('-')[1]}_${property.property_type ==='Land' ? '' : property.bedrooms +'-bedroom-'}${ property.property_type ==='Land' ? '' : property.property_type.toLowerCase() +'-to-'}${property.property_type ==='Land' ? '' : 'buy'}?type=exclusive`} target="_blank">
                          <div className="property-feat-image">
                              <img src={property.features_image } alt={property.title} />
                          </div>
                          <span className="heading-title">{property.bedrooms ==='0' ? '':property.bedrooms} {property.bedrooms =='1' || property.bedrooms =='2' ?'Bed' :'Beds'} {property.property_type} For Sale</span>
                          <div className="property-content-hover">
                              <h4>{property.bedrooms ==='0' ? '':property.bedrooms}{property.bedrooms =='1' || property.bedrooms =='2' ?'Bed' :'Beds'}-{property.property_type}-{property.condition}-{property.property_area} {property.location}, {property.region} </h4>
                              <span>€{property.price ? new Intl.NumberFormat().format(property.price) : 'N/A'}</span>
                              <span>{property.bedrooms} Room</span>
                              <span>{property.property_type}</span>
                              <span className="see-more-prop">See More</span>
                          </div>
                          <div className="property-content">
                              <h6>{property.property_area}</h6>
                              <p>€{property.price ? new Intl.NumberFormat().format(property.price) : 'N/A'}</p>
                          </div>
                      </a>
                  </li>
              ))
          ) : (
              <li>No properties available</li>
          )}
            </ul>
            <div className="btn-row">
                <a className="btn-3" href="/featured">View All Featured Listings
                </a>
            </div>
        </div>
    </section>

      {pageSettings.testimonials_section && (
    
        <div
          dangerouslySetInnerHTML={{ __html: pageSettings.testimonials_section }}
        />
         
      )} 
            <section className="latest-properties">
  <div className="container">
      <div className="latest-title">
          <h2>Latest Properties for Sale in Cyprus</h2>
      </div>
      <ul className="list-property">
          {Array.isArray(buy_data) && buy_data.length > 0 ? (
              buy_data.map((property) => (
                  <li key={property.id}>
                      <a href={`/properties/${property.property_id.split('-')[1]}_${property.property_type ==='Land' ? '' : property.bedrooms +'-bedroom-'}${ property.property_type ==='Land' ? '' : property.property_type.toLowerCase() +'-to-'}${property.property_type ==='Land' ? '' : 'buy'}`} target="_blank">
                          <div className="property-feat-image">
                              <img src={property.features_image || "images/properties-img-1.jpg"} alt={property.title} />
                          </div>
                          <span className="heading-title">{property.bedrooms ==='0' ? '':property.bedrooms} {property.bedrooms =='1' || property.bedrooms =='2' ?'Bed' :'Beds'} {property.property_type} For Sale</span>
                          <div className="property-content-hover">
                              <h4>{property.bedrooms ==='0' ? '':property.bedrooms} {property.bedrooms =='1' || property.bedrooms =='2' ?'Bed' :'Beds'}-{property.property_type}-{property.condition}-{property.property_area} {property.location}, {property.region} </h4>
                              <span>€{property.price ? new Intl.NumberFormat().format(property.price) : 'N/A'}</span>
                              <span>{property.bedrooms} Room</span>
                              <span>{property.property_type}</span>
                              <span className="see-more-prop">See More</span>
                          </div>
                          <div className="property-content">
                              <h6>{property.property_area}</h6>
                              <p>€{property.price ? new Intl.NumberFormat().format(property.price) : 'N/A'}</p>
                          </div>
                      </a>
                  </li>
              ))
          ) : (
              <li>No properties available</li>
          )}
      </ul>
      <div className="btn-row">
          <a className="btn-3" href="/buy">View All Listings for Sale</a>
      </div>
  </div>
</section>


    
      {pageSettings.team_section && (
        <div dangerouslySetInnerHTML={{ __html: pageSettings.team_section }} />
      )}
      
      <Footer />
    </div>
  );
};

export default Pages;
