import React, { useState, useEffect  } from 'react';
import './header.css';  // Import the CSS file
import { toggleMenu } from './style'; // Import the toggleMenu function
import Popup from "./uesrRegisterForm";
import { toast } from 'react-toastify'; // Importing toast and ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Importing the default styles
import { Helmet } from 'react-helmet'; 

const Header = ({ activeMarket, style }) => {
  const [activeSubmenu, setActiveSubmenu] = useState(activeMarket || ''); // Default to the passed activeMarket
  const [isSubmenuVisible, setIsSubmenuVisible] = useState(false); // Track the visibility of the submenu
  const [username, setUsername] = useState(null);  // Track the logged-in username
  const [userMenuVisible, setUserMenuVisible] = useState(false);  // Track the visibility of the Account submenu
 

  useEffect(() => {
    // Check if user is logged in by fetching data from localStorage
    const storedUsername = localStorage.getItem('username');
    const session_id = localStorage.getItem('id');
    const email = localStorage.getItem('email');
    //console.log('email is: ',email);
    if (storedUsername) {
      setUsername(storedUsername);  // Set the username if found
      console.log('Username from localStorage header file:', storedUsername); // Log it for debugging
    } else {
      console.log('No username found in localStorage header file'); // Log if no username is found
    }
    
   // toggleMenu();

    // Ensure DOM is loaded and the element exists
    const toggleButton = document.querySelector('.mobile-toggle-btn');
    const closeButton = document.querySelector('.mobile-toggle-close');
    //console.log('Toggle button:', toggleButton);
  
    if (toggleButton) {
      const handleToggleClick = () => {
        document.body.classList.toggle('menu-show');
      //  console.warn('Done');
      };

      window.addEventListener('scroll', function() {
        var sticky = document.body;
        var scroll = window.scrollY;
      
        if (scroll >= 250) {
          sticky.classList.add('scroll-up');
        } else {
          sticky.classList.remove('scroll-up');
        }
      });
  
      toggleButton.addEventListener('click', handleToggleClick);

      if (closeButton) {
        closeButton.addEventListener('click', function () {
          document.body.classList.remove('menu-show');
         // console.warn('Menu toggled close');
        });
      } else {
       console.warn('Close button not found');
      }
  
      // Cleanup function to remove the event listener
      return () => {
        toggleButton.removeEventListener('click', handleToggleClick);
      };
    } else {
      console.log('Toggle button not found in DOM');
    }
  }, []);

  const handleSubmenuItemClick = (market) => {
    setActiveSubmenu(market); // Set the active submenu item

    if (market === 'market') {
      setIsSubmenuVisible(prevState => !prevState); // Toggle submenu visibility when "Market" is clicked
    } else {
      setIsSubmenuVisible(false); // Close the submenu when a city is clicked
      setActiveSubmenu('market'); // Set the "Market" tab as active when a submenu item is clicked
    }
  };

  // Function to handle submenu visibility on hover
  const handleSubmenuHover = (isHovering) => {
    setIsSubmenuVisible(isHovering);
  };

  // Determine if any of the market pages are active
  const isMarketActive = ['limassol', 'nicosia', 'paphos', 'larnaca', 'famagusta'].includes(activeSubmenu);

  const handleLogout = () => {
    localStorage.removeItem('userToken');
    localStorage.removeItem('username');
    localStorage.removeItem('id');
    setUsername(null); // Clear username from state
    toast.success(`successfully logout!`, {
      autoClose: 3000,

    });
    window.location.href = `https://${host}/`;
  };

  // Handling login and setting username (example)
  const handleLogin = (username) => {
    localStorage.setItem('username', username);  // Store username in localStorage
    setUsername(username);  // Immediately update the state with the username
  };

  const host = window.location.hostname; 

  return (
    <div className='googletag'>
    <header className="main-header-sec" style={activeMarket === "login" ? { backgroundColor: "black" } : {}}>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.7.1/css/all.min.css" />
    
       {/* Do not remove this --- Analytics Tracking code starts --> */}
       <script id='wf_anal' src='https://crm.zohopublic.eu/crm/WebFormAnalyticsServeServlet?rid=617dbb6067034720b4cad8d58ca61076bcda7489198f97091270a5848a861160b4a571db844c70130306f475a7af418cgid24da5c9ce81169a934fd7734c7aeac20c8680dd4c36f31dc0c5aa8082054612bgida53217d45b3df7e42fd3fe62ff13c6d9c46e2332d66365259721eed789b67600gid8674097c16eead13c3d87d4a0133c0e63cef245df0745724b284543cbcf68cd2&tw=9ff967bc054c8cb128e22ad9b966b7c7f484ad76dddba8b21a41a9ee8ef983d2'></script>
           {/*<!-- Do not remove this --- Analytics Tracking code ends. -->*/}
      
      <div className="header-menu-sec">
        <div className="mobile-toggle-btn"><i className="fas fa-bars"></i></div>
        <div className="menu-outer-sec">
          <div className="mobile-toggle-close"><i className="fas fa-times"></i></div>
          <ul className='main-menu'>
          <li
              className={`menu-item about`}
          >
              <a href={`https://${host}/about`}>About</a><i aria-hidden="true" className="icon icon-down-arrow1 elementskit-submenu-indicator"></i>
            </li>

            <li
              className={`menu-item services`}
          >
              <a href={`https://${host}/services`}>Services</a><i aria-hidden="true" className="icon icon-down-arrow1 elementskit-submenu-indicator"></i>
            </li>

            <li
              className={`menu-item ${activeSubmenu === 'rent' ? 'active' : ''}`}
              onClick={() => handleSubmenuItemClick('rent')}
            >
              <a href={`https://${host}/rent`}>Rent</a><i aria-hidden="true" className="icon icon-down-arrow1 elementskit-submenu-indicator"></i>
            </li>
            <li
              className={`menu-item ${activeSubmenu === 'buy' ? 'active' : ''}`}
              onClick={() => handleSubmenuItemClick('buy')}
            >
              <a href={`https://${host}/buy`}> Buy </a> 
              
            </li>
            <li
              className={`menu-item ${isMarketActive ? 'active' : ''}`} // Activate market item if any market page is selected
              onClick={() => handleSubmenuItemClick('market')} // Set active market when clicked
              onMouseEnter={() => handleSubmenuHover(true)} // Show submenu on hover
            >
              <a href="#">Market <i className="fas fa-chevron-down"></i></a>
              {/* Submenu for Market */}
              {isSubmenuVisible && (
                <ul className="elementskit-submenu-panel">
                  <li
                    className={`menu-item ${activeSubmenu === 'limassol' ? 'active' : ''}`}
                    onClick={() => handleSubmenuItemClick('limassol')}
                  >
                   
                    <a href={`https://${host}/market-limassol`} className="dropdown-item">Limassol</a>
                  </li>
                  <li
                    className={`menu-item ${activeSubmenu === 'nicosia' ? 'active' : ''}`}
                    onClick={() => handleSubmenuItemClick('nicosia')}
                  >
                    <a href={`https://${host}/market-nicosia`} className="dropdown-item">Nicosia</a>
                  </li>
                  <li
                    className={`menu-item ${activeSubmenu === 'paphos' ? 'active' : ''}`}
                    onClick={() => handleSubmenuItemClick('paphos')}
                  >
                    <a href={`https://${host}/market-paphos`} className="dropdown-item">Paphos</a>
                  </li>
                  <li
                    className={`menu-item ${activeSubmenu === 'larnaca' ? 'active' : ''}`}
                    onClick={() => handleSubmenuItemClick('larnaca')}
                  >
                    <a href={`https://${host}/market-larnaca`} className="dropdown-item">Larnaca</a>
                  </li>
                  <li
                    className={`menu-item ${activeSubmenu === 'famagusta' ? 'active' : ''}`}
                    onClick={() => handleSubmenuItemClick('famagusta')}
                  >
                    <a href={`https://${host}/market-famagusta`} className="dropdown-item">Famagusta</a>
                  </li>
                </ul>
              )}
            </li>
            <li
              className={`menu-item exclusives`}
          >
              <a href={`https://${host}/featured`}>Featured</a><i aria-hidden="true" className="icon icon-down-arrow1 elementskit-submenu-indicator"></i>
            </li>

            <li
              className={`menu-item contact`}
          >
              <a href={`https://${host}/contact`}>Contact</a><i aria-hidden="true" className="icon icon-down-arrow1 elementskit-submenu-indicator"></i>
            </li>

            <li
              className="menu-item login" style={{ display: 'none' }}
              onMouseEnter={() => setUserMenuVisible(true)}  // Show the dropdown when hovering over the account
             // onMouseLeave={() => setUserMenuVisible(false)} // Hide the dropdown when the hover ends
            >
              {username ? (
                <div>
                  <span>Account <i className="fas fa-chevron-down"></i></span>
                  {/* Show the dropdown only when userMenuVisible is true */}
                  {userMenuVisible && (
                    <ul className="elementskit-submenu-panel">
                      <li className="menu-item">
                        <a href={`https://${host}/update-password`}>Change Password</a> {/* Link to Edit Profile */}
                      </li>
                      <li className="menu-item">
                        <a href={`https://${host}/mylisting`}>Saved Properties</a> {/* Link to Saved Properties */}
                      </li>
                      <li className="menu-item">
                        <a href={`https://${host}/alert-listing`}>Alert Listing</a> {/* Link to Alert Listing */}
                      </li>
                      <li className="menu-item" onClick={handleLogout}>
                        <a href="#">Logout</a> {/* Logout functionality */}
                      </li>
                    </ul>
                  )} 
                </div>
              ) : (
                <a href={`https://${host}/login`}>Login</a> // Show login link if not logged in
              )}
            </li>

          </ul>
        </div>
      </div>

      <div className="header-mid">
        <div className="logo">
         <a href={`https://${host}/`}>
							<img width="150" height="134" src="../images/PropertyOne-logo-white.png" className="attachment-thumbnail size-thumbnail wp-image-2405449" alt="" /></a>
        </div>
      </div>
      <div className="header-right">
        <ul>
          <li>
            <a href="mailto:info@propertyone.com.cy">
              <i className="fas fa-at"></i>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/propertyonehq/" target="_blank">
              <i className="fab fa-instagram"></i>
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/propertyonehq" target="_blank">
              <i className="fab fa-facebook"></i>
            </a>
          </li>
          <li>
            <a href="https://t.me/+35796371372" target="_blank">
              <i className="fab fa-telegram"></i>
            </a>
          </li>
          <li>
            <a href="https://wa.me/+35796371372" target="_blank">
              <i className="fab fa-whatsapp"></i>
            </a>
          </li>
          <li className="phone-btn">
            <a href="tel:+35722285635" target="_blank">
              <i className="fas fa-phone-square-alt"></i>
            </a>
          </li>
            {/* Account Section */}
            <li
              className="menu-item"
              onMouseEnter={() => setUserMenuVisible(true)}  // Show the dropdown when hovering over the account
             // onMouseLeave={() => setUserMenuVisible(false)} // Hide the dropdown when the hover ends
            >
              {username ? (
                <div>
                  <span>Account <i className="fas fa-chevron-down"></i></span>
                  {/* Show the dropdown only when userMenuVisible is true */}
                  {userMenuVisible && (
                    <ul className="elementskit-submenu-panel">
                      <li className="menu-item">
                      <a href={`https://${host}/update-password`}>Change Password</a> {/* Link to Edit Profile */}
                      </li>
                      <li className="menu-item">
                        <a href={`https://${host}/mylisting`}>Saved Properties</a> {/* Link to Saved Properties */}
                      </li>
                      <li className="menu-item">
                        <a href={`https://${host}/alert-listing`}>Alert Listing</a> {/* Link to Alert Listing */}
                      </li>
                      <li className="menu-item" onClick={handleLogout}>
                        <a href="#">Logout</a> {/* Logout functionality */}
                      </li>
                    </ul>
                  )} 
                </div>
              ) : (
                <a href={`https://${host}/login`}>Login</a> // Show login link if not logged in
              )}
            </li>
        </ul>
      </div>
      <div className="menu-blank"></div>
      <Helmet>
        {/* Google Tag Manager */}
        <script>
          {`
            (function(w,d,s,l,i){
              w[l]=w[l]||[];
              w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
              var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
              j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
              f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-WFK5RP7T');
          `}
        </script>
        {/* End Google Tag Manager */}
      </Helmet>
    </header>
    
   {/*  <Helmet>
         
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-63YVCXLYRY"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-63YVCXLYRY');
            `}
          </script>
        </Helmet> */}
    </div>

   
  );
};

export default Header;
