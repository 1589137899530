import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head
import './newstyle.css'; 
import Header from './Header';
import Footer from './Footer'; 
import Loader from './Loader';
import { $ }  from 'react-jquery-plugin';

const Pages = () => {
  const [pageSettings, setPageSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // Added error state to track errors
  const host = window.location.hostname; 
 
  useEffect(() => {
    const fetchPageSettings = async () => {
      try {
        const response = await fetch(`https://${host}:5000/api/page?page_id=7&page_title=property_sell`);
        console.log(response);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`); // Handle non-2xx HTTP status
        }

        const data = await response.json();

        if (data && data.data) {
          setPageSettings(data.data);
        } else {
          throw new Error('Error: No data received or incorrect format');
        }
      } catch (error) {
       /*  console.error('Error fetching homepage settings:', error);
        setError(error.message);  */// Set error message to display in UI
        if (error.response) {
          // Server responded with a status other than 2xx
          setError(`Error: ${error.response.data.message || 'Unable to fetch properties'}`);
        } else if (error.request) {
          // The request was made but no response was received
          setError('Error: No response from the server. Please try again later.');
        } else {
          // Something happened in setting up the request
          setError('Error: Failed to fetch properties due to a network issue.');
        }

      } finally {
        setLoading(false); // Ensure loading is set to false after fetching
      }
    };

    fetchPageSettings();
  }, []);

  // Owl Carousel
  useEffect(() => {
    // Initialize Owl Carousel when the pageSettings are loaded
    if (pageSettings && pageSettings.testimonials_section) {
      // This ensures the carousel initializes after content is injected into the DOM
      $('.repeater-img-slider').owlCarousel({
        loop: true,
        margin: 0,
        dots: false,
        nav: true,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplaySpeed: 1000,
        navText: ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>'],
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
        
      });
    }
  }, [pageSettings]);
  // for counter
/*  useEffect(() => {
    // Set a timeout to delay the counter animation by 5 seconds
    const timer = setTimeout(() => {
      console.log("useEffect triggered after 5 seconds");
  
      const counters = document.querySelectorAll(".counter");
      console.log("Counters found:", counters);
  
      const animateCounter = (counter) => {
        const target = +counter.getAttribute("data-target");
        const speed = target / 200; // Adjust speed for animation
  
        let count = 0;
        const updateCounter = () => {
          if (count < target) {
            count += speed;
            counter.innerText = Math.floor(count);
            requestAnimationFrame(updateCounter);
          } else {
            counter.innerText = target.toLocaleString();
          }
        };
  
        updateCounter();
      };
  
      // Force the counter animation immediately for testing
      counters.forEach((counter) => animateCounter(counter));
  
      // Intersection Observer setup
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              console.log("Element is in view:", entry.target);
              animateCounter(entry.target);
              observer.unobserve(entry.target); // Stop observing after animation
            }
          });
        },
        { threshold: 0, rootMargin: "0px 0px 50px 0px" } // Trigger earlier
      );
  
      counters.forEach((counter) => observer.observe(counter));
      
    // Cleanup observer on component unmount
    return () => {
      counters.forEach((counter) => observer.unobserve(counter));
      clearTimeout(timer); // Clear the timeout when the component unmounts
    };
  }, 1000); // 5000ms = 5 seconds delay

  // Cleanup function to clear timeout if the component unmounts before 5 seconds
  return () => clearTimeout(timer);

}, []);
 */

  
// for user form 
// Initialize form data state
const [formData, setFormData] = useState({
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  city: '',
  propertyType: '',
  description: '',
  leadType: [''],
});

const handleSubmit = (e) => {
  e.preventDefault();

  let isFormValid = true; // To track if the form is valid

  // Validate First Name
  if (!formData.firstName) {
    document.getElementById('First_Name').style.borderColor = "red";
    isFormValid = false;
  } else {
    document.getElementById('First_Name').style.borderColor = "";
  }

  // Validate Last Name
  if (!formData.lastName) {
    document.getElementById('Last_Name').style.borderColor = "red";
    isFormValid = false;
  } else {
    document.getElementById('Last_Name').style.borderColor = "";
  }

  // Validate Email
  if (!formData.email) {
    document.getElementById('Email').style.borderColor = "red";
    isFormValid = false;
  } else {
    document.getElementById('Email').style.borderColor = "";
  }

  // Validate Phone Number
  if (!formData.phone) {
    document.getElementById('Phone').style.borderColor = "red";
    isFormValid = false;
  } else {
    document.getElementById('Phone').style.borderColor = "";
  }

  // Validate City
  if (!formData.city) {
    document.getElementById('City').style.borderColor = "red";
    isFormValid = false;
  } else {
    document.getElementById('City').style.borderColor = "";
  }

  // Validate Property Type
  if (!formData.propertyType) {
    document.getElementById('LEADCF6').style.borderColor = "red";
    isFormValid = false;
  } else {
    document.getElementById('LEADCF6').style.borderColor = "";
  }

  // Validate Description
  if (!formData.description) {
    document.getElementById('Description').style.borderColor = "red";
    isFormValid = false;
  } else {
    document.getElementById('Description').style.borderColor = "";
  }

  // If the form is invalid, do not proceed
  if (!isFormValid) {
    return;
  }

  // Optional: Validate Email Format
  if (!validateEmail(formData.email)) {
    alert("Please enter a valid email.");
    return;
  }

  // Send data to Zoho CRM (your Zoho form submission logic)
  const form = document.getElementById('webform745708000000438001');
  if (!form) {
    console.error("Form not found!");
    return;
  }

  const formFields = [
    'firstName', 'lastName', 'email', 'phone', 'propertyType', 'city', 'description','leadType'
  ];

  formFields.forEach((field) => {
    const input = form.querySelector(`[name=${field}]`);
    if (input) {
      input.value = formData[field];
    } else {
      console.warn(`Field with name ${field} not found in the form.`);
    }
  });

  // Submit the form to Zoho CRM
  form.submit();
};

// Simple email validation function
const validateEmail = (email) => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
};



  if (loading) {
    return <div>{loading && <Loader />}</div>;
  }

   if (error) {
        return (
          <div>
            <Helmet>
              <meta charset="UTF-8" />
              <meta name="viewport" content="width=device-width, initial-scale=1.0" />
              <title>Home-Property One</title>
              <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.1/jquery.min.js"></script>
            </Helmet>
            <Header activeMarket="sell" />
            
            <div
              className="banner-header"
              style={{ backgroundImage: 'url(/images/our-services)' }}
            >
              <h1>{error} </h1> {/* Replace default_market with the actual variable value */}
            </div>
      
            <Footer />
          </div>
        );
      }
      
/*   if (error) {
    return <div>Error: {error}</div>; // Display error message if an error occurred
  } */

  if (!pageSettings) {
    return <div>No homepage settings available.</div>;
  }

  return (
    <div>
      {/* Add head content dynamically using Helmet */}
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Sell-Property One</title>
        <meta name="title" content="About PropertyOne: Leading Real Estate Experts in Cyprus"></meta>
        <meta name="description" content="Learn about PropertyOne's commitment to excellence in the Cyprus real estate market. Discover our mission, vision, and team."></meta>
        <script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.7.2/css/all.min.css"
        integrity="sha512-Evv84Mr4kqVGRNSgIGL/F/aIDqQb7xQ2vcrdIwxfjThSH8CSR7PBEakCr51Ck+w+/U6swU2Im1vVX0SVk9ABhg=="
        crossorigin="anonymous" referrerpolicy="no-referrer" />
      </Helmet>
      <Header activeMarket="about" />
      {/* Render HTML sections dynamically using dangerouslySetInnerHTML */}
      {pageSettings.banner_section && (
        <div dangerouslySetInnerHTML={{ __html: pageSettings.banner_section }} />
      )}

      {pageSettings.content_section && (
        <div dangerouslySetInnerHTML={{ __html: pageSettings.content_section }} />
      )}

      {pageSettings.partners_section && (
        <div dangerouslySetInnerHTML={{ __html: pageSettings.partners_section }} />
      )}

      {pageSettings.testimonials_section && (
        <div dangerouslySetInnerHTML={{ __html: pageSettings.testimonials_section }} />
      )}
      <section className="sell-property-sec">
          <div className="container">
              <div className="sell-property-title">
                  <h2>Sell Your Property With Us</h2>
              </div>
              <div className="sell-form-col">
                  <div className="form-col-left">
                  <script id='wf_anal' src='https://crm.zohopublic.eu/crm/WebFormAnalyticsServeServlet?rid=617dbb6067034720b4cad8d58ca61076bcda7489198f97091270a5848a861160b4a571db844c70130306f475a7af418cgid24da5c9ce81169a934fd7734c7aeac20c8680dd4c36f31dc0c5aa8082054612bgida53217d45b3df7e42fd3fe62ff13c6d9c46e2332d66365259721eed789b67600gid8674097c16eead13c3d87d4a0133c0e63cef245df0745724b284543cbcf68cd2&tw=9ff967bc054c8cb128e22ad9b966b7c7f484ad76dddba8b21a41a9ee8ef983d2'></script>
                      <form className="contact-form" id='webform745708000000438001' action='https://crm.zoho.eu/crm/WebToLeadForm' name='WebToLeads745708000000438001' method='POST' acceptCharset='UTF-8'onSubmit={handleSubmit}>
                      <input type='text' style={{ display: 'none' }} name='xnQsjsdp' value='26a237614b31914c34ad26f89a20ce1a3c56019eb548c7fbe292fa765610f89c' />
                      <input type='hidden' name='zc_gad' id='zc_gad' value='' />
                      <input type='text' style={{ display: 'none' }} name='xmIwtLD' value='15808ee5adab482c8faf72ee7501a5e09104b5fc502f1e9bf73fe123a055dac1f7179259d9775e18c99e598cf6a7800e' />
                      <input type='text' style={{ display: 'none' }} name='actionType' value='TGVhZHM=' />
                      <input type='text' style={{ display: 'none' }} name='returnURL' value={`https://${host}/thank-you/`} />
                          <ul className="form-input-row-sec">
                              <li className="input-group">
                                  <input
                                      type="text"
                                      id="First_Name"
                                      name="First Name"
                                      value={formData.firstName}
                                      onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                                      placeholder="First Name"
                                      maxLength="40"
                                  />
                              </li>
                              <li className="input-group">
                                  <input
                                      type="text"
                                      id="Last_Name"
                                      name="Last Name"
                                      value={formData.lastName}
                                      onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                                      placeholder="Last Name"
                                      maxLength="80"
                                  />
                              </li>
                              <li className="input-group">
                                  <input
                                      type="email"
                                      id="Email"
                                      name="Email"
                                      value={formData.email}
                                      onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                      placeholder="Email"
                                      maxLength="100"
                                  />
                              </li>
                              <li className="input-group">
                                  <input
                                      type="text"
                                      id="Phone"
                                      name="Phone"
                                      value={formData.phone}
                                      onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                                      placeholder="Phone"
                                      maxLength="30"
                                  />
                              </li>
                              <li className="input-group">
                                <select
                                    className="input-group"
                                    id="City"
                                    name="City"
                                    value={formData.city}
                                    onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                                >
                                    <option value="" disabled>Select City</option>
                                    <option value="Limassol">Limassol</option>
                                    <option value="Paphos">Paphos</option>
                                    <option value="Larnaca">Larnaca</option>
                                    <option value="Nicosia">Nicosia</option>
                                    <option value="Famagusta">Famagusta</option>
                                </select>
                            </li>
                              <li className="input-group">
                                  <select
                                  className="input-group"
                                      id="LEADCF6"
                                      name="LEADCF6"
                                      value={formData.propertyType}
                                      onChange={(e) => setFormData({ ...formData, propertyType: e.target.value })}
                                  >
                                      <option value="" disabled>Select property type</option>
                                      <option value="Apartment">Apartment</option>
                                      <option value="Penthouse">Penthouse</option>
                                      <option value="Detached House">Detached House</option>
                                      <option value="Semi-detached House">Semi-detached House</option>
                                      <option value="Villa">Villa</option>
                                      <option value="Plot">Plot</option>
                                      <option value="Land">Land</option>
                                  </select>
                              </li>
                              <li className="input-group">
                                <textarea
                                  id="Description"
                                  name="Description"
                                  value={formData.description}
                                  onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                  placeholder="Description"
                                />
                              </li>
                              <li className="input-group" style={{'display':'none'}}>
                              <select
                                  id="LEADCF1"
                                  name="LEADCF1"
                                  value={formData.leadType[0]} 
                                >
                                  <option value="Landlord Seller">Landlord Seller</option>
                                </select>
                              </li>
                          </ul>
                          <div className="submit-row">
                              <input
                               type="submit"
                               id="formsubmit"
                               className="formsubmit btn"
                               title="Submit"
                               value="Submit"
                              />
                          </div>
                      </form>
                  </div>
                  <div className="form-img-right">
                      <img src="images/formImage.jpg" alt="" />
                  </div>
              </div>
          </div>
      </section>

      {pageSettings.team_section && (
        <div dangerouslySetInnerHTML={{ __html: pageSettings.team_section }} />
      )}

      {/* Add scripts dynamically */}
      <Helmet>
        
      <script src="https://fast.wistia.com/embed/medias/3ebvt41ub3.jsonp" async></script>
      <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.1/jquery.min.js"
          integrity="sha512-v2CJ7UaYy4JwqLDIrZUI/4hqeoQieOmAZNXBeQyjo21dadnwR+8ZaIJVT8EE2iyI61OV8e6M8PP2/4hpQINQ/g=="
          crossorigin="anonymous"
          referrerpolicy="no-referrer"
        ></script>
      
        
      </Helmet>
      <Footer />
    </div>
  );
};

export default Pages;
