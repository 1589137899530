import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';

const MarketTest = () => {
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [propertyLocation, setPropertyLocation] = useState([]); // Store locations
  const [marketData, setMarketData] = useState({}); // Store the market data
  const [aggregatedData, setAggregatedData] = useState({}); // Store aggregated data
  const [hideRowsRent, setHideRowsRent] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(''); // Track selected location rent 

  // for buy 
  const [aggregatedDataForBuy, setAggregatedDataForBuy] = useState({});
  const [hideRowsBuy , setHideRowsBuy] = useState(false);
  const [selectedLocationForBuy, setSelectedLocationForBuy] = useState('');

  // for annual rent data 
  const [selectedLocationAnnualRent, setSelectedLocationAnnualRent] = useState('');
  const [hideRowsAnnualRent, setHideRowsAnnualRent] = useState(false);   // 3rd block on chnage hide
  
  
// for yield 
const [selectedLocationForYield, setSelectedLocationForYield] = useState('');
const [hideRowsYield, setHideRowsAnnualYield] = useState(false);   // 4th block on chnage hide
const [yieldData, setYieldData] = useState(null);

  //default values

  const default_market = 'Nicosia';
  const default_region = 'Nicosia';

  // Fetch locations and market data from the backend
const fetchLocations = async () => {
    try {
      const response = await axios.get(`http://13.38.24.9:5000/api/market-data/${default_market}/${default_region}`);
      console.log('API Response:', response.data);
  
      // Filter market data for 'rent' and 'buy' categories
      const filteredMarketDataRent = response.data.marketData.filter(item => item.buy_rent_category === 'rent');
      const filteredMarketDataBuy = response.data.marketData.filter(item => item.buy_rent_category === 'buy');
  
      // Set the filtered market data for rent and buy
      setMarketData({
        rent: filteredMarketDataRent,
        buy: filteredMarketDataBuy
      });
  
      // Filter locations based on the market data for 'rent' category
      const filteredLocationsRent = response.data.allLocations.filter(location =>
        filteredMarketDataRent.some(item => item.location === location.location)
      );
  
      // Remove duplicates and ensure unique locations for rent
      const uniqueLocationsRent = Array.from(new Set(filteredLocationsRent.map(location => location.location)))
        .map(location => {
          return filteredLocationsRent.find(item => item.location === location);
        });
  
      // Filter locations based on the market data for 'buy' category
      const filteredLocationsBuy = response.data.allLocations.filter(location =>
        filteredMarketDataBuy.some(item => item.location === location.location)
      );
  
      // Remove duplicates and ensure unique locations for buy
      const uniqueLocationsBuy = Array.from(new Set(filteredLocationsBuy.map(location => location.location)))
        .map(location => {
          return filteredLocationsBuy.find(item => item.location === location);
        });
  
      // Set the locations for both rent and buy categories
      setPropertyLocation({
        rent: uniqueLocationsRent,
        buy: uniqueLocationsBuy
      });
  
      // Calculate aggregated data for 'rent' properties
      calculateAggregatedData(response.data.marketData);
    } catch (err) {
      console.error('Error fetching property data:', err);
      setError('There was an error fetching the property data');
    } finally {
      setLoading(false);
    }
  };
  
  // Fetch data when the component is first mounted
  useEffect(() => {
    fetchLocations();
  }, []); // This only runs once when the component mounts
  // Function to calculate aggregated data (count, total, average, annual rent)
/*    const calculateAggregatedData = (data) => { 
    // Filter only 'rent' properties
    const filteredData = data.filter(item => item.buy_rent_category === 'rent');
    
    // Sort the filtered data by the number of bedrooms in ascending order (1, 2, 3)
    const sortedData = filteredData.sort((a, b) => parseInt(a.location) - parseInt(b.location));
  
    const aggregated = sortedData.reduce((acc, item) => {
        const { location, bedrooms, price, cost_per_square_meter } = item;
  
        // Validate the data
        if (!location || !bedrooms || !price || !cost_per_square_meter) {
            console.warn("Invalid data detected", item);
            return acc;
        }
  
        // Initialize location object if it doesn't exist
        if (!acc[location]) {
            acc[location] = { 1: { count: 0, totalPrice: 0 }, 2: { count: 0, totalPrice: 0}, 3: { count: 0, totalPrice: 0 }, 4:{ count: 0, totalArea: 0 , pricePerM2:0}};
        }
  
        // Ensure the price and cost_per_square_meter are valid numbers
        const validPrice = parseFloat(price);
        const validCostPerM2 = parseFloat(cost_per_square_meter);
        if (isNaN(validPrice) || isNaN(validCostPerM2)) {
            console.warn("Invalid price or cost_per_square_meter detected for", item);
            return acc;
        }
  
        // Accumulate data based on the number of bedrooms
        if (bedrooms === '1') {
            acc[location][1].count += 1;
            acc[location][1].totalPrice += validPrice;
        } else if (bedrooms === '2') {
            acc[location][2].count += 1;
            acc[location][2].totalPrice += validPrice;
        } else if (bedrooms === '3') {
            acc[location][3].count += 1;
            acc[location][3].totalPrice += validPrice;
        }
      
        acc[location][4].count += 1;
        acc[location][4].totalArea += validCostPerM2;
        acc[location][4].pricePerM2 = `${Intl.NumberFormat().format(Math.round(acc[location][4].totalArea/acc[location][4].count))} m²`;

        return acc;
    }, {});

    // Format values and calculate average price, price per m², and annual rent for each bedroom type
    for (const location in aggregated) {
        for (const bedrooms in aggregated[location]) {
            const count = aggregated[location][bedrooms].count;
            const totalPrice = aggregated[location][bedrooms].totalPrice;
           // const totalArea = aggregated[location][bedrooms].totalArea;
            
            if (count > 0) {
                // Use Intl.NumberFormat to format and round the values
                aggregated[location][bedrooms].averagePrice = `€${Intl.NumberFormat().format(Math.round(totalPrice / count))}`;
                aggregated[location][bedrooms].annualRent = `€${Intl.NumberFormat().format(Math.round((totalPrice / count) * 12))}`;
               
            } else {
                aggregated[location][bedrooms].averagePrice = 'Not Enough Data';
                aggregated[location][bedrooms].annualRent = 'Not Enough Data';
              
            }
        }
    }

    // Sort the locations to ensure 'Nicosia' comes first
    const sortedAggregated = Object.keys(aggregated).sort((a, b) => {
        if (a === 'Nicosia') return -1; // Nicosia should come first
        if (b === 'Nicosia') return 1;  // If b is Nicosia, it should come after a
        return 0; // Keep other locations in the same order
    });

    // Create a new object with sorted locations
    const sortedAggregatedData = {};
    sortedAggregated.forEach(location => {
        sortedAggregatedData[location] = aggregated[location];
    });

    console.log("Aggregated Data (sorted):", sortedAggregatedData);
    setAggregatedData(sortedAggregatedData);
}; */
/* const calculateAggregatedData = (data) => {
  // Filter only 'rent' properties
  const filteredData = data.filter(item => item.buy_rent_category === 'rent');
  
  // Sort the filtered data by the number of bedrooms in ascending order (1, 2, 3)
  const sortedData = filteredData.sort((a, b) => parseInt(a.location) - parseInt(b.location));

  // Initialize global aggregation for all bedrooms (1, 2, 3) combined
  const globalAggregation = { 1: { count: 0, totalPrice: 0 }, 2: { count: 0, totalPrice: 0 }, 3: { count: 0, totalPrice: 0 }, total: { count: 0, totalPrice: 0, totalArea: 0 }};

  const aggregated = sortedData.reduce((acc, item) => {
      const { location, bedrooms, price, cost_per_square_meter } = item;

      // Validate the data
      if (!location || !bedrooms || !price || !cost_per_square_meter) {
          console.warn("Invalid data detected", item);
          return acc;
      }

      // Initialize location object if it doesn't exist
      if (!acc[location]) {
          acc[location] = { 1: { count: 0, totalPrice: 0 }, 2: { count: 0, totalPrice: 0 }, 3: { count: 0, totalPrice: 0 }, 4: { count: 0, totalArea: 0, pricePerM2: 0 }};
      }

      // Ensure the price and cost_per_square_meter are valid numbers
      const validPrice = parseFloat(price);
      const validCostPerM2 = parseFloat(cost_per_square_meter);
      if (isNaN(validPrice) || isNaN(validCostPerM2)) {
          console.warn("Invalid price or cost_per_square_meter detected for", item);
          return acc;
      }

      // Accumulate data based on the number of bedrooms
      if (bedrooms === '1') {
          acc[location][1].count += 1;
          acc[location][1].totalPrice += validPrice;
          globalAggregation[1].count += 1;
          globalAggregation[1].totalPrice += validPrice;
      } else if (bedrooms === '2') {
          acc[location][2].count += 1;
          acc[location][2].totalPrice += validPrice;
          globalAggregation[2].count += 1;
          globalAggregation[2].totalPrice += validPrice;
      } else if (bedrooms === '3') {
          acc[location][3].count += 1;
          acc[location][3].totalPrice += validPrice;
          globalAggregation[3].count += 1;
          globalAggregation[3].totalPrice += validPrice;
      }

      // Accumulate global total area
      acc[location][4].count += 1;
      acc[location][4].totalArea += validCostPerM2;
      acc[location][4].pricePerM2 = `${Intl.NumberFormat().format(Math.round(acc[location][4].totalArea / acc[location][4].count))} m²`;

      // Update global total area
      globalAggregation.total.count += 1;
      globalAggregation.total.totalPrice += validPrice;
      globalAggregation.total.totalArea += validCostPerM2;

      return acc;
  }, {});

  // Format values and calculate average price, price per m², and annual rent for each bedroom type
  for (const location in aggregated) {
      for (const bedrooms in aggregated[location]) {
          const count = aggregated[location][bedrooms].count;
          const totalPrice = aggregated[location][bedrooms].totalPrice;
          const totalArea = aggregated[location][bedrooms].totalArea;
          
          if (count > 0) {
              // Use Intl.NumberFormat to format and round the values
              aggregated[location][bedrooms].averagePrice = `€${Intl.NumberFormat().format(Math.round(totalPrice / count))}`;
              aggregated[location][bedrooms].annualRent = `€${Intl.NumberFormat().format(Math.round((totalPrice / count) * 12))}`;
          } else {
              aggregated[location][bedrooms].averagePrice = 'Not Enough Data';
              aggregated[location][bedrooms].annualRent = 'Not Enough Data';
          }
      }
  }

  // Add global aggregation data (1, 2, 3 combined)
  aggregated["All"] = {
      1: { count: globalAggregation[1].count, totalPrice: globalAggregation[1].totalPrice },
      2: { count: globalAggregation[2].count, totalPrice: globalAggregation[2].totalPrice },
      3: { count: globalAggregation[3].count, totalPrice: globalAggregation[3].totalPrice },
      total: { count: globalAggregation.total.count, totalPrice: globalAggregation.total.totalPrice, totalArea: globalAggregation.total.totalArea, pricePerM2: `${Intl.NumberFormat().format(Math.round(globalAggregation.total.totalArea / globalAggregation.total.count))} m²` }
  };

  // Format the global aggregated data
  for (const bedrooms in aggregated["All"]) {
      const count = aggregated["All"][bedrooms].count;
      const totalPrice = aggregated["All"][bedrooms].totalPrice;
      const totalArea = aggregated["All"][bedrooms].totalArea;

      if (count > 0) {
          aggregated["All"][bedrooms].averagePrice = `€${Intl.NumberFormat().format(Math.round(totalPrice / count))}`;
          aggregated["All"][bedrooms].annualRent = `€${Intl.NumberFormat().format(Math.round((totalPrice / count) * 12))}`;
      } else {
          aggregated["All"][bedrooms].averagePrice = 'Not Enough Data';
          aggregated["All"][bedrooms].annualRent = 'Not Enough Data';
      }
  }

  // Sort the locations to ensure 'Nicosia' comes first
  const sortedAggregated = Object.keys(aggregated).sort((a, b) => {
      if (a === 'Nicosia') return -1; // Nicosia should come first
      if (b === 'Nicosia') return 1;  // If b is Nicosia, it should come after a
      return 0; // Keep other locations in the same order
  });

  // Create a new object with sorted locations
  const sortedAggregatedData = {};
  sortedAggregated.forEach(location => {
      sortedAggregatedData[location] = aggregated[location];
  });

  console.log("Aggregated Data (sorted):", sortedAggregatedData);
  setAggregatedData(sortedAggregatedData['All']);
}; */
 const calculateAggregatedData = (data) => {
  // Filter only 'rent' properties
  const filteredData = data.filter(item => item.buy_rent_category === 'rent');
  
  // Sort the filtered data by the number of bedrooms in ascending order (1, 2, 3)
  const sortedData = filteredData.sort((a, b) => parseInt(a.location) - parseInt(b.location));

  // Initialize global aggregation for all bedrooms (1, 2, 3) combined
  const globalAggregation = { 1: { count: 0, totalPrice: 0 }, 2: { count: 0, totalPrice: 0 }, 3: { count: 0, totalPrice: 0 }, total: { count: 0, totalPrice: 0, totalArea: 0 }};

  const aggregated = sortedData.reduce((acc, item) => {
      const { location, bedrooms, price, cost_per_square_meter } = item;

      // Validate the data
      if (!location || !bedrooms || !price || !cost_per_square_meter) {
          console.warn("Invalid data detected", item);
          return acc;
      }

      // Initialize location object if it doesn't exist
      if (!acc[location]) {
          acc[location] = { 1: { count: 0, totalPrice: 0 }, 2: { count: 0, totalPrice: 0 }, 3: { count: 0, totalPrice: 0 }, 4: { count: 0, totalArea: 0, pricePerM2: 0 }};
      }

      // Ensure the price and cost_per_square_meter are valid numbers
      const validPrice = parseFloat(price.replace(/[^0-9.-]+/g, ''));  // Clean up any non-numeric characters
      const validCostPerM2 = parseFloat(cost_per_square_meter.replace(/[^0-9.-]+/g, ''));
      if (isNaN(validPrice) || isNaN(validCostPerM2)) {
          console.warn("Invalid price or cost_per_square_meter detected for", item);
          return acc;
      }

      // Accumulate data based on the number of bedrooms
      if (bedrooms === '1') {
          acc[location][1].count += 1;
          acc[location][1].totalPrice += validPrice;
          globalAggregation[1].count += 1;
          globalAggregation[1].totalPrice += validPrice;
      } else if (bedrooms === '2') {
          acc[location][2].count += 1;
          acc[location][2].totalPrice += validPrice;
          globalAggregation[2].count += 1;
          globalAggregation[2].totalPrice += validPrice;
      } else if (bedrooms === '3') {
          acc[location][3].count += 1;
          acc[location][3].totalPrice += validPrice;
          globalAggregation[3].count += 1;
          globalAggregation[3].totalPrice += validPrice;
      }

      // Accumulate global total area
      acc[location][4].count += 1;
      acc[location][4].totalArea += validCostPerM2;

      // Calculate price per square meter
      if (acc[location][4].count > 0) {
          acc[location][4].pricePerM2 = `${Intl.NumberFormat().format(Math.round(acc[location][4].totalArea / acc[location][4].count))} m²`;
      }

      // Update global total area
      globalAggregation.total.count += 1;
      globalAggregation.total.totalPrice += validPrice;
      globalAggregation.total.totalArea += validCostPerM2;

      return acc;
  }, {});

  // Format values and calculate average price, price per m², and annual rent for each bedroom type
  for (const location in aggregated) {
      for (const bedrooms in aggregated[location]) {
          const count = aggregated[location][bedrooms].count;
          const totalPrice = aggregated[location][bedrooms].totalPrice;
          const totalArea = aggregated[location][bedrooms].totalArea;
          
          if (count > 0) {
              aggregated[location][bedrooms].averagePrice = `€${Intl.NumberFormat().format(Math.round(totalPrice / count))}`;
              aggregated[location][bedrooms].annualRent = `€${Intl.NumberFormat().format(Math.round((totalPrice / count) * 12))}`;
          } else {
              aggregated[location][bedrooms].averagePrice = 'Not Enough Data';
              aggregated[location][bedrooms].annualRent = 'Not Enough Data';
          }
      }
  }

  // Add global aggregation data with the "Nicosia(Avg)" key
  aggregated["Nicosia(Avg)"] = {
      1: { count: globalAggregation[1].count, totalPrice: globalAggregation[1].totalPrice },
      2: { count: globalAggregation[2].count, totalPrice: globalAggregation[2].totalPrice },
      3: { count: globalAggregation[3].count, totalPrice: globalAggregation[3].totalPrice },
      total: { count: globalAggregation.total.count, totalPrice: globalAggregation.total.totalPrice, totalArea: globalAggregation.total.totalArea, pricePerM2: `${Intl.NumberFormat().format(Math.round(globalAggregation.total.totalArea / globalAggregation.total.count))} m²` }
  };

  // Format the global aggregated data under "Nicosia(Avg)"
  for (const bedrooms in aggregated["Nicosia(Avg)"]) {
      const count = aggregated["Nicosia(Avg)"][bedrooms].count;
      const totalPrice = aggregated["Nicosia(Avg)"][bedrooms].totalPrice;
      const totalArea = aggregated["Nicosia(Avg)"][bedrooms].totalArea;

      if (count > 0) {
          aggregated["Nicosia(Avg)"][bedrooms].averagePrice = `€${Intl.NumberFormat().format(Math.round(totalPrice / count))}`;
          aggregated["Nicosia(Avg)"][bedrooms].annualRent = `€${Intl.NumberFormat().format(Math.round((totalPrice / count) * 12))}`;
      } else {
          aggregated["Nicosia(Avg)"][bedrooms].averagePrice = 'Not Enough Data';
          aggregated["Nicosia(Avg)"][bedrooms].annualRent = 'Not Enough Data';
      }
  }

  // Sort the locations to ensure 'Nicosia' comes first
  const sortedAggregated = Object.keys(aggregated).sort((a, b) => {
      if (a === 'Nicosia(Avg)') return -1;
      if (b === 'Nicosia(Avg)') return 1;
      return a.localeCompare(b);  // Sort other locations alphabetically
  });

  // Create a new object with sorted locations
  const sortedAggregatedData = {};
  sortedAggregated.forEach(location => {
      sortedAggregatedData[location] = aggregated[location];
  });

  console.log("Aggregated Data (sorted):", sortedAggregatedData);
  setAggregatedData(sortedAggregatedData);
}; 


// Handle location selection change
  const handleLocationChange = (event) => {
    const selected = event.target.value;
    console.log('Selected Location:', selected);
    if (selected === "") {
      setHideRowsRent(false); 
    } else {
      setHideRowsRent(true); 
    }
    // Set the selected location
    setSelectedLocation(selected);
  };
// for buy property market data 

/*  const calculateAggregatedDataForBuy = (data) => {
  // Filter only 'buy' properties
  const filteredData = data.filter(item => item.buy_rent_category === 'buy');
  
  // Sort the filtered data by the number of bedrooms in ascending order (1, 2, 3)
  const sortedData = filteredData.sort((a, b) => parseInt(a.location) - parseInt(b.location));

  const aggregated = sortedData.reduce((acc, item) => {
      const { location, bedrooms, price, cost_per_square_meter } = item;

      // Validate the data
      if (!location || !bedrooms || !price || !cost_per_square_meter) {
          console.warn("Invalid data detected", item);
          return acc;
      }

      // Initialize location object if it doesn't exist
      if (!acc[location]) {
          acc[location] = { 1: { count: 0, totalPrice: 0 }, 2: { count: 0, totalPrice: 0}, 3: { count: 0, totalPrice: 0 }, 4:{ count: 0, totalArea: 0 , pricePerM2:0}};
      }

      // Ensure the price and cost_per_square_meter are valid numbers
      const validPrice = parseFloat(price);
      const validCostPerM2 = parseFloat(cost_per_square_meter);
      if (isNaN(validPrice) || isNaN(validCostPerM2)) {
          console.warn("Invalid price or cost_per_square_meter detected for", item);
          return acc;
      }

      // Accumulate data based on the number of bedrooms
      if (bedrooms === '1') {
          acc[location][1].count += 1;
          acc[location][1].totalPrice += validPrice;
      } else if (bedrooms === '2') {
          acc[location][2].count += 1;
          acc[location][2].totalPrice += validPrice;
      } else if (bedrooms === '3') {
          acc[location][3].count += 1;
          acc[location][3].totalPrice += validPrice;
      }
    
      acc[location][4].count += 1;
      acc[location][4].totalArea += validCostPerM2;
      acc[location][4].pricePerM2 = `${Intl.NumberFormat().format(Math.round(acc[location][4].totalArea/acc[location][4].count))} m²`;

      return acc;
  }, {});

  // Format values and calculate average price, price per m² for each bedroom type
  for (const location in aggregated) {
      for (const bedrooms in aggregated[location]) {
          const count = aggregated[location][bedrooms].count;
          const totalPrice = aggregated[location][bedrooms].totalPrice;
          
          if (count > 0) {
              // Use Intl.NumberFormat to format and round the values
              aggregated[location][bedrooms].averagePrice = `€${Intl.NumberFormat().format(Math.round(totalPrice / count))}`;
          } else {
              aggregated[location][bedrooms].averagePrice = 'Not Enough Data';
          }
      }
  }

  // Sort the locations to ensure 'Nicosia' comes first
  const sortedAggregated = Object.keys(aggregated).sort((a, b) => {
      if (a === 'Nicosia') return -1; // Nicosia should come first
      if (b === 'Nicosia') return 1;  // If b is Nicosia, it should come after a
      return 0; // Keep other locations in the same order
  });

  // Create a new object with sorted locations
  const sortedAggregatedData = {};
  sortedAggregated.forEach(location => {
      sortedAggregatedData[location] = aggregated[location];
  });

  console.log("Aggregated Data for Buy (sorted):", sortedAggregatedData);
  setAggregatedDataForBuy(sortedAggregatedData);
};   */

const calculateAggregatedDataForBuy = (data) => {
  // Filter only 'buy' properties
  const filteredData = data.filter(item => item.buy_rent_category === 'buy');
  
  // Sort the filtered data by the number of bedrooms in ascending order (1, 2, 3)
  const sortedData = filteredData.sort((a, b) => parseInt(a.location) - parseInt(b.location));

  // Initialize global aggregation for all bedrooms (1, 2, 3) combined
  const globalAggregation = { 1: { count: 0, totalPrice: 0 }, 2: { count: 0, totalPrice: 0 }, 3: { count: 0, totalPrice: 0 }, total: { count: 0, totalPrice: 0, totalArea: 0 }};

  const aggregated = sortedData.reduce((acc, item) => {
      const { location, bedrooms, price, cost_per_square_meter } = item;

      // Validate the data
      if (!location || !bedrooms || !price || !cost_per_square_meter) {
          console.warn("Invalid data detected", item);
          return acc;
      }

      // Initialize location object if it doesn't exist
      if (!acc[location]) {
          acc[location] = { 1: { count: 0, totalPrice: 0 }, 2: { count: 0, totalPrice: 0}, 3: { count: 0, totalPrice: 0 }, 4:{ count: 0, totalArea: 0 , pricePerM2:0}};
      }

      // Ensure the price and cost_per_square_meter are valid numbers
      const validPrice = parseFloat(price);
      const validCostPerM2 = parseFloat(cost_per_square_meter);
      if (isNaN(validPrice) || isNaN(validCostPerM2)) {
          console.warn("Invalid price or cost_per_square_meter detected for", item);
          return acc;
      }

      // Accumulate data based on the number of bedrooms
      if (bedrooms === '1') {
          acc[location][1].count += 1;
          acc[location][1].totalPrice += validPrice;
          globalAggregation[1].count += 1;
          globalAggregation[1].totalPrice += validPrice;
      } else if (bedrooms === '2') {
          acc[location][2].count += 1;
          acc[location][2].totalPrice += validPrice;
          globalAggregation[2].count += 1;
          globalAggregation[2].totalPrice += validPrice;
      } else if (bedrooms === '3') {
          acc[location][3].count += 1;
          acc[location][3].totalPrice += validPrice;
          globalAggregation[3].count += 1;
          globalAggregation[3].totalPrice += validPrice;
      }
    
      acc[location][4].count += 1;
      acc[location][4].totalArea += validCostPerM2;
      
      // Calculate price per square meter
      if (acc[location][4].count > 0) {
        acc[location][4].pricePerM2 = `${Intl.NumberFormat().format(Math.round(acc[location][4].totalArea / acc[location][4].count))} m²`;
    }
     /*  acc[location][4].pricePerM2 = `${Intl.NumberFormat().format(Math.round(acc[location][4].totalArea/acc[location][4].count))} m²`; */

       // Update global total area
       globalAggregation.total.count += 1;
       globalAggregation.total.totalPrice += validPrice;
       globalAggregation.total.totalArea += validCostPerM2;

      return acc;
  }, {});

  // Format values and calculate average price, price per m² for each bedroom type
  for (const location in aggregated) {
      for (const bedrooms in aggregated[location]) {
          const count = aggregated[location][bedrooms].count;
          const totalPrice = aggregated[location][bedrooms].totalPrice;
          
          if (count > 0) {
              // Use Intl.NumberFormat to format and round the values
              aggregated[location][bedrooms].averagePrice = `€${Intl.NumberFormat().format(Math.round(totalPrice / count))}`;
              aggregated[location][bedrooms].annualRent = `€${Intl.NumberFormat().format(Math.round((totalPrice / count) * 12))}`;
          } else {
              aggregated[location][bedrooms].averagePrice = 'Not Enough Data';
              aggregated[location][bedrooms].annualRent = 'Not Enough Data';
          }
      }
  }

  aggregated["Nicosia(Avg)"] = {
    1: { count: globalAggregation[1].count, totalPrice: globalAggregation[1].totalPrice },
    2: { count: globalAggregation[2].count, totalPrice: globalAggregation[2].totalPrice },
    3: { count: globalAggregation[3].count, totalPrice: globalAggregation[3].totalPrice },
    total: { count: globalAggregation.total.count, totalPrice: globalAggregation.total.totalPrice, totalArea: globalAggregation.total.totalArea, pricePerM2: `${Intl.NumberFormat().format(Math.round(globalAggregation.total.totalArea / globalAggregation.total.count))} m²` }
};

// Format the global aggregated data under "Nicosia(Avg)"
for (const bedrooms in aggregated["Nicosia(Avg)"]) {
    const count = aggregated["Nicosia(Avg)"][bedrooms].count;
    const totalPrice = aggregated["Nicosia(Avg)"][bedrooms].totalPrice;
    const totalArea = aggregated["Nicosia(Avg)"][bedrooms].totalArea;

    if (count > 0) {
        aggregated["Nicosia(Avg)"][bedrooms].averagePrice = `€${Intl.NumberFormat().format(Math.round(totalPrice / count))}`;
        aggregated["Nicosia(Avg)"][bedrooms].annualRent = `€${Intl.NumberFormat().format(Math.round((totalPrice / count) * 12))}`;
    } else {
        aggregated["Nicosia(Avg)"][bedrooms].averagePrice = 'Not Enough Data';
        aggregated["Nicosia(Avg)"][bedrooms].annualRent = 'Not Enough Data';
    }
}
  // Sort the locations to ensure 'Nicosia' comes first
  const sortedAggregated = Object.keys(aggregated).sort((a, b) => {
      if (a === 'Nicosia(Avg)') return -1; // Nicosia should come first
      if (b === 'Nicosia(Avg)') return 1;  // If b is Nicosia, it should come after a
      return 0; // Keep other locations in the same order
  });

  // Create a new object with sorted locations
  const sortedAggregatedData = {};
  sortedAggregated.forEach(location => {
      sortedAggregatedData[location] = aggregated[location];
  });

  console.log("Aggregated Data for Buy (sorted):", sortedAggregatedData);
  setAggregatedDataForBuy(sortedAggregatedData);
};   



const handleLocationChangeForBuy = (event) => {
  const selected = event.target.value;
  console.log('Selected Location for Buy:', selected);
  if (selected === "") {
      setHideRowsBuy(false); 
  } else {
      setHideRowsBuy(true); 
  }
  // Set the selected location for buy properties
  setSelectedLocationForBuy(selected);
};

useEffect(() => {
  if (marketData && marketData.buy) {
    calculateAggregatedDataForBuy(marketData.buy);
  }
}, [marketData]);

const handleLocationChangeAnnualRent = (event) => {
  const selected = event.target.value;
  console.log('Selected Location for Annual Rent:', selected);
  if (selected === "") {
    setHideRowsAnnualRent(false); 
} else {
  setHideRowsAnnualRent(true); 
}

  // Set the selected location for Annual Rent
  setSelectedLocationAnnualRent(selected);
};

// Function to calculate yield percentage
const calculateYield = (buyData, rentData, selectedLocation) => {
  if (!buyData[selectedLocation] || !rentData[selectedLocation]) {
    return null; // If no data is available for the location
  }

  // Parse prices and rents as floats
  const avgPrice1 = parseFloat(buyData[selectedLocation][1].averagePrice.replace('€', '').replace(',', ''));
  const avgPrice2 = parseFloat(buyData[selectedLocation][2].averagePrice.replace('€', '').replace(',', ''));
  const avgPrice3 = parseFloat(buyData[selectedLocation][3].averagePrice.replace('€', '').replace(',', ''));

  const annualRent1 = parseFloat(rentData[selectedLocation][1].annualRent.replace('€', '').replace(',', ''));
  const annualRent2 = parseFloat(rentData[selectedLocation][2].annualRent.replace('€', '').replace(',', ''));
  const annualRent3 = parseFloat(rentData[selectedLocation][3].annualRent.replace('€', '').replace(',', ''));

  // Calculate yields
  const yield1 = avgPrice1 && annualRent1 ? (annualRent1 / avgPrice1) * 100 : null;
  const yield2 = avgPrice2 && annualRent2 ? (annualRent2 / avgPrice2) * 100 : null;
  const yield3 = avgPrice3 && annualRent3 ? (annualRent3 / avgPrice3) * 100 : null;

  const formattedyield1 = (yield1) ? `${Intl.NumberFormat().format(Math.round(yield1))}%` : 'Not Enough Data';
const formattedyield2 = ( yield2) ? `${Intl.NumberFormat().format(Math.round(yield2))}%` : 'Not Enough Data';
const formattedyield3 = ( yield3) ? `${Intl.NumberFormat().format(Math.round(yield3))}%` : 'Not Enough Data';

  return { formattedyield1, formattedyield2, formattedyield3 };
};
const handleLocationChangeForYield = (event) => {
  const selected = event.target.value;
  console.log('Selected Location for Annual Rent:', selected);
  if (selected === "") {
    setHideRowsAnnualYield(false); 
} else {
  setHideRowsAnnualYield(true); 
}

  setSelectedLocationForYield(event.target.value);
};


// Calculate yields for all locations on initial load
useEffect(() => {
  const allYields = {};
  Object.keys(aggregatedDataForBuy).forEach((location) => {
    const yieldDataForLocation = calculateYield(aggregatedDataForBuy, aggregatedData, location);
    if (yieldDataForLocation) {
      allYields[location] = yieldDataForLocation;
    }
  });
  setYieldData(allYields);

  // Set the initial location (can be the first available location)
  const initialLocation = Object.keys(aggregatedDataForBuy)[0];
  //setSelectedLocationForYield(initialLocation);
}, [aggregatedDataForBuy, aggregatedData]);

// Recalculate yield when the selected location changes
useEffect(() => {
  if (selectedLocationForYield) {
    const calculatedYield = calculateYield(aggregatedDataForBuy, aggregatedData, selectedLocationForYield);
    setYieldData((prevData) => ({
      ...prevData,
      [selectedLocationForYield]: calculatedYield,
    }));
  }
}, [selectedLocationForYield, aggregatedDataForBuy, aggregatedData]);

  if (loading) {
    return <div>Loading property data...</div>;
  }

  return (
    <div className="property-detail">
      <Header />

      <div
        className="banner-header"
        style={{ backgroundImage: 'url(https://propertyone.com.cy/wp-content/uploads/2024/08/nicosia-1024x293.jpg)' }}
      >
        <h1>Current Market Insights for {default_market}</h1>
      </div>

      {/* Rent Section */}
      <div className="insight-repeater-sec rent">
        <div className="container">
          <h2>Current AVG Rental Pricing</h2>

          {/* Location Select Dropdown for Rent */}
             <div className="limassol-location">
                <select
                  className="location-select select2 select2-hidden-accessible"
                  name="location_rent"
                  value={''} // Set a state variable for selected location here, if needed
                  onChange={handleLocationChange} // Call the function to handle location selection change
                >
                  <option value="">Select a location</option>
                  {/* Dynamically populate dropdown options from propertyLocation.rent */}
                  {propertyLocation.rent.map((locationObj, index) => (
                    <option key={index} value={locationObj.location}>
                      {locationObj.location}
                    </option>
                  ))}
                </select>
             </div>


          {/* Table for Rent Market Data */}
          <div className="insight-table-main">
                <table className="rent_list">
                  <thead>
                    <tr>
                      <th>Location</th>
                      <th>1 Bed Rent Price</th>
                      <th>2 Bed Rent Price</th>
                      <th>3 Bed Rent Price</th>
                      <th>Price Per m²</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Loop through the first 4 locations in the aggregated data */}
                   {Object.keys(aggregatedData).slice(0, 4).map((loc, index) => (
                    !hideRowsRent || (index !== 1 && index !== 2 && index !== 3 && index !== 4) ? (
                      <tr key={loc} className="table-row-rent" id={`row-${index}`}>
                        <td>{loc}</td>
                        
                        {/* Average Price for Bedrooms 1, 2, 3 */}
                        <td>{aggregatedData[loc][1].averagePrice}</td>
                        <td>{aggregatedData[loc][2].averagePrice}</td>
                        <td>{aggregatedData[loc][3].averagePrice}</td>

                        {/* Conditional rendering for pricePerM2 */}
                        <td>
                          {loc === "Nicosia(Avg)"
                            ? aggregatedData[loc]["total"].pricePerM2 // For Nicosia(Avg), use the 'total' key
                            : aggregatedData[loc][4].pricePerM2  // For other locations, use the regular location data
                          }
                        </td>
                      </tr>
                    ) : null
                  ))}


                     {/* Filter the aggregated data to only show Nicosia and selected location */}
                    {Object.keys(aggregatedData).filter(location => location === selectedLocation).map((location, index) => (
                      <tr key={location} className="table-row-rent" id={`row-${index}`}>
                        <td>{location}</td>
                        <td>{aggregatedData[location][1].averagePrice}</td>
                        <td>{aggregatedData[location][2].averagePrice}</td>
                        <td>{aggregatedData[location][3].averagePrice}</td>
                        <td>{aggregatedData[location][4].pricePerM2}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
            </div>
          <ul className="insight-button-list">
            <li className="insight-register-btn">
              <a href="javascript:void(0)" className="register-popup-form">
                Register To View All Towns
              </a>
            </li>
            <li>
              <a href="#!" className="rpa-popup-form" onClick={''}>
                Get Rental Price Alert
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Buy Section */}
      <div className="insight-repeater-sec buy">
        <div className="container">
          <h2>Current AVG Sale Pricing</h2>

          {/* Location Select Dropdown for Rent */}
          <div className="limassol-location">
              <select
                  className="location-select select2 select2-hidden-accessible"
                  name="location_buy"
                  value={''} // Set a state variable for selected location here, if needed
                  onChange={handleLocationChangeForBuy} // Call the function to handle location selection change
              >
                  <option value="">Select a location</option>
                  {/* Dynamically populate dropdown options from propertyLocation.buy */}
                  {propertyLocation.buy.map((locationObj, index) => (
                      <option key={index} value={locationObj.location}>
                          {locationObj.location}
                      </option>
                  ))}
              </select>
          </div>
         {/* Table for Buy Market Data */}
            <div className="insight-table-main">
                  <table className="buy_list">
                      <thead>
                          <tr>
                              <th>Location</th>
                              <th>1 Bed Buy Price</th>
                              <th>2 Bed Buy Price</th>
                              <th>3 Bed Buy Price</th>
                              <th>Price Per m²</th>
                          </tr>
                      </thead>
                      <tbody>
                          {/* Loop through the first 4 locations in the aggregated data */}
                          {Object.keys(aggregatedDataForBuy).slice(0, 4).map((loc, index) => (
                              !hideRowsBuy || (index !== 1 && index !== 2 && index !== 3 && index !== 4) ? (
                                  <tr key={loc} className="table-row-buy" id={`row-${index}`}>
                                      <td>{loc}</td>
                                      <td>{aggregatedDataForBuy[loc][1].averagePrice}</td>
                                      <td>{aggregatedDataForBuy[loc][2].averagePrice}</td>
                                      <td>{aggregatedDataForBuy[loc][3].averagePrice}</td>
                                      <td>
                          {loc === "Nicosia(Avg)"
                            ? aggregatedDataForBuy[loc]["total"].pricePerM2 // For Nicosia(Avg), use the 'total' key
                            : aggregatedDataForBuy[loc][4].pricePerM2  // For other locations, use the regular location data
                          }
                        </td>
                                  </tr>
                              ) : null
                          ))}

                          {/* Filter the aggregated data to only show selected location for Buy */}
                          {Object.keys(aggregatedDataForBuy).filter(location => location === selectedLocationForBuy).map((location, index) => (
                              <tr key={location} className="table-row-buy" id={`row-${index}`}>
                                  <td>{location}</td>
                                  <td>{aggregatedDataForBuy[location][1].averagePrice}</td>
                                  <td>{aggregatedDataForBuy[location][2].averagePrice}</td>
                                  <td>{aggregatedDataForBuy[location][3].averagePrice}</td>
                                  <td>{aggregatedDataForBuy[location][4].pricePerM2}</td>
                              </tr>
                          ))}
                      </tbody>
                  </table>
              </div>

          <ul className="insight-button-list">
            <li className="insight-register-btn">
              <a href="javascript:void(0)" className="register-popup-form">
                Register To View All Towns
              </a>
            </li>
            <li>
              <a href="#!" className="rpa-popup-form" onClick={''}>
                Get Rental Price Alert
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Annual Rent Section */}
      <div className="insight-repeater-sec annual-rent">
        <div className="container">
          <h2>Current AVG Rental Annual Income</h2>

          {/* Location Select Dropdown for Rent */}
          <div className="limassol-location">
            <select
              className="location-select select2 select2-hidden-accessible"
              name="location_rent"
              value={selectedLocationAnnualRent} // Bind to the state variable
              onChange={handleLocationChangeAnnualRent} // Handle change
            >
              <option value="">Select a location</option>
              {propertyLocation.rent.map((locationObj, index) => (
                <option key={index} value={locationObj.location}>
                  {locationObj.location}
                </option>
              ))}
            </select>
          </div>

          {/* Table for Rent Market Data */}
          <div className="insight-table-main">
            <table className="rent_list">
              <thead>
                <tr>
                  <th>Location</th>
                  <th>1 Bed Rent Price</th>
                  <th>2 Bed Rent Price</th>
                  <th>3 Bed Rent Price</th>
                </tr>
              </thead>
              <tbody>
                {/* Loop through the first 4 locations in the aggregated data */}
                {Object.keys(aggregatedData).slice(0, 4).map((loc, index) => (
                  (!hideRowsAnnualRent || (index !== 1 && index !== 2 && index !== 3 && index !== 4)) && (
                    <tr key={loc} className="table-row-rent" id={`row-${index}`}>
                      <td>{loc}</td>
                      <td>{aggregatedData[loc][1].annualRent}</td>
                      <td>{aggregatedData[loc][2].annualRent}</td>
                      <td>{aggregatedData[loc][3].annualRent}</td>
                    </tr>
                  )
                ))}

                {/* Filter the aggregated data to only show the selected location */}
                {selectedLocationAnnualRent && Object.keys(aggregatedData).filter(location => location === selectedLocationAnnualRent).map((location, index) => (
                  <tr key={location} className="table-row-rent" id={`row-${index}`}>
                    <td>{location}</td>
                    <td>{aggregatedData[location][1].annualRent}</td>
                    <td>{aggregatedData[location][2].annualRent}</td>
                    <td>{aggregatedData[location][3].annualRent}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <ul className="insight-button-list">
            <li className="insight-register-btn">
              <a href="javascript:void(0)" className="register-popup-form">
                Register To View All Towns
              </a>
            </li>
            <li>
              <a href="#!" className="rpa-popup-form" onClick={''}>
                Get Rental Price Alert
              </a>
            </li>
          </ul>
        </div>
      </div>
    
         
        {/* Yield Calculation Section */}
        <div className="insight-repeater-sec yield">
        <div className="container">
          <h2>Average Yield Calculation</h2>

          {/* Location Select Dropdown for Yield */}
          <div className="limassol-location">
            <select
              className="location-select select2 select2-hidden-accessible"
              name="location_yield"
              value={selectedLocationForYield}
              onChange={handleLocationChangeForYield}
            >
              <option value="">Select a location</option>
              {propertyLocation.buy.map((locationObj, index) => (
                <option key={index} value={locationObj.location}>
                  {locationObj.location}
                </option>
              ))}
            </select>
          </div>

          {/* Yield Table */}
          <div className="insight-table-main">
            <table className="yield_list">
              <thead>
                <tr>
                  <th>Location</th>
                  <th>1 Bed Yield</th>
                  <th>2 Bed Yield</th>
                  <th>3 Bed Yield</th>
                </tr>
              </thead>
              <tbody>
               {/* Loop through the first 4 locations for yield data */}
                      {Object.keys(yieldData).slice(0, 4).map((loc, index) => (
                        (!hideRowsYield || (index !== 1 && index !== 2 && index !== 3 && index !== 4)) && (
                          <tr key={loc} className="table-row-yield" id={`row-${index}`}>
                            <td>{loc}</td>
                            <td>{yieldData[loc]?.formattedyield1 && yieldData[loc].formattedyield1 !== 0 ? `${yieldData[loc].formattedyield1}` : 'Not Enough Data'}</td>
                            <td>{yieldData[loc]?.formattedyield2 && yieldData[loc].formattedyield2 !== 0 ? `${yieldData[loc].formattedyield2}` : 'Not Enough Data'}</td>
                            <td>{yieldData[loc]?.formattedyield3 && yieldData[loc].formattedyield3 !== 0 ? `${yieldData[loc].formattedyield3}` : 'Not Enough Data'}</td>
                          </tr>
                        )
                      ))}

                      {/* Filter the aggregated data to only show the selected location */}
                      {selectedLocationForYield && Object.keys(yieldData).filter(location => location === selectedLocationForYield).map((location, index) => (
                        <tr key={location} className="table-row-yield" >
                          <td>{location}</td>
                          <td>{yieldData[location]?.formattedyield1 && yieldData[location].formattedyield1 !== 0 ? `${yieldData[location].formattedyield1}` : 'Not Enough Data'}</td>
                          <td>{yieldData[location]?.formattedyield2 && yieldData[location].formattedyield2 !== 0 ? `${yieldData[location].formattedyield2}` : 'Not Enough Data'}</td>
                          <td>{yieldData[location]?.formattedyield3 && yieldData[location].formattedyield3 !== 0 ? `${yieldData[location].formattedyield3}` : 'Not Enough Data'}</td>
                        </tr>
                         
                      ))}

              </tbody>
            </table>
          </div>

          {/* Register & Alert buttons */}
          <ul className="insight-button-list">
            <li className="insight-register-btn">
              <a href="javascript:void(0)" className="register-popup-form">
                Register To View All Towns
              </a>
            </li>
            <li>
              <a href="#!" className="rpa-popup-form" onClick={''}>
                Get Rental Price Alert
              </a>
            </li>
          </ul>
        </div>
      </div>



      <Footer />
    </div>
  );
};

export default MarketTest;
