import React from 'react';

const MapComponent = ({ location }) => {
  // You may need to combine region and location to form a valid query for the map
  const formattedLocation = encodeURIComponent(location);  // URL encode to handle spaces or special characters
  const mapUrl = `https://maps.google.com/maps?q=${formattedLocation}&t=&z=10&ie=UTF8&iwloc=&output=embed`;

  return (
    <div className="map-sec">
      <div className="mapouter">
        <div className="gmap_canvas">
          <iframe
            width="770"
            height="510"
            id="gmap_canvas"
            src={mapUrl}
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            title="Property Location Map"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default MapComponent;
